import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'
import _ from "lodash";

export const currentSlice = createSlice({
  name: 'content',
  initialState: {
    campaignData: {
      campaignName: "",
      budgetAndTarget: {}
    },
    filters: {
    } as any,
    start_busy_dates: [] as any,
    tempFilter: {} as any,
    billboards: [] as any,
    selectedBillboards: {},
    billboardsCoordinates: [] as any,
    selectedPlants: [] as any,
    sconto: {} as any,
    totale_sconto: 0,
    orderedPlants: [] as any,   //impianti ordinati dopo obiettivo, array utilizzato per averli ordinati sulla lista
    campaignID: uuidv4(),
    prices: {
      prezzo_totale: 0,
      sconto: 0
    },
    client_secret: "",
    prezzo_totale: 0,
    orders: [] as any,
    ordini: [] as any,
    ordersToDelete: [] as any,
    myCampaigns: [] as any,
    sortColumnValue: "",
    sortOrderAsc: true,
    indexFetchBillboards: 50,
    campaignOrders: [] as any,
    is_confirm: false,
    selectedCampaign: {} as any,
    order_date: null as any,
    billalo_fee: 0 as any,
    downloadUrls: [] as any,
    stampaConsegna: false
  }
  ,
  reducers: {
    willFilterBillboards: (state, action: PayloadAction<any>) => {
      action.payload.filters = current(state.filters)
      action.payload['selectedPlants'] = current(state.selectedPlants)
      action.payload['start_busy_dates'] = current(state.start_busy_dates)
      action.payload['campaign_id'] = state.campaignID
      // console.log('in slice,', action, current(state.filters))

    },
    didFilterBillboards: (state, action: PayloadAction<any>) => {
      state.billboards = action.payload.billboards;
      state.orderedPlants = action.payload.billboards
      state.billboardsCoordinates = _.compact(_.map(action.payload.billboards, function (o: any) {
        if (o.latitude && o.longitude) {
          return { "lat": o.latitude, "lng": o.longitude }
        }
      }))
      state.is_confirm = action.payload.is_confirm
      state.selectedPlants = action.payload.selectedPlants

      // /// test per visualizzazione gruppo DRAFT [inizio]
      // const testAdd: any[] = action.payload.billboards
      // testAdd.map((billboard: any, index: number) => {
      //   if (index < 5) {
      //     let modifiedBillboard = { ...billboard }
      //     modifiedBillboard.media_owner = 'Draft'
      //     state.billboards.push(modifiedBillboard)
      //   }
      // })
      // /// test per visualizzazione gruppo DRAFT [fine]
    },
    didSetFilter: (state, action: PayloadAction<any>) => {
      state.filters = action.payload
      state.billboardsCoordinates = []
      state.billboards = []
      localStorage.removeItem('filters');
    },
    willUploadFilter: (state, action: PayloadAction<any>) => {
      console.log('uploading period', action)

      const field = action.payload.key
      if (field === "price") {
        if (action.payload.value.from === 0) {
          delete state.filters.price_from
        } else {
          state.filters.price_from = action.payload.value.from
        }
        if (action.payload.value.to === 0) {
          delete state.filters.price_to
        } else {
          state.filters.price_to = action.payload.value.to
        }
      } else if (field === "iV_municipality") {
        if (action.payload.value.from === 0) {
          delete state.filters.iV_municipality_from
        } else {
          state.filters.iV_municipality_from = action.payload.value.from
        }
        if (action.payload.value.to === 0) {
          delete state.filters.iV_municipality_to
        } else {
          state.filters.iV_municipality_to = action.payload.value.to
        }
      } else if (field === "media_type") {
        state.filters['media_type'] = action.payload.value.media_type
        state.filters['billboard_class'] = action.payload.value.billboard_class
      } else {
        if (_.isEmpty(action.payload.value)) {
          delete state.filters[field]
        } else {
          state.filters[field] = action.payload.value
        }
      }
      action.payload.filters = current(state.filters)

      if (action.payload.start_busy_dates) {
        state.start_busy_dates = action.payload.start_busy_dates
      }
    },
    didUploadFilter: (state, action: PayloadAction<any>) => {
      state.filters = action.payload.filters
      if (_.isEmpty(state.filters)) {
        state.billboardsCoordinates = []
        state.billboards = []
      }
    },
    didUploadTempFilter: (state, action: PayloadAction<any>) => {
      state.tempFilter = action.payload.filters
    },
    didSyncDraftFilter: (state, action: PayloadAction<any>) => {
      state.filters = state.tempFilter
      let filterData = current(state.filters)
      // console.log("didSyncDraftFilter", action.payload, filterData)

      if (filterData.period) {
        // console.log("didSyncDraftFilter year", filterData.period.year)
        let startYear = moment().year(filterData.period.year).startOf('year').day("Monday").add(8, 'days')
        // console.log("didSyncDraftFilter startYear", startYear.toISOString(), moment().year(filterData.period.year).toISOString())
        let dates = [...state.start_busy_dates]
        // console.log("didSyncDraftFilter uscite", filterData.period.uscite)
        _.forEach(filterData.period.uscite, (o: any) => {
          // console.log("didSyncDraftFilter uscite pre", o)
          let date = startYear.clone()
          date.add(_.multiply(14, o - 1), 'days')
          // console.log("didSyncDraftFilter uscite post", date)
          dates.push(date.format('YYYY-MM-DD'))
        })
        state.start_busy_dates = dates
      }
      console.log("didSyncDraftFilter start_busy_dates", state.start_busy_dates)
    },
    didDiscardDraftFilter: (state, action: PayloadAction<any>) => {
      state.tempFilter = {} as any
      localStorage.removeItem('filters');
    },
    didSelectBillboard: (state, action: PayloadAction<any>) => {
      state.selectedBillboards[action.payload.id] = action.payload.price
    },
    didSelectBillboards: (state, action: PayloadAction<any>) => {
      let ids = action.payload.ids
      ids = _.mapValues(ids, function (o: any) { return _.multiply(o, state.filters.period.uscite.length) })
      state.selectedBillboards = ids
    },
    didRemoveSelectedBillboard: (state, action: PayloadAction<any>) => {
      delete state.selectedBillboards[action.payload.id]
    },
    didRemoveSelectedBillboards: (state, action: PayloadAction<any>) => {
      let ids = action.payload.ids
      _.forEach(ids, function (id: any) {
        delete state.selectedBillboards[id]
      })
    },
    didSetCampaignData: (state, action: PayloadAction<any>) => {
      state.campaignData.campaignName = action.payload.campaignName
      state.campaignData.budgetAndTarget = action.payload.budgetAndTarget
    },
    willCreateCampaign: (state, action: PayloadAction<any>) => {
      action.payload.period = { ...current(state.filters.period) }
      action.payload.campaignData = current(state.campaignData)
      action.payload.sconto = current(state.sconto)
      action.payload.filters = current(state.filters)
      action.payload.selectedPlants = current(state.selectedPlants)
      action.payload.campaignID = state.campaignID
      action.payload.orders = current(state.orders)
      action.payload['ordini'] = current(state.ordini)
      action.payload['printer'] = state.stampaConsegna
      // console.log("in willCreateCampaign",state.order_date)
      // action.payload['order_date'] = moment()
      action.payload['start_busy_dates'] = current(state.start_busy_dates)
    },
    didSetPlant: (state, action: PayloadAction<any>) => {
      let plant = _.find(state.selectedPlants, action.payload)
      if (plant) {
        console.log("already selected!")
      } else {
        state.selectedPlants.push(action.payload)
      }
    },
    didDeletePlant: (state, action: PayloadAction<any>) => {
      _.remove(state.selectedPlants, action.payload)
    },
    didSetPlants: (state, action: PayloadAction<any>) => {
      console.log("in didSetPlants action", action.payload)
      state.selectedPlants = action.payload
      // let plants = action.payload
      // _.forEach(plants, (i: any) => {
      //   let plant = _.find(state.selectedPlants, i)
      //   console.log("didSetPlants map",plant)
      //   if (plant) {
      //     console.log("already selected!")
      //   } else {
      //     state.selectedPlants.push(i)
      //   }
      // })
    },
    didDeletePlants: (state, action: PayloadAction<any>) => {
      let plants = action.payload
      _.forEach(plants, (i: any) => {
        _.remove(state.selectedPlants, i)
      })
    },
    willFilterPricingIds: (state, action: PayloadAction<any>) => {
      action.payload.filters = current(state.filters)
      action.payload.selectedPlants = current(state.selectedPlants)
    },
    didSortByObjective: (state, action: PayloadAction<any>) => {
      action.payload.billboards = current(state.billboards)
      action.payload.n_uscite = state.filters.period.uscite.length
      state.campaignData.budgetAndTarget = action.payload.budgetAndTarget
      console.log('length', state.filters.period.uscite.length, current(state.filters.period.uscite).length)
    },
    didUploadPriceAndDiscount: (state, action: PayloadAction<any>) => {
      state.totale_sconto = action.payload.sconto
      state.prezzo_totale = action.payload.totale
      state.sconto = action.payload.sconti
      state.billalo_fee = action.payload.fee
    },
    didSetOrderedPlants: (state, action: PayloadAction<any>) => {
      state.orderedPlants = action.payload.ordered
    },
    didStartPayment: (state, action: PayloadAction<any>) => {
      state.client_secret = action.payload.client_secret
    },
    willPayOrder: (state, action: PayloadAction<any>) => {
      action.payload[ordersToDelete] = current(state.ordersToDelete)
    },
    addOrdersId: (state, action: PayloadAction<any>) => {
      console.log('nella action', current(state), action.payload)
      state.orders = action.payload.orders
      state.ordini = action.payload.ordini
    },
    ordersToDelete: (state, action: PayloadAction<any>) => {
      state.ordersToDelete = action.payload
      state.is_confirm = false      // forse sará da spostare
    },
    batchDeleteOrders: (state, action: PayloadAction<any>) => {
      action.payload.orders = current(state.ordersToDelete)
      action.payload.campaign_id = state.campaignID
      state.campaignID = uuidv4()
    },
    willListMyCampaigns: (state, action: PayloadAction<any>) => state,
    didListMyCampaigns: (state, action: PayloadAction<any>) => {
      let now = moment()
      let array = [] as any
      if (!action.payload.campaigns.length && !state.myCampaigns.length) {
        state.myCampaigns = ['empty']
      } else {
        _.forEach(action.payload.campaigns, (o: any) => {
          if (moment(now).isAfter(moment(o.order_date).add(30, 'minutes')) && (o.status === "Draft" || o.status === "draft")) {
            return
          } else {
            array.push(o)
          }
        })
        state.myCampaigns = _.orderBy(array, 'order_date', 'desc')
      }
    },
    didSetSortColumnValue: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        console.log('did set sort value', action.payload, current(state.myCampaigns))
        state.sortColumnValue = action.payload.sortColumnValue
        state.sortOrderAsc = action.payload.sortOrderAsc
      }
      switch (state.sortColumnValue) {
        case "Nome Campagna": {
          console.log("ordina per", state.sortColumnValue)
          if (state.sortOrderAsc) {
            state.myCampaigns = _.slice(_.orderBy(state.myCampaigns, 'name', 'asc'), 0, state.indexFetchBillboards)
          } else {
            state.myCampaigns = _.slice(_.orderBy(state.myCampaigns, 'name', 'desc'), 0, state.indexFetchBillboards)
          }
          break;
        }
        case "Stato": {
          console.log("ordina per", state.sortColumnValue)
          if (state.sortOrderAsc) {
            state.myCampaigns = _.slice(_.orderBy(state.myCampaigns, 'status', 'asc'), 0, state.indexFetchBillboards)
          } else {
            state.myCampaigns = _.slice(_.orderBy(state.myCampaigns, 'status', 'desc'), 0, state.indexFetchBillboards)
          }
          break;
        }
        case "Inizio/Fine": {
          console.log("ordina per", state.sortColumnValue)
          if (state.sortOrderAsc) {
            state.myCampaigns = _.slice(_.orderBy(state.myCampaigns, ['from', 'to'], 'asc'), 0, state.indexFetchBillboards)
          } else {
            state.myCampaigns = _.slice(_.orderBy(state.myCampaigns, ['from', 'to'], 'desc'), 0, state.indexFetchBillboards)
          }
          break;
        }
        case "Comuni": {
          console.log("ordina per", state.sortColumnValue)
          if (state.sortOrderAsc) {
            state.myCampaigns = _.slice(_.orderBy(state.myCampaigns, 'n_municipality', 'asc'), 0, state.indexFetchBillboards)
          } else {
            state.myCampaigns = _.slice(_.orderBy(state.myCampaigns, 'n_municipality', 'desc'), 0, state.indexFetchBillboards)
          }
          break;
        }
        // case "Formati": {
        //   console.log("ordina per", state.sortColumnValue)
        //   if (state.sortOrderAsc) {
        //     state.myCampaigns = _.slice(_.orderBy(state.myCampaigns, 'city_code', 'asc'), 0, state.indexFetchBillboards)
        //   } else {
        //     state.myCampaigns = _.slice(_.orderBy(state.myCampaigns, 'city_code', 'desc'), 0, state.indexFetchBillboards)
        //   }
        //   break;
        // }
        case "Importo Totale": {
          console.log("ordina per", state.sortColumnValue)
          if (state.sortOrderAsc) {
            state.myCampaigns = _.slice(_.orderBy(state.myCampaigns, 'total_amount', 'desc'), 0, state.indexFetchBillboards)
          } else {
            state.myCampaigns = _.slice(_.orderBy(state.myCampaigns, 'total_amount', 'asc'), 0, state.indexFetchBillboards)
          }
          break;
        }
        default:
          //Comandi eseguiti quando nessuno dei valori coincide col valore dell'epressione
          break;
      }
    },
    willGetCampaignOrders: (state, action: PayloadAction<any>) => state,
    didListCampaignOrders: (state, action: PayloadAction<any>) => {
      console.log("didListCampaignOrders", action.payload)
      state.campaignOrders = action.payload.campaignOrders
    },
    willStartPayment: (state, action: PayloadAction<any>) => {
      // action.payload.selectedPlants = state.selectedPlants
      action.payload.filters = JSON.parse(state.selectedCampaign.filters)
      action.payload.campaign = current(state.selectedCampaign)
      if (state.selectedCampaign.id) {
        action.payload.campaignID = state.selectedCampaign.id
      } else {
        action.payload.campaignID = state.campaignID
      }
    },
    didSetSelectedCampaign: (state, action: PayloadAction<any>) => {
      console.log("didSetSelectedCampaign", action)
      state.selectedCampaign = action.payload
    },
    didSetOrderDate: (state, action: PayloadAction<any>) => {
      state.order_date = action.payload
    },
    willUpdateOrderState: (state, action: PayloadAction<any>) => {
      console.log("in willUpdateOrderStatus", action)
    },
    willAddBillboardsAvailability: (state, action: PayloadAction<any>) => {
      console.log("in willAddBillboardsAvailability", action)
      action.payload["orders"] = current(state.campaignOrders)
      if (state.selectedCampaign) {
        let parsedFilters = JSON.parse(state.selectedCampaign.filters)
        action.payload.period = parsedFilters.period
      } else {
        action.payload.period = current(state.filters.period)
      }
    },
    willUpdateCampaignState: (state, action: PayloadAction<any>) => state,
    willGetContracts: (state, action: PayloadAction<any>) => state,
    didPutDownloadUrls: (state, action: PayloadAction<any>) => {
      state.downloadUrls = action.payload.urls
    },
    didSetSelectedPlants: (state, action: PayloadAction<any>) => {
      state.selectedPlants = action.payload
    },
    didSetStampaConsegna: (state, action: PayloadAction<any>) => {
      state.stampaConsegna = action.payload
    },
    willPayCampaign: (state, action: PayloadAction<any>) => state
  }
})

export const { actions, reducer }: any = currentSlice

export const {
  didFilterBillboards,
  didSetFilter,
  willUploadFilter,
  didUploadFilter,
  didSyncDraftFilter,
  didDiscardDraftFilter,
  didSelectBillboard,
  didSelectBillboards,
  didRemoveSelectedBillboard,
  didRemoveSelectedBillboards,
  didSetPlant,
  didDeletePlant,
  didSetPlants,
  didDeletePlants,
  willFilterPricingIds,
  didSortByObjective,
  didUploadPriceAndDiscount,
  didStartPayment,
  willPayOrder,
  ordersToDelete,
  batchDeleteOrders,
  willListMyCampaigns,
  didListMyCampaigns,
  didSetSortColumnValue,
  willGetCampaignOrders,
  didListCampaignOrders,
  willStartPayment,
  didSetSelectedCampaign,
  didSetOrderDate,
  willUpdateOrderState,
  willGetContracts,
  didSetSelectedPlants,
  didSetStampaConsegna,
  willPayCampaign
} = actions

export const selectors = {
  listBillboards: (state: any) => state.content.billboards,
  listBillboardsCoordinates: (state: any) => state.content.billboardsCoordinates,
  didGetFilter: (state: any) => state.content.filters,
  getTempFilter: (state: any) => state.content.tempFilter,
  getSelectedBillboards: (state: any) => state.content.selectedBillboards,
  getCampaignData: (state: any) => state.content.campaignData,
  getSelectedPlants: (state: any) => state.content.selectedPlants,
  getDiscount: (state: any) => state.content.totale_sconto,
  getPrezzoTotale: (state: any) => state.content.prezzo_totale,
  getOrderedPlants: (state: any) => state.content.orderedPlants,
  getClientSecret: (state: any) => state.content.client_secret,
  getPrices: (state: any) => state.content.prices,
  getMyCampaigns: (state: any) => state.content.myCampaigns,
  getCampaignOrders: (state: any) => state.content.campaignOrders,
  getSortColumnValue: (state: any) => state.content.sortColumnValue,
  getSortOrderAsc: (state: any) => state.content.sortOrderAsc,
  getIfIsConfirm: (state: any) => state.content.is_confirm,
  getSelectedCampaign: (state: any) => state.content.selectedCampaign,
  getOrderDate: (state: any) => state.content.order_date,
  getBillaloFee: (state: any) => state.content.billalo_fee,
  getDownloadUrls: (state: any) => state.content.downloadUrls,
  getStampaConsegna: (state: any) => state.content.stampaConsegna
}
