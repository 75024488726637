import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { AvailabilityStatusRange } from "./PlantAvailability.types";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listContainer: {
      width: 450,
    },
    emptyState: {
      fontSize: 32,
    },
  })
);

export const usePickerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // Just here to override everything from original class
    },
    caption: {
      textAlign: "left",
    },
    caption_end: {
      "& $caption_label:after": {
        marginRight: 0,
      },
    },
    caption_dropdowns: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      "&:after": {
        content: "''",
        flex: 1,
        height: 1,
        marginLeft: theme.spacing(1),
        marginRight: "4em",
        backgroundColor: theme.palette.divider,
      },
    },
    caption_label: {
      fontSize: "1rem",
      fontWeight: "bold",
      textTransform: "capitalize",
      display: "flex",
      alignItems: "center",
      "&:after": {
        content: "''",
        flex: 1,
        height: 1,
        marginLeft: theme.spacing(1),
        marginRight: "4em",
        backgroundColor: theme.palette.divider,
      },
    },
    months: {
      flexDirection: "column",
      position: "relative",
    },
    month: {
      position: "static",
      marginLeft: 0,
      marginRight: 0,
    },
    button: {
      border: 0,
    },
    nav_button: {
      position: "absolute",
      top: 1,
      borderWidth: 1,
      borderRadius: 5,
      borderStyle: "solid",
      borderColor: theme.palette.divider,
      width: "1.5em",
      height: "1.5em",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.grey[400],
    },
    nav_button_next: {
      right: "0.5em",
      width: "1.5em",
      height: "1.5em",
      transform: "rotate(90deg)",
    },
    nav_button_previous: {
      right: "2.5em",
      width: "1.5em",
      height: "1.5em",
      transform: "rotate(90deg)",
      paddingLeft: 2,
    },
    table: {
      width: 73 * 7,
      borderCollapse: "collapse",
    },
    row: {
      height: "100%",
      borderRadius: 5,
    },
    cell: {
      width: 50,
      height: 50,
      padding: 0,
      overflow: "hidden",
      position: "relative",
      "&:first-child": {
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
      },
      "&:last-child": {
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
      },
    },
    day: {
      height: "100%",
      width: "100%",
      borderRadius: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      lineHeight: "40px",
      "& > span": {
        zIndex: 1,
      },
    },
    day_disabled: {
      opacity: 0.5,
    },
    day_outside: {
      opacity: 0.25,
    },
  })
);

const backgroundInset = (color: string) => ({
  "&:after": {
    content: "''",
    background: color,
    width: "60%",
    position: "absolute",
    top: 0,
    bottom: 0,
    borderRadius: 10,
  },
});

const backgroundLeft = (color: string) => ({
  background: `linear-gradient(to right, ${color} 50%, transparent 50%)`,
});
const backgroundRight = (color: string) => ({
  background: `linear-gradient(to right, transparent 50%, ${color} 50%)`,
});

const beforeDots = {
  "&:before": {
    content: '"..."',
    position: "absolute",
    bottom: 5,
    left: "50%",
    zIndex: 1,
    lineHeight: "100%",
    transform: "translateX(-50%)",
  },
};

const bookedActive = {
  color: "white",
  ...backgroundInset("rgba(80, 181, 255, 1)"),
};
const booked = { backgroundColor: "rgba(80, 181, 255, 0.5)" };
const bookedActiveStart = backgroundRight(booked.backgroundColor);
const bookedActiveEnd = backgroundLeft(booked.backgroundColor);

const maintenanceActive = {
  color: "white",
  ...backgroundInset("rgba(255, 197, 65, 1)"),
};
const maintenance = { backgroundColor: "rgba(255, 226, 161, 1)" };
const maintenanceActiveStart = backgroundRight(maintenance.backgroundColor);
const maintenanceActiveEnd = backgroundLeft(maintenance.backgroundColor);

const unavailableActive = {
  color: "white",
  ...backgroundInset("rgba(259, 90, 90, 1)"),
};
const unavailable = { backgroundColor: "rgba(253, 173, 173, 1)" };
const unavailableActiveStart = backgroundRight(unavailable.backgroundColor);
const unavailableActiveEnd = backgroundLeft(unavailable.backgroundColor);

const hoverActive = {
  color: "white",
  "&:before": { content: "unset" },
  ...backgroundInset("rgba(20, 98, 255, 1)"),
};
const hover = {
  background: "rgba(20, 98, 255, 0.3)",
  "&:after": { content: "unset" },
  "&:before": { content: "unset" },
};
const hoverActiveStart = backgroundRight(hover.background);
const hoverActiveEnd = backgroundLeft(hover.background);

export const usePickerModifierStyles = makeStyles((theme: Theme) =>
  createStyles({
    [AvailabilityStatusRange.BOOKED_SINGLE]: bookedActive,
    [AvailabilityStatusRange.BOOKED_START]: Object.assign(
      {},
      bookedActive,
      bookedActiveStart,
      beforeDots
    ),
    [AvailabilityStatusRange.BOOKED_END]: Object.assign(
      {},
      bookedActive,
      bookedActiveEnd
    ),
    [AvailabilityStatusRange.BOOKED_MIDDLE]: booked,

    [AvailabilityStatusRange.MAINTENANCE_SINGLE]: maintenanceActive,
    [AvailabilityStatusRange.MAINTENANCE_START]: Object.assign(
      {},
      maintenanceActive,
      maintenanceActiveStart,
      beforeDots
    ),
    [AvailabilityStatusRange.MAINTENANCE_END]: Object.assign(
      {},
      maintenanceActive,
      maintenanceActiveEnd
    ),
    [AvailabilityStatusRange.MAINTENANCE_MIDDLE]: maintenance,

    [AvailabilityStatusRange.UNAVAILABLE_SINGLE]: unavailableActive,
    [AvailabilityStatusRange.UNAVAILABLE_START]: Object.assign(
      {},
      unavailableActive,
      unavailableActiveStart,
      beforeDots
    ),
    [AvailabilityStatusRange.UNAVAILABLE_END]: Object.assign(
      {},
      unavailableActive,
      unavailableActiveEnd
    ),
    [AvailabilityStatusRange.UNAVAILABLE_MIDDLE]: unavailable,

    [AvailabilityStatusRange.HOVER_SINGLE]: hoverActive,
    [AvailabilityStatusRange.HOVER_START]: Object.assign(
      {},
      hoverActive,
      hoverActiveStart,
      beforeDots
    ),
    [AvailabilityStatusRange.HOVER_END]: Object.assign(
      {},
      hoverActive,
      hoverActiveEnd
    ),
    [AvailabilityStatusRange.HOVER_MIDDLE]: hover,
  })
);
