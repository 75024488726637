import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { FormGroup, Input, Label, FormFeedback, Row, Col } from 'reactstrap';
import { Grid } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { actions as AuthActions, selectors as AuthSelectors } from '../store/slices/auth'
import { actions as UIActions, selectors as UISelectors } from '../store/slices/ui'
import { actions as ProfileActions, selectors as ProfileSelectors } from '../store/slices/profile'
import { ActivityButton } from '../components/ActivityButton'
import billalo_logo from '../assets/logo.png'

const NewAdvertiserGroupSchema = Yup.object().shape({
  advertiser: Yup.string()
    .min(2, 'Too Short!')
    // .max(50, 'Too Long!')
    .required('Required')
});

// useEffect(() => {
//   if(!user.signInUserSession.accessToken.payload["cognito:groups"]){

//   }
// },[])

export const NoGroupAdvise = ({ password, email }: any) => {

  const dispatch = useDispatch();
  let history = useHistory();

  let usernameNewGroup = localStorage.getItem('usernameNewGroup')
  let noGroup = useSelector(UISelectors.getNoGroup)
  const advertiser = useSelector(ProfileSelectors.getProfile)
  console.log("NoGroupAdvise advertiser", advertiser)

  // useEffect(() => {
  //   dispatch(ProfileActions.willRetrieveProfileData())
  // }, [])

  return (
    <div className="d-flex justify-content-center align-items-center bg-white poppinsFont">
      {noGroup
        ? <Grid container item xs={12} alignItems="center" justify="center" direction="column" spacing={4}>
          <Grid item xs={5}>
            <img src={billalo_logo} width="300" height="100%" />
          </Grid>
          <Grid item xs={5}>
            Inserisci il nome della tua azienda
          </Grid>
          <Formik
            initialValues={{
              advertiser: ''
            }}
            validationSchema={NewAdvertiserGroupSchema}
            validateOnBlur={true}
            onSubmit={values => {
              console.log('in onsubmit with: ', values)
              dispatch(AuthActions.willAddAdvertiserGroup({ advertiser: values.advertiser, username: usernameNewGroup, history: history, email: email, password: password }));
            }}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <FormGroup>
                  {/* <Label for="advertiser">Nome azienda</Label> */}
                  <Input invalid={errors.advertiser && touched.advertiser ? true : false} type="text" name="advertiser" id="advertiser" placeholder="nome azienda" tag={Field} />
                  {errors.advertiser && touched.advertiser ? (
                    <FormFeedback>{errors.advertiser}</FormFeedback>
                  ) : null}
                </FormGroup>
                <Row>
                  <Col>
                    <ActivityButton type="submit" name="willAddAdvertiserGroup" color="primary" block>Conferma</ActivityButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Grid>
        : <Grid container item xs={10} alignItems="center" justify="center" direction="column" spacing={4}>
          <h3>Nome azienda già inserito. Torna alla schermata di login o alla tua <a href='/'>Dashboard</a> per utilizzare la piattaforma.</h3>
        </Grid>
      }
    </div>
  )
}