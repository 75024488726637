import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
    buttonOccupated: {
      border: "none",
      backgroundColor: "#007bff",
      borderColor: "transparent",
      borderRadius: 10,
      borderWidth:1,
      borderStyle:"solid",
      color: "white",
      width: 40,
      height: 50,
      "&:hover": {
        backgroundColor: "#007bff"
      }
    },
    buttonNotOccupated: {
      border: "none",
      backgroundColor: "transparent",
      borderRadius: 10,
      color: "black",
      width: 40,
      height: 50,
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    dotOccupated: {
      backgroundColor: "#ffad33",
      width: 5,
      height: 5,
      borderRadius: 50
    },
    dotNotOccupated: {
      backgroundColor: "grey",
      width: 5,
      height: 5,
      borderRadius: 50
    },
    buttonOrder: {
      backgroundColor: "#007bff",
      width: 80,
      height: 20,
      fontSize: 10,
      color: "white",
      "&:hover": {
        backgroundColor: "#007bff"
      }
    },
    tooltip: {
      fontSize: 15,
    },
    buttonUnclickable: {
      border: "none",
      backgroundColor: "#f2f2f2",
      borderRadius: 10,
      color: "white",
      width: 40,
      height: 50,
      "&:hover": {
        backgroundColor: "#f2f2f2"
      }
    },
  }),
);


// export const modifiers = {
//   //daysOfWeek:{}, ///<---- vuole solo number[]
//   // thursdays: { daysOfWeek: [4] },
//   // days: startDate,
//   //highlighted: startDate
// };
export const modifiersStyles = {
  highlighted: {
    color: 'white',
    backgroundColor: '#2196F3',
    borderRadius: 10,
  },
  outside: {
    backgroundColor: 'white',
  },
};

export const renderDay = (day: any) => {
  const date = day.getDate();
  const dateStyle = {
    position: 'absolute',
    //color: 'lightgray',
    bottom: 1,
    right: 5,
    //fontSize: 17,
  } as any
  const cellStyle = {
    height: 30,
    width: 30,
    position: 'relative',
    //backgroundColor: "transparent"
  } as any
  return (
    <div style={cellStyle}>
      <div style={dateStyle}>{date}</div>
    </div>
  )
}