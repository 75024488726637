import React, { useState, useEffect } from 'react';
import { goToDetailConfirmPayment, goToDashboardConfirmPayment } from '../Campain.css';
import { Grid, Checkbox } from '@material-ui/core';
import Icon from '../../IconsComponent';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ContentActions, selectors as ContentSelectors } from '../../../store/slices/content';
import { actions as NotificationActions } from '../../../store/slices/notification';
import { actions as UiActions } from '../../../store/slices/ui';


export const CampainPaymentConfirm = (props: any) => {
  const {  data } = props
  // console.log("CampainPaymentConfirm data", data)
  const dispatch = useDispatch()

  let selectedCampaign = useSelector(ContentSelectors.getSelectedCampaign)
  // let campaignOrders = useSelector(ContentSelectors.getCampaignOrders)

  // console.log("selectedCampaign", selectedCampaign)

  useEffect(() => {
    dispatch(ContentActions.willListMyCampaigns())
  }, [])

  return (
    <Grid className="poppinsFont p-5" container alignItems="center" justify="center">
      <Grid className="d-sm-flex align-items-center justify-content-center" item xs={12}>
        <p style={{ color: "#007bff", fontSize: 50, fontWeight: 700 }}>Il pagamento è andato a buon fine</p>
      </Grid>
      <Grid className="d-sm-flex align-items-center justify-content-center mb-5" item xs={12}>
        <b style={{ fontSize: 20 }}>Campagna in attesa di conferma</b>
      </Grid>
      <Grid className="d-sm-flex align-items-center justify-content-center mb-4" item xs={8}>
        <p style={{ opacity: 0.6, textAlign: "center" }}>Da questo momento le concessionarie da cui hai acquistato gli spazi pubblicitari
          hanno 24 ore di tempo per accettare o rifiutare l'ordine</p>
      </Grid>
      <Grid className="d-sm-flex align-items-center justify-content-center mb-5" item xs={8}>
        <p style={{ opacity: 0.6, textAlign: "center" }}>Se tutti gli spazi verranno confermati, entro 24 ore lo stato della tua campagna risulterà "programmata".
          Qualora una o più concessionarie dovessero rifiutare l'ordine ricevuto, verrai contattato per una
          rimodulazione della campagna e per ricevere l'eventuale rimborso.</p>
      </Grid>
      <Grid className="d-sm-flex align-items-center justify-content-center" container item xs={10}>
        <Grid item xs={5}>
          <button style={goToDashboardConfirmPayment} onClick={() => dispatch(UiActions.goTo("/dashboard"))}>Vai alla dashboard</button>
        </Grid>
        <Grid item xs={5}>
          <button onClick={() => {
            dispatch(UiActions.didSetActionDone({ done: true, action: "campagna pagata" }))
            dispatch(ContentActions.willGetCampaignOrders({ campaign_id: selectedCampaign.id, orders: selectedCampaign.orders }))
            dispatch(ContentActions.didSetSelectedCampaign(selectedCampaign))
            dispatch(UiActions.goTo("/campaign/campaigndetails"));
          }} style={goToDetailConfirmPayment}>Vedi dettaglio campagna</button>
        </Grid>
      </Grid>
    </Grid>
  )
}