import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Grid, GridList, Switch, Paper, withStyles } from '@material-ui/core';
// import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { actions as ContentActions, selectors as ContentSelectors } from '../../../store/slices/content';
import "react-circular-progressbar/dist/styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Chart, PieSeries, Title, Legend, ArgumentAxis, ValueAxis, LineSeries, BarSeries, Tooltip } from '@devexpress/dx-react-chart-material-ui';
import { Stack, Animation, HoverState } from '@devexpress/dx-react-chart';
import Icon from '../../IconsComponent';
import { useStyles, Divider, photoShape } from '../../CampaignDetails.css';
import { orderStatusSwitch } from "../Campain.css";
import placeholder from '../../../assets/images/placeholder.jpg'
import moment from 'moment';
import _ from 'lodash';

const currencyFormatter = (value: number) => {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
}

const numFormatter = (num: any) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
}

export const CampaignDetailsLeft = (props: any) => {
  const { allBillboards } = props
  const classes = useStyles()

  const [test, setTest] = useState(false)
  const [countdown, setCountDown] = useState("")

  let campaignOrders = useSelector(ContentSelectors.getCampaignOrders)
  let data = useSelector(ContentSelectors.getSelectedCampaign)

  let pieChartStats = [] as any
  let size6x3 = 0 as number
  let size4x3 = 0 as number
  let sizeOthers = 0 as number
  let totalMunicipality = [] as any
  let totalNormalized = [] as any
  let meanVisibilityIndex = [] as any
  let ownersArray = [] as any

  let sizesArray = [
    { format: "1200x600", number: 0 },
    { format: "600x300", number: 0 },
    { format: "400x200", number: 0 },
    // { format: "200x100", number: 0 },
    { format: "Altri", number: 0 }
  ]

  allBillboards.map((i: any) => {
    const mappedSize = `${i.base}x${i.height}`
    switch (mappedSize) {
      case "1200x600":
        sizesArray[0].number++
        break;
      case "600x300":
        sizesArray[1].number++
        break;
      case "400x200":
        sizesArray[2].number++
        break;
      // case "200x100":
      //   sizesArray[3].number++
      //   break;
      default:
        if (_.find(sizesArray, mappedSize)?.format !== mappedSize) {
          sizesArray[3].number++
        } else {
          return
        }
        break;
    }
  })

  allBillboards.map((i: any) => {
    const mappedSize = `${i.base}x${i.height}`
    // sizeArray.push({ size: mappedSize })
    if (mappedSize === "600x300") {
      size6x3 = size6x3 + 1
    }
    else if (mappedSize === "400x300") {
      size4x3 = size4x3 + 1
    } else {
      sizeOthers = sizeOthers + 1
    }
  })
  // console.log("number of Sizes:", size6x3, size6x4, sizeOthers)

  allBillboards.map((i: any) => {
    totalMunicipality.push(i.iV_municipality)
  })
  allBillboards.map((i: any) => {
    totalNormalized.push(i.iV_normalized)
  })
  meanVisibilityIndex.push(_.mean(totalMunicipality), _.mean(totalNormalized))

  let obj = {} as any
  let obj2 = {} as any
  _.forEach(allBillboards, (value: any) => {
    if (obj[value.municipality]) {
      obj[value.municipality]++
    } else {
      obj[value.municipality] = 1
    }
    if (obj2[value.media_owner]) {
      obj2[value.media_owner]++
    } else {
      obj2[value.media_owner] = 1
    }
  })
  _.forOwn(obj, (value: any, key: any) => {
    // console.log("value", value, _.keys(obj))
    pieChartStats.push({ municipality: key, plants: value })
    // console.log("value pieChartStats", pieChartStats)

  })
  _.forOwn(obj2, (value: any, key: any) => {
    ownersArray.push({ media_owner: key, plants: value })
  })

  // const labelComponent = (props: any) => {
  //   //console.log("labelComponent",props)
  //   const item = pieChartStats.find((item: any) => item.municipality === props.text);
  //   return <Legend.Label className="d-sm-flex" {...props} text={`${props.text} ${numFormatter(item.plants)}`} />;
  // };

  const rootComponent = (props: any) => {
    // console.log("rootComponent", props)
    // const item = pieChartStats.find((item: any) => item.municipality === props.text);
    return (
      <Legend.Root className="d-sm-flex" {...props}>
        <Grid container alignItems="center">
          {props.children.map((o: any) => {
            const item = pieChartStats.find((item: any) => item.municipality === o.props.children[0].props.name);
            // console.log("municipality number", item)
            return (
              <Grid xs={6}>
                {/* <Grid style={{ width: 50, height: 10, backgroundColor: o.props.children[0].props.color }} xs={1}></Grid> */}
                <Grid className="d-sm-flex align-items-center" xs={12}>
                  <div style={{ width: 10, height: 10, backgroundColor: o.props.children[0].props.color, marginRight: 5, borderRadius: 10 }} />
                  <div style={{ marginRight: 5 }} >{o.props.children[0].props.name.length < 15 ? o.props.children[0].props.name : `${o.props.children[0].props.name.substring(0, 15)}..`}</div>
                  <div className="poppinsFont">{numFormatter(item.plants)}</div>
                </Grid>
              </Grid>
            )
          })
          }
        </Grid>
      </Legend.Root>
    )
  }

  return (
    <Grid className="p-3 orderDetailsLeft" container alignItems="center">
      <h4 data-cy="fotoInCampaignDetails">Foto</h4>
      <Grid className="mb-4" item xs={12}>
        <img style={photoShape} src={placeholder} />
      </Grid>
      <Divider />
      {/* <button onClick={() => console.log("secondo me non va", sizesArray)} >TEST</button> */}
      <Grid className="mt-4 mb-4" container item xs={12} alignItems="center">
        <Grid className="text-secondary" item xs={3}>Stato</Grid>
        {test ?
          <>
            <Grid item xs={7}>
              {/* <span className="detailText">tempo rimanente: {moment().hours()}:{moment().minutes()}:{moment().seconds()}</span> */}
              <span className="detailText">tempo rimanente: {countdown}</span>
              {/* <span className="detailText">tempo rimanente: {<CountDown creationDate={creationDate}/>}</span> */}
            </Grid>
            <Grid className="d-sm-flex flex-row-reverse" item xs={2}><Switch color="primary" /></Grid>
          </> :
          <Grid className="d-sm-flex flex-row-reverse" item xs={9}><span className="maintenanceOverview">{orderStatusSwitch(data.status, data.from, data.to)}</span></Grid>}
      </Grid>
      {/* <Divider />
      <Grid className="mt-4 mb-4" container item xs={12} alignItems="center">
        <Grid data-cy="client" className="text-secondary" item xs={3}>Cliente</Grid>
        <Grid className="d-sm-flex flex-row-reverse" item xs={9}>{data.advertiser}</Grid>
      </Grid> */}
      <Divider />
      <Grid className="mt-4 mb-4" container item xs={12} alignItems="center">
        <Grid className="text-secondary" item xs={4}>Inizio / Fine</Grid>
        <Grid className="d-sm-flex flex-row-reverse" item xs={8}>{moment(data.from).format("DD-MM-YYYY")} / {moment(data.to).format("DD-MM-YYYY")}</Grid>
      </Grid>
      <Divider />
      <Grid className="mt-4 mb-4" container item xs={12} alignItems="center">
        <Grid className="text-secondary" item xs={4}>Data ordine</Grid>
        <Grid className="d-sm-flex flex-row-reverse" item xs={8}>{moment(data.order_date).format("DD-MM-YYYY")}</Grid>
      </Grid>
      <Divider />
      <Grid className="mt-4 mb-4" container item xs={12} alignItems="center">
        <Grid className="text-secondary" item xs={6}>Stampa</Grid>
        <Grid className="d-sm-flex flex-row-reverse" item xs={6}>{data.printer === "true" ? 'Richiesta' : 'Non richiesta'}</Grid>
      </Grid>
      <Divider />
      <Grid className="mt-4 mb-4" container item xs={12} alignItems="center">
        <Grid className="text-secondary" item xs={6}>Fee</Grid>
        <Grid className="d-sm-flex flex-row-reverse" item xs={6}>{currencyFormatter(data.fee)}</Grid>
      </Grid>
      <Divider />
      <Grid className="mt-4 mb-4" container item xs={12} alignItems="center">
        <Grid className="text-secondary" item xs={4}>Spesa totale</Grid>
        <Grid className="d-sm-flex flex-row-reverse" item xs={8}>{currencyFormatter(_.ceil(data.total_amount,2))}</Grid>
      </Grid>
      <Divider />
      <Grid className={classes.confirmStepBorders} item xs={12}>
        <Chart data={sizesArray.filter((o: any) => o.number > 0)} > {/* il .filter mostra solo i formati presenti */}
          <ArgumentAxis />
          <ValueAxis />
          <BarSeries
            // name="600x300"
            valueField="number"
            argumentField="format"
            barWidth={1 / 2} /// non prende 0.5 ma prende 1/2
          // color="#ffd700"
          />
          <Animation />
          <Title text="Formati della Campagna" />
          <Stack />
        </Chart>
      </Grid>
      {/* <Grid className={`${classes.confirmStepBorders} p-4 d-sm-flex flex-column bg-transparent`} container item xs={12}>
        <Grid style={{ fontSize: 20 }} className="p-3" item xs={12}><b>Formati / Tipologia</b></Grid>
        <Grid data-cy="size-1" className="mb-3 mt-3" container item xs={12} alignItems="center" justify="center">
          <CircularProgressbarWithChildren
            className={classes.visibilityProgress}
            styles={buildStyles({
              //textColor: "red",
              pathColor: "#0066ff",
              trailColor: "#f8f9fa"
            })}
            value={size6x3 / allBillboards.length * 100}
            strokeWidth={5} >
            <b className={classes.percentageProgress}>{_.round(size6x3 / allBillboards.length * 100)}%</b>
          </CircularProgressbarWithChildren>
          <div style={{ fontSize: 20 }} className="d-sm-flex flex-column ml-3">
            <span><Icon size={25} icon="impianto" /> <b>{size6x3}</b></span>
            <span className="text-muted">600x300</span>
          </div>
        </Grid>
        <Divider />
        <Grid data-cy="size-2" className="mb-3 mt-3" container item xs={12} alignItems="center" justify="center">
          <CircularProgressbarWithChildren
            className={classes.visibilityProgress}
            styles={buildStyles({
              //textColor: "red",
              pathColor: "#00e68a",
              trailColor: "#f8f9fa"
            })}
            value={size4x3 / allBillboards.length * 100}
            strokeWidth={5} >
            <b className={classes.percentageProgress}>{_.round(size4x3 / allBillboards.length * 100)}%</b>
          </CircularProgressbarWithChildren>
          <div style={{ fontSize: 20 }} className="d-sm-flex flex-column ml-3">
            <span><Icon size={25} icon="impianto" /> <b>{size4x3}</b></span>
            <span className="text-muted">400x300</span>
          </div>
        </Grid>
        <Divider />
        <Grid data-cy="size-3" className="mb-3 mt-3" container item xs={12} alignItems="center" justify="center">
          <CircularProgressbarWithChildren
            className={classes.visibilityProgress}
            styles={buildStyles({
              //textColor: "red",
              pathColor: "#ff9900",
              trailColor: "#f8f9fa"
            })}
            value={sizeOthers / allBillboards.length * 100}
            strokeWidth={5} >
            <b className={classes.percentageProgress}>{_.round(sizeOthers / allBillboards.length * 100)}%</b>
          </CircularProgressbarWithChildren>
          <div style={{ fontSize: 20 }} className="d-sm-flex flex-column ml-3">
            <span><Icon size={25} icon="impianto" /> <b>{sizeOthers}</b></span>
            <span style={{ width: 80 }} className="text-muted">Altri</span>
          </div>
        </Grid>
      </Grid> */}
      <Grid className={`${classes.confirmStepBorders} d-sm-flex flex-column overflow-auto bg-white justify-content-center`} container item xs={12} alignItems="center" justify="center">
        <span style={{ fontSize: 26, fontWeight: 600 }} className="provaCentro mt-3" >{_.size(pieChartStats)}</span>
        <span style={{ fontSize: 20 }} className="provaCentro " > {_.size(pieChartStats) > 1 ? "Comuni coinvolti" : "Comune coinvolto"}</span>
        <div className="w-75 h-75">
          <Chart data={pieChartStats} >
            <PieSeries
              valueField="plants"
              argumentField="municipality"
              innerRadius={0.9}
            />
            <Legend
              // labelComponent={labelComponent}
              rootComponent={rootComponent}
              position="bottom"
            />
          </Chart>
        </div>
      </Grid>
      <Grid className={`${classes.confirmStepBorders} p-2 border`} item xs={12}>
        <div className="d-sm-flex flex-column align-items-center justify-content-center mt-4">
          <b>Indice visibilità medio </b>
          <b style={{ fontSize: 37, marginTop: 20 }}><b style={{ color: "#1a75ff" }}>{_.ceil(_.mean(meanVisibilityIndex), 2)}</b><b className="text-secondary">/10</b></b>
        </div>
      </Grid>
      <Grid data-cy="billaloIndex" className={`${classes.confirmStepBorders} mt-3 mb-3 p-2`} container alignItems="center" justify="center">
        <CircularProgressbarWithChildren
          className={classes.visibilityProgress}
          styles={buildStyles({
            //textColor: "red",
            pathColor: "#00e68a",
            trailColor: "#f8f9fa"
          })}
          maxValue={10}
          value={_.ceil(_.mean(totalNormalized), 2)}
          strokeWidth={5} >
          <Icon color="#00e68a" size={25} icon="leaderboard" />
        </CircularProgressbarWithChildren>
        <div className="d-sm-flex flex-column ml-3 detailText">
          <span><b style={{ fontSize: 30 }}>{_.ceil(_.mean(totalNormalized), 2)}</b> <br /><span style={{ fontSize: 15 }} className="text-muted">Indice Visibilità <br /> - Italia</span></span>
        </div>
        <Grid data-cy="billaloIndex" className="mt-3 mb-1" container alignItems="center" justify="center">
          <CircularProgressbarWithChildren
            className={classes.visibilityProgress}
            styles={buildStyles({
              pathColor: "#0066ff",
              trailColor: "#f8f9fa"
            })}
            maxValue={10}
            value={_.ceil(_.mean(totalMunicipality), 2)}
            strokeWidth={5} >
            <Icon color="#0062CC" size={25} icon="headquarters" />
          </CircularProgressbarWithChildren>
          <div className="d-sm-flex flex-column ml-3 detailText">
            <span><b style={{ fontSize: 30 }}>{_.ceil(_.mean(totalMunicipality), 2)}</b> <br /><span style={{ fontSize: 15 }} className="text-muted">Indice Visibilità <br /> - Comune</span></span>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}