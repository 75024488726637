import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory
} from "react-router-dom";
import { useSelector } from "react-redux";
import { actions as UiActions, selectors as UiSelectors } from './store/slices/ui';
import { Header } from "./header";
import { Container } from "reactstrap";
import { history } from './store'
import { ConnectedRouter } from 'connected-react-router'
import { LoginPage } from './pages/login'
import { SignUp } from './pages/signup'
import { SignupPage } from '../src/components/SignUp'
import { SignupConfirmPage } from './pages/signupConfirm'
import { ResetPassword } from './pages/resetpassword'
import { HomePage } from "./pages/home";
import { PlanningPage } from './pages/planning';
import { CampaignList } from './pages/campaign';
import { Dashboard } from './pages/dashboard';
import { CampaignDetails } from './pages/campaigndetails';
import { NoGroupAdvise } from "../src/components/NoGroupAdvise";
import { Profile } from './pages/profile';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { Styles, useStyles } from "../src/components/BackdropCss";

import * as AuthApi from './api/auth'
import { selectors as AuthSelectors } from './store/slices/auth'
import { actions as ContentActions, selectors as ContentSelectors } from './store/slices/content';
import { actions as profileActions, selectors as ProfileSelector } from './store/slices/profile';


const PrivateRoute = ({ children, ...rest }: any) => {

  let history = useHistory();
  const isLogged = useSelector(AuthSelectors.isLogged)
  const isChecked = useSelector(AuthSelectors.isChecked)
  return (
    <Route
      {...rest}
      render={
        props => {
          console.log("isLogged: ", isLogged)
          return (
            <>
              {isChecked ? (
                <>
                  {isLogged ? (
                    children
                  ) : (
                    <Redirect to="/login" />
                  )}
                </>
              ) : (
                <p>waiting</p>
              )
              }
            </>
          )
        }
      }
    />
  )
}

export const AppRouter = () => {
  const classes = useStyles()
  let actionDone = useSelector(UiSelectors.getActionDone)

  let campaign = useSelector(ContentSelectors.getSelectedCampaign)
  let noGroup = useSelector(UiSelectors.getNoGroup)
  let noProfile = useSelector(UiSelectors.getNoProfile)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  return (
    <>
      <Backdrop className={classes.backdrop} open={actionDone.action === "orderContract" ? true : false} >
        <CircularProgress color="inherit" /> <br />
      </Backdrop>
      <ConnectedRouter history={history}>
        <Header className="text-white border-bottom" />
        {noGroup ? <NoGroupAdvise password={password} email={email} /> :
          <Container className="switchContainer" fluid>
            <Switch>
              <Route path="/resetpassword">
                <div className="bg-white">
                  <ResetPassword />
                </div>
              </Route>
              <Route path="/signup/confirm/:code">
                <SignupConfirmPage />
              </Route>
              <Route path="/signup/confirm/">
                <SignupConfirmPage />
              </Route>
              <Route path="/signup">
                {/* <SignUp /> */}
                <SignupPage />
              </Route>
              <Route path="/login">
                <LoginPage setEmail={setEmail} setPassword={setPassword} />
              </Route>
              <Route path="/planning">
                <PlanningPage />
              </Route>
              <Route path="/campaign/campaigndetails">
                <div style={{ backgroundColor: "#fafafb" }}>
                  {campaign.id ? <CampaignDetails /> : <Redirect from="/campaign/campaigndetails" to="/campaign" />}
                </div>
              </Route>
              <Route path="/campaign">
                <div style={{ backgroundColor: "#fafafb", overflow: "hidden", height: 'calc(100vh - 83px)' }}>
                  <CampaignList />
                </div>
              </Route>
              <Route path="/profile">
                <Profile />
              </Route>
              <Route path="/">
                <div style={{ backgroundColor: "#fafafb", overflow: "hidden", height: 'calc(100vh - 83px)' }}>
                  {/* {noGroup === false && noProfile ? <Redirect from="/" to="/profile" /> : <HomePage />} */}
                  <Dashboard />
                </div>
              </Route>
            </Switch>
          </Container>}
      </ConnectedRouter>
    </>
  )
}
