import React from 'react';
import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Switch, Checkbox } from '@material-ui/core';


export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
    buttonOccupated: {
      border: "none",
      backgroundColor: "#007bff",
      borderColor: "transparent",
      borderRadius: 10,
      borderWidth: 1,
      borderStyle: "solid",
      color: "white",
      width: 40,
      height: 50,
      "&:hover": {
        backgroundColor: "#007bff"
      }
    },
    buttonNotOccupated: {
      border: "none",
      backgroundColor: "transparent",
      borderRadius: 10,
      color: "black",
      width: 40,
      height: 50,
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    dotOccupated: {
      backgroundColor: "#ffad33",
      width: 5,
      height: 5,
      borderRadius: 50
    },
    dotNotOccupated: {
      backgroundColor: "grey",
      width: 5,
      height: 5,
      borderRadius: 50
    },
    buttonOrder: {
      backgroundColor: "#007bff",
      width: 80,
      height: 20,
      fontSize: 10,
      color: "white",
      "&:hover": {
        backgroundColor: "#007bff"
      }
    },
    tooltip: {
      fontSize: 15,
    },
    buttonUnclickable: {
      border: "none",
      backgroundColor: "#f2f2f2",
      borderRadius: 10,
      color: "white",
      width: 40,
      height: 50,
      "&:hover": {
        backgroundColor: "#f2f2f2"
      }
    },
    middlerLine: {
      height: 1,
      width: '100%',
      backgroundColor: '#E2E2EA'
    },
    yearButton: {
      // display:'flex',
      // alignItems: 'center'
      backgroundColor: 'transparent',
      border: '1px solid #E2E2EA',
      borderRadius: 10,
      width: 32,
      height: 32
    },
    cancelButton: {
      fontSize: 12,
      width: 100,
      height: 38,
      border: '1px solid #E2E2EA',
      backgroundColor: "#FAFAFB",
      color: "#696974",
      borderRadius: 10,
      "&:hover": {
        backgroundColor: "#FAFAFB",
      }
    },
    confirmButton: {
      fontSize: 12,
      width: 100,
      height: 38,
      borderColor: "transparent",
      backgroundColor: "#0062FF",
      color: "white",
      borderRadius: 10,
      "&:hover": {
        backgroundColor: "#0062FF",
      }
    },
    buttonContainer: {
      backgroundColor: '#FAFAFB',
      borderBottomLeftRadius: '15px',
      borderBottomRightRadius: '15px',
      marginTop: 15,
      padding: '20px 0px 20px 10px'
    }
  }),
);

export const CustomSwitch = withStyles({
  switchBase: {
    color: "#FFC542",
    "&$checked": {
      color: "#FFC542",
      opacity: 1
    },
    "&$checked + $track": {
      backgroundColor: "#FAFAFB",
      opacity: 1
    },
    "&.MuiSwitch-colorSecondary + .MuiSwitch-track": {
      backgroundColor: "#FAFAFB",
      opacity: 1
    }
  },
  checked: {},
  track: {}
})(Switch);


export const CustomCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#50B5FF',
    },
  },
  checked: {},
})(Checkbox); 