import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content'
import { actions as UiActions, selectors as UiSelectors } from '../../store/slices/ui'
import { useStyles, buttonApply, draftButtons, draftButtonsSelected } from '../campaign/Campain.css';
import { TextField, Grid, InputAdornment } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Icon from '../IconsComponent';


const test = [
  { prova: "prova 1", sezioneProva: "sezioneProva 1" },
  { prova: "prova 2", sezioneProva: "sezioneProva 2" },
  { prova: "prova 3", sezioneProva: "sezioneProva 3" },
  { prova: "prova 4", sezioneProva: "sezioneProva 4" },
]

export const Audience = (props: any) => {
  const { setAnchor, setPlanningType, setBudgetAndTarget } = props

  const classes = useStyles();
  const dispatch = useDispatch()
  const [data, setData] = useState({}) as any

  const applyFunction = () => {
    // setBudgetAndTarget({budget: budget, target: data})
    setAnchor(null)
    setPlanningType("")
  }

  return (
    <div className={`${classes.root} poppinsFont`}>
      <Grid container spacing={3}>
        <Grid container item xs={12}>
          <Grid item xs={11}><b>Audience</b></Grid>
          <Grid item xs={1}>
            <button
              data-cy="goBack"
              className="border-0 bg-transparent"
              onClick={() => { setAnchor(null); setPlanningType("") }}
            >
              <Icon size={25} color="#2196F3" icon="chevronLeft" /></button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <button disabled data-cy="apply" style={buttonApply} onClick={applyFunction}>Applica</button>
        </Grid>
        <Grid item xs={12} ><p>ATTUALMENTE NON DISPONIBILE</p></Grid>
        <Grid item xs={12}>
          <p className="detailText text-muted">SELEZIONA MICROCLUSTER</p>
          <Autocomplete
            disabled
            id="microcluster"
            options={test}
            getOptionLabel={(option) => option.prova}
            renderInput={(params) => <TextField {...params} label="" InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <Icon size={20} icon="friends" />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              )
            }} />}
          />
        </Grid>
        <Grid container item xs={12} spacing={1} >
          <Grid item xs={12} ><p className="detailText text-muted">ETA'</p></Grid>
          <Grid item xs={6} >
            <Autocomplete
            disabled
              id="age-1"
              options={test}
              getOptionLabel={(option) => option.prova}
              renderInput={(params) => <TextField {...params} label="" InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <Icon size={20} icon="calendar" />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                )
              }} />}
            />
          </Grid>
          <Grid item xs={6} >
            <Autocomplete
            disabled
              id="age-2"
              options={test}
              getOptionLabel={(option) => option.prova}
              renderInput={(params) => <TextField {...params} label="" InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <Icon size={20} icon="calendar" />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                )
              }} />}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <p className="detailText text-muted">SESSO</p>
          <Autocomplete
            disabled
            id="sex"
            options={test}
            getOptionLabel={(option) => option.prova}
            renderInput={(params) => <TextField {...params} label="" InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <Icon size={20} icon="relationship" />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              )
            }} />}
          />
        </Grid>
        <Grid item xs={12}>
          <p className="detailText text-muted">REDDITO</p>
          <Autocomplete
            disabled
            id="reddit"
            options={test}
            getOptionLabel={(option) => option.prova}
            renderInput={(params) => <TextField {...params} label="" InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <Icon size={20} icon="analytics_outline" />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              )
            }} />}
          />
        </Grid>
        <Grid item xs={12}>
          <p className="detailText text-muted">PROFESSIONI</p>
          <Autocomplete
            disabled
            id="job"
            options={test}
            getOptionLabel={(option) => option.prova}
            renderInput={(params) => <TextField {...params} label="" InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <Icon size={20} icon="student_outline" />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              )
            }} />}
          />
        </Grid>
        <Grid item xs={12}>
          <p className="detailText text-muted">INTERESSI</p>
          <Autocomplete
            disabled
            id="interests"
            options={test}
            getOptionLabel={(option) => option.prova}
            renderInput={(params) => <TextField {...params} label="" InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <Icon size={20} icon="achievements" />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              )
            }} />}
          />
        </Grid>
      </Grid>
    </div>
  )
}