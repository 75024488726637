import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { loader } from 'graphql.macro';
import { configuration } from '../config';
import moment from 'moment';
import _ from 'lodash';
import { loadStripe } from '@stripe/stripe-js';

export const configure = () => {
  const stage: string = process.env.REACT_APP_STAGE != undefined ? process.env.REACT_APP_STAGE : "dev"
  Amplify.configure(configuration[stage]);
}

export const addCampaign = async (input: any, orders: any, start_busy_dates: any) => {
  const query = loader('../graphql/addCampaign.gql');
  console.log('with query: ', input)
  try {
    const result: any = await API.graphql({ query: query, variables: { input: input, start_busy_dates: start_busy_dates } });
    return result
  } catch (error) {
    console.log("errore api")
    throw error
  }
}
export const batchAddOrders = async (input: any, orders: any) => {
  const query = loader('../graphql/batchAddOrders.gql');
  console.log('with query: ', input, orders)
  try {
    const result: any = await API.graphql({ query: query, variables: { input: input, orders: orders } });
    return result
  } catch (error) {
    console.log("errore api")
    throw error
  }
}

export const startPayment = async (campaign_id: string, pricindIds: any, filtro: any, selectedPlants: any, impianti_sconto: any) => {
  console.log('with query: ', campaign_id, pricindIds, filtro, selectedPlants, impianti_sconto)
  const query = loader('../graphql/startPayment.gql');
  let pricing_input = {
    input: pricindIds,
    filter: filtro,
    plants: selectedPlants,
    impianti_sconto: impianti_sconto
  }
  try {
    const result: any = await API.graphql({ query: query, variables: { campaignId: campaign_id, pricing_input: pricing_input} });
    console.log('result api', result)
    return result.data.startPayment
  } catch (error) {
    console.log("errore api")
    throw error
  }
}

export const batchDeleteOrders = async (ids: any, campaign_id: string) => {
  const query = loader('../graphql/batchDeleteOrders.gql');

  try {
    const result: any = await API.graphql({ query: query, variables: { ids: ids, campaign: campaign_id} });
    console.log('result api', result)
    return result.data.startPayment
  } catch (error) {
    console.log("errore api")
    throw error
  }
}
export const payOrder = async (data: any) => {
  console.log('with query: ', data)
  var stripe = Stripe('pk_test_51IEydlBobj2XjFUxEtd2thxFd9KNyXhidCMLvoqgEpaC9O4STRLnqMTQKAp4CXGa2s3811q6UACaHFDaLIu6KRwa00C8CaKvVN');
  // const stripePromise = loadStripe('pk_test_51IEydlBobj2XjFUxEtd2thxFd9KNyXhidCMLvoqgEpaC9O4STRLnqMTQKAp4CXGa2s3811q6UACaHFDaLIu6KRwa00C8CaKvVN')
  try {
    const { paymentIntent, error } = await stripe.confirmCardPayment(
      data.client_secret,
      {
        payment_method: {
          card: data.payment_method.card,
          billing_details: {
            name: data.payment_method.billing_details.name,
          },
        },
      },
    );
    console.log('pagamento', paymentIntent, error)
    return paymentIntent
  } catch (error) {
    console.log("errore api")
    throw error
  }
}
export const getCampaingprice = async (input: any, filtro: any, plants: any, impianti_sconto: any) => {

}
export const getPricingRules = async (input: any, filtro: any, plants: any, impianti_sconto: any) => {
  const queryPrice = loader('../graphql/setCampaignDiscount.gql');
  let risultato = {} as any
  console.log('with query: ', queryPrice, input, filtro, plants, impianti_sconto)

  try {
    const result: any = await API.graphql({ query: queryPrice, variables: { input: input, filter: filtro, plants: plants, impianti_sconto: impianti_sconto } });
    console.log('risultato regole di pricing', result)
    // _.forEach(result.data.getBatchBillboardPricing, function (o: any) { return price_rules.push(o) })
    risultato = result.data.setCampaignDiscount
  } catch (error) {
    console.log("errore api")
    throw error
  }

  return { sconto: risultato.totale_sconto, totale: risultato.prezzo_totale, sconti: risultato.sconto, billalo_fee: risultato.billalo_fee }

}