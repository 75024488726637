import moment from "moment";
import { Matcher } from "react-day-picker";

import {
  Availability,
  AvailabilityIssuePeriod,
  AvailabilityModifiersMap,
  AvailabilityStatus,
  AvailabilityStatusCustom,
  AvailabilityStatusRange,
} from "./PlantAvailability.types";

import { AVAILABILITY_DURATION } from "./PlantAvailability.constants";
import { BillboardAvailability } from "../../graphql/generated";

// export const makeRangesList = (
//   availabilities: (BillboardAvailability | null)[],
//   duration = AVAILABILITY_DURATION
// ): Availability[] =>
//   availabilities.reduce((acc, availability) => {
//     if (!availability) return acc;
//     const from = moment(availability.start_busy_date).toDate();
//     const to = moment(from)
//       .add(duration - 1, "days")
//       .toDate();

//     const isInMaintenance = !!availability.is_maintenance;
//     const order = availability.order_data;

//     const status = !!order
//       ? AvailabilityStatus.BOOKED
//       : isInMaintenance
//       ? AvailabilityStatus.MAINTENANCE
//       : AvailabilityStatus.UNAVAILABLE;

//     return [...acc, { from, to, status, order }];
//   }, [] as Availability[]);

const makeModifier = (
  from: Date,
  to: Date,
  status: AvailabilityStatus | AvailabilityStatusCustom
): AvailabilityModifiersMap<Matcher> => {
  const status_SINGLE = `${status}_SINGLE` as AvailabilityStatusRange;
  const status_START = `${status}_START` as AvailabilityStatusRange;
  const status_MIDDLE = `${status}_MIDDLE` as AvailabilityStatusRange;
  const status_END = `${status}_END` as AvailabilityStatusRange;

  const momentFrom = moment(from);
  const momentTo = moment(to);

  const common = {
    [status]: {
      from: moment(from).toDate(),
      to: moment(to).toDate(),
    },
  };

  if (momentFrom.isSame(momentTo, "date")) {
    return {
      ...common,
      [status_SINGLE]: momentFrom.toDate(),
    };
  }

  if (momentTo.diff(momentFrom, "days") === 1) {
    return {
      ...common,
      [status_START]: momentFrom.toDate(),
      [status_END]: momentTo.toDate(),
    };
  }

  return {
    ...common,
    [status_START]: momentFrom.toDate(),
    [status_END]: momentTo.toDate(),
    [status_MIDDLE]: {
      from: moment(from).add(1, "days").toDate(),
      to: moment(to).subtract(1, "days").toDate(),
    },
  };
};

const ensureArray = <T>(value?: T | T[]) =>
  value ? (Array.isArray(value) ? value : [value]) : [];

export const mergeModifiers = (
  ...modifiers: AvailabilityModifiersMap[]
): AvailabilityModifiersMap => {
  return modifiers.reduce((acc, inc) => {
    return {
      ...acc,
      ...Object.entries(inc).reduce((_acc, [key, value]) => {
        return {
          ..._acc,
          [key as AvailabilityStatusRange]: [
            ...ensureArray(acc[key as AvailabilityStatusRange]),
            ...ensureArray(value),
          ],
        };
      }, {} as AvailabilityModifiersMap),
    };
  }, {} as AvailabilityModifiersMap);
};

export const getRangesByStatus = (availabilities: Availability[]) => {
  return mergeModifiers(
    ...availabilities.map(({ status, from, to }) =>
      makeModifier(from, to, status)
    )
  );
};

export const getDateRange = (day: Date, duration = AVAILABILITY_DURATION) => {
  if (duration === AvailabilityIssuePeriod.WEEKLY) {
    const from = moment(day).startOf("isoWeek").toDate();
    const to = moment(from)
      .add(duration - 1, "days")
      .toDate();
    return { from, to };
  } else if (duration === AvailabilityIssuePeriod.BIWEEKLY) {
    const isoWeek = moment(day).isoWeek();
    const from = moment(day)
      .isoWeek(isoWeek % 2 === 0 ? isoWeek - 1 : isoWeek)
      .startOf("week")
      .toDate();
    const to = moment(from)
      .add(duration - 1, "days")
      .toDate();
    return { from, to };
  }

  return { from: day, to: day };
};

export const getFirstFutureRangeDate = (duration = AVAILABILITY_DURATION) => {
  const { to } = getDateRange(new Date(), duration);
  return moment(to).add(1, "day").toDate();
};

export const makeHoverModifier = (day: Date) => {
  const { from, to } = getDateRange(day);
  return makeModifier(from, to, AvailabilityStatusCustom.HOVER);
};

const availabilityTitles: Record<
  AvailabilityStatus,
  string | ((value: Availability) => string)
> = {
  [AvailabilityStatus.FREE]: "Disponibile",
  [AvailabilityStatus.MAINTENANCE]: "Impianto in manutenzione",
  [AvailabilityStatus.UNAVAILABLE]: "Impianto non disponibile",
  [AvailabilityStatus.BOOKED]: (availability) =>
    availability.order?.advertiser || "Impianto in uso",
};

const stringFactory =
  <K extends PropertyKey, T extends { [key in TK]: K }, TK extends keyof T>(
    factoryByKeyMap: Record<K, string | ((value: T) => string)>,
    key: TK
  ) =>
  (value: T): string => {
    const factory = factoryByKeyMap[value[key]];
    if (factory instanceof Function) {
      return (factory as (value: T) => string)(value);
    }
    return factory as string;
  };

export const getTitle = stringFactory(availabilityTitles, "status");

const availabilityDescriptions: Record<
  AvailabilityStatus,
  string | ((value: Availability) => string)
> = {
  [AvailabilityStatus.FREE]: " ",
  [AvailabilityStatus.MAINTENANCE]: " ",
  [AvailabilityStatus.UNAVAILABLE]: " ",
  [AvailabilityStatus.BOOKED]: ({ order }) => {
    if (order) {
      return (
        [`Campagna ${order.campaign_name}`, `ID Ordine: ${order.id}`].join(
          "\n"
        ) || " "
      );
    }
    return " ";
  },
};

export const getDescription = stringFactory(availabilityDescriptions, "status");
