import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTable, useFlexLayout } from "react-table";
import { actions as ContentActions, selectors as ContentSelectors } from '../../../store/slices/content';
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import debounce from "lodash/debounce";
import ReactTooltip from "react-tooltip";
import { Grid, Modal, Tooltip, Zoom } from '@material-ui/core';
import { Styles, cancelButton } from '../Campain.css';
import { customBillboardIcon } from '../../CustomBillboardIcon';
import Icon from '../../IconsComponent';
import "react-circular-progressbar/dist/styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import placeholder from '../../../assets/images/placeholder.jpg'
import billboard_placeholder from '../../../assets/images/billboard_placeholder.svg'
import _ from 'lodash';

const CustomTooltip = ({ icon, titleText, bodyText }: any) => {
  return (
    <Tooltip style={{ marginLeft: 10 }} title={
      <div className='d-sm-flex flex-column align-itemns-center'>
        <div className='d-sm-flex align-itemns-center'>
          {icon}
          <span style={{ color: 'black' }}>{titleText}</span>
        </div>
        <span style={{ fontSize: 12, color: '#92929D' }}>
          {bodyText}
        </span>
      </div>
    } TransitionComponent={Zoom}>
      <span>
        {icon}
      </span>
    </Tooltip>
  )
}

const rebuildTooltip = debounce(() => ReactTooltip.rebuild(), 200, {
  leading: false,
  trailing: true
});

const iconSize = 18

export const ListSelectedPlants = (props: any) => {
  const { setBillboardsNumber, selectedPlants } = props
  const listRef = useRef(null);

  let plantsSelected = useSelector(ContentSelectors.getSelectedPlants)

  const columns = useMemo(
    () => [
      {
        Header: "Comune / Indirizzo",
        width: 170,
        accessor: (props: any) => {
          console.log("rowProps",props)
          const imageUrl = /* props.imageUrl ? props.imageUrl :  */placeholder
          return (
            // <div style={{ fontSize: 20 }} className="d-sm-flex align-items-center mt-3">
            //   <Icon color="#00e68a" size={35} icon="impianto" />
            //   <span className="ml-3">{props.municipality.length > 15 ? `${props.municipality.substring(0, 15)}..` : props.municipality}<br /><span style={{ color: "#1a88ff", fontSize: 16 }} >{props.address.length > 15 ? `${props.address.substring(0, 15)}..` : props.address}</span> - <span style={{ fontSize: 16, opacity: 0.5 }}>{props.base} x {props.height}</span></span>
            // </div>

            <div style={{ fontSize: 14 }} className="d-sm-flex align-items-center mt-3">
              {/* <img data-cy="avatar2" className="plantAvatar" src={billboard_placeholder} /> */}
              {customBillboardIcon(props.media_type)}
              <Grid container item xs={12}>
                <Grid item xs={7}>
                  {props.address.length < 18 ? props.address : `${props.address.substring(0, 18)}..`}
                </Grid>
                <Grid className="d-sm-flex flex-row-reverse" item xs={5}>
                  <span style={{ ...cancelButton, fontSize: 11, padding: '2px 4px 2px 4px', borderRadius: 4 }} className="text-muted">{props.base} x {props.height}</span>
                </Grid>
                {/* <br /> */}
                <Grid item xs={6}>{props.municipality}
                  {props.is_draft 
                    ? <CustomTooltip
                      icon={<Icon className='mr-1' size={iconSize} color="#FFC542" icon="activity" />}
                      titleText={'Richiesta disponibilità'}
                      bodyText={'Stiamo verificando la disponibilità dello spazio per le date selezionate.Entro 72 ore riceverai l’eventuale conferma'} />
                    : <CustomTooltip
                      icon={<Icon className='mr-1' size={iconSize} color="#00e68a" icon="task_list" />}
                      titleText={'Spazio disponibile'}
                      bodyText={'Lo spazio è disponibile per le date selezionate.'} />
                  }
                </Grid>
                <Grid className="d-sm-flex flex-row-reverse" style={{fontSize:12, opacity: 0.5}} item xs={6}>
                  {/* <p>prenotato per {'666'} giorni</p> */}
                {/* moment('data inizio').diff(moment('data fine'), 'days') */}
                  </Grid>
              </Grid>
            </div>
          )
        }
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    rows,
    prepareRow,
  }: any = useTable(
    {
      columns,
      data: plantsSelected,
    } as any,
    useFlexLayout,
  )

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);

      return (
        <div className="tr-group" style={style}>
          <div className="tr d-flex align-items-center">
            {row.cells.map((cell: any) => {
              return (
                <div {...cell.getCellProps()} className="td text-dark poppinsFont">
                  {cell.render("Cell")}
                </div>
              );
            })}
          </div>
        </div>
      );
    },
    [prepareRow, rows]
  );

  const itemData = {
    rows,
    prepareRow,
  }

  useEffect(() => {
    setBillboardsNumber(rows.length)
    console.log("selectedPlants", plantsSelected)
  }, [rows, plantsSelected])

  return (
    <Styles>
      <div {...getTableProps()} className="table mt-2">
        <div data-cy="tableBody" className="tbodyConfirm" {...getTableBodyProps()}>
          <AutoSizer onResize={rebuildTooltip}>
            {({ height, width }) => (
              <VariableSizeList
                height={height}
                itemCount={rows.length}
                itemData={itemData}
                // Hardcoded values only :/
                itemSize={(i) => (rows[i] && rows[i].isExpanded ? 250 : 80)}
                width={width}
                onScroll={rebuildTooltip}
                ref={listRef}
              >
                {RenderRow}
              </VariableSizeList>
            )}
          </AutoSizer>
        </div>
      </div>
    </Styles>
  );
}