import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../../store/slices/ui';
import { useStyles, cancelButtonFilters, confirmButtonFilters, cancelButtonCampaign, confirmButtonCampaign } from '../campaign/Campain.css';
import { Grid, Chip, Popover, Modal } from '@material-ui/core';
import Icon from '../IconsComponent';
import { Budget } from '../filters/Budget';
import { Period } from '../filters/Period';
import { Municipality } from '../filters/Municipality';
import { Typology } from '../filters/TypologyFilter';
import { PlantPrice } from '../filters/PlantPrice';
import { Audience } from '../filters/Audience';
import { Format } from '../filters/FormatFilter';
import { ClassAndTypology } from '../filters/ClassAndTypology';
import { IVMunicipality } from '../filters/IVMunicipalityFilter';
import { Interest } from '../filters/Interest';
import { DraftCampainModal } from '../DraftCampainModal';
import { ObjectiveChoice } from './ObjectiveChoice';
import { FilterListComponent } from './FilterListComponent';

import moment from 'moment';
import _ from 'lodash';

interface FilterListType {
  planningType: string;
  name: string;
  chip: string;
  icon: string;
  filterValue: string;
  filterCondition: boolean;
  multiChip?: any;
}


export const Campain = (props: any) => {
  const { handleStepsArray, setPlanningType } = props
  const classes = useStyles();
  const dispatch = useDispatch()

  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [planningTypeFilter, setPlanningTypeFilter] = useState("")
  const [modalType, setModalType] = useState("")
  const [objectiveModalOpen, setObjectiveModalOpen] = useState(false)

  let filters = useSelector(ContentSelectors.didGetFilter)
  const switchModalOpen = useSelector(UiSelectors.switchDraftCampainModal)
  let order_date = useSelector(ContentSelectors.getOrderDate)

  let remainingTime = {} as any

  if (order_date) {
    remainingTime = moment(order_date).diff(moment().add(30, 'minutes'))
  } else {
    remainingTime = moment()
  }

  useEffect(() => {
    if (order_date) {
      if (moment(order_date).isAfter(moment(order_date).add(30, 'minutes'))/* moment() >= moment(order_date).add(30, 'minutes') */) {
        console.log("time is over")
        setModalType("endOfTime")
        dispatch(UiActions.switchDraftCampainModal(''))
      } else {
        console.log("remaining time:", moment(remainingTime).format('mm:ss'))
      }
    }
    setModalType("none")
    
  }, [filters])

  const open = Boolean(anchor);
  const id = open ? 'simple-popover' : undefined;

  const required = () => {
    if (_.isEmpty(filters)) {
      console.log("no filters")
      // setModalType("start")
      dispatch(UiActions.switchDraftCampainModal(''))
    }
    else if (!filters.period || !filters.city) {
      console.log("no city or period filter")
      setModalType("required")
      dispatch(UiActions.switchDraftCampainModal(''))
    }
    else {
      setObjectiveModalOpen(true)
    }
  }

  const setPopoverAnchor = () => {
    const anchorElement: any = document.getElementById('mapComponent')
    setAnchor(anchorElement)
  }

  const filtersList: FilterListType[] = [
    {
      planningType: 'Period',
      name: 'Periodo',
      chip: `Da ${filters.period?.date.from} a ${filters.period?.date.to}`,
      icon: 'calendar',
      filterCondition: !_.isEmpty(filters.period),
      filterValue: 'period'
    },
    {
      planningType: 'Municipality',
      name: 'Località',
      chip: `${filters.city && filters.city.length !== 0 ? filters.city : ''}`,
      icon: 'place',
      filterCondition: filters.city && filters.city.length !== 0,
      filterValue: 'city',
      multiChip: true
    },
    {
      planningType: 'ClassAndTypology',
      name: 'Classe e tipologia',
      chip: `${filters.media_type ? filters.media_type : ''}`,
      icon: 'spreadSheet',
      filterCondition: filters.media_type && filters.media_type.length !== 0,
      filterValue: 'media_type',
      multiChip: true
    },
    {
      planningType: 'PlantPrice',
      name: 'Prezzo Impianti',
      chip: `Da ${filters.price_from ? filters.price_from : ""}€ a ${filters.price_to ? filters.price_to : ""}€`,
      icon: 'administration',
      filterCondition: filters.price_from || filters.price_to,
      filterValue: 'price'
    },
    {
      planningType: 'Format',
      name: 'Formato',
      chip: `Formati: ${filters.format}`,
      icon: 'full',
      filterCondition: filters.format,
      filterValue: 'format',
      multiChip: true
    },
    {
      planningType: 'IVMunicipality',
      name: 'Indice Visibilità',
      chip: `Da ${filters.iV_municipality_from ? filters.iV_municipality_from : ""} a ${filters.iV_municipality_to ? filters.iV_municipality_to : ""}`,
      icon: 'chat',
      filterCondition: filters.iV_municipality_from && filters.iV_municipality_to,
      filterValue: 'iV_municipality_from'
    },
  ]

  return (
    <>
      <Modal open={objectiveModalOpen} onClose={() => setObjectiveModalOpen(false)}>
        <div className="d-flex justify-content-center align-items-center" >
          <ObjectiveChoice handleStepsArray={handleStepsArray} setObjectiveModalOpen={setObjectiveModalOpen} />
        </div>
      </Modal>
      <Modal open={switchModalOpen} onClose={() => dispatch(UiActions.switchDraftCampainModal(''))}>
        <DraftCampainModal setAnchor={setPopoverAnchor} setPlanningType={setPlanningTypeFilter} modalType={modalType} />
      </Modal>
      <div className=" poppinsFont">
        <Grid data-cy="campain" style={{ marginTop: 0 }} container spacing={4}>
          <Grid className="text-secondary ml-4" item xs={12}>Filtri Impianti</Grid>
          {filtersList.map((filter: FilterListType, index: number) => {

            return (
              <React.Fragment key={index}>
                <FilterListComponent
                  planningType={filter.planningType}
                  planningTypeSelected={planningTypeFilter}
                  setAnchor={setPopoverAnchor}
                  setPlanningType={setPlanningTypeFilter}
                  filterValue={filter.filterValue}
                  filterCondition={filter.filterCondition}
                  chipText={filter.filterValue ? filter.chip : ''}
                  name={filter.name}
                  icon={filter.icon}
                  multiChip={filter.multiChip}
                />
              </React.Fragment>
            )
          })}

          <Grid>
            <Popover
              className={classes.popoverInFilters}
              id={id}
              open={open}
              anchorEl={anchor}
              onClose={() => { setAnchor(null); setPlanningTypeFilter(""); }}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }} >
              {planningTypeFilter === "Period" && <Period setAnchor={setAnchor} setPlanningType={setPlanningTypeFilter} />}
              {planningTypeFilter === "Municipality" && <Municipality setAnchor={setAnchor} setPlanningType={setPlanningTypeFilter} />}
              {/* {planningTypeFilter === "Typology" && <Typology setAnchor={setAnchor} setPlanningType={setPlanningTypeFilter} />} */}
              {planningTypeFilter === "PlantPrice" && <PlantPrice setAnchor={setAnchor} setPlanningType={setPlanningTypeFilter} />}
              {planningTypeFilter === "Audience" && <Audience setAnchor={setAnchor} setPlanningType={setPlanningTypeFilter} />}
              {planningTypeFilter === "Format" && <Format setAnchor={setAnchor} setPlanningType={setPlanningTypeFilter} />}
              {planningTypeFilter === "IVMunicipality" && <IVMunicipality setAnchor={setAnchor} setPlanningType={setPlanningTypeFilter} />}
              {planningTypeFilter === "ClassAndTypology" && <ClassAndTypology setAnchor={setAnchor} setPlanningType={setPlanningTypeFilter} />}
            </Popover>

          </Grid>
        </Grid>
        <Grid style={{ position: 'absolute', bottom: 30, right: 0, left: 0 }} className={`${classes.buttonBackground}`} container>
          <button data-cy="goBack" style={cancelButtonCampaign} onClick={() => {
            dispatch(ContentActions.didSetFilter({}))
            dispatch(UiActions.didSetCampaignStep(""))
            setPlanningType('Scelta')
            // dispatch(UiActions.didSetCampaignStep(''))
            // dispatch(UiActions.goTo("/planning"))
          }}>Annulla</button>
          <button data-cy="confirm" style={confirmButtonCampaign} onClick={required}>Conferma</button>
        </Grid>
      </div>
    </>
  )
}

//        {campaignStep !== "Pianificazione guidata" && <div style={{ width: 200, height: 100, fontSize: 18, borderRadius: 14 }} className="d-sm-flex flex-column align-items-center bg-white p-2 poppinsFont">
//           <span>{campaignStep === "Pianifica campagna" ? "Impianti selezionati" : "Impianti trovati"}</span>
//           <div className="d-sm-flex align-items-center mt-2">
//             <Icon color="#66b0ff" size={25} icon="impianto" />
//             <b style={{ fontSize: 30, marginLeft: 10 }}>{campaignStep === "Pianifica campagna" ? `+ ${_.size(selectedBillboards)}` : points.length}</b>
//           </div>
//         </div>}