import React, { useState, useMemo, useCallback, useEffect, forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ButtonDropdown, UncontrolledDropdown, Dropdown, DropdownToggle, DropdownItem, DropdownMenu, Button, Row, Col, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalBody, ModalFooter, ModalHeader, Label } from "reactstrap";
import { useTable, useSortBy, useFlexLayout, useExpanded, useGlobalFilter, useAsyncDebounce, useRowSelect, useGroupBy } from "react-table";
import { VariableSizeList, FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from 'react-window-infinite-loader';
import ReactTooltip from "react-tooltip";
import debounce from "lodash/debounce";
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { TextField, InputAdornment } from '@material-ui/core';
import Icon from "../components//IconsComponent";
import { Styles, useStyles, orderStatusSwitch, draftButtons } from "../components/campaign/Campain.css";
import { NotificationContainer } from "../components/notification-layer/NotificationContainer";
import placeholder from '../assets/images/placeholder.jpg'
import _ from 'lodash';
import moment from 'moment';
// import { Grid, Modal } from '@material-ui/core';

const currencyFormatter = (value: number) => {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
}

const rebuildTooltip = debounce(() => ReactTooltip.rebuild(), 200, {
  leading: false,
  trailing: true
});

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = useRef() as any
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <div>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </div>
    )
  }
)

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: any) => {
  const classes = useStyles()
  const count = preGlobalFilteredRows.length
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <TextField
      data-cy="inputList"
      className={classes.searchCampaign}
      style={{ width: 600 }}
      variant="outlined"
      id="searchInput"
      size="small"
      value={value || ""}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon color="gray" icon="search" size={20} />
          </InputAdornment>
        ),
        classes: { notchedOutline: classes.noBorders }
      }}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Cerca per nome campagna, stato...`}
    />
  )
}


const Table = () => {
  const listRef = useRef(null);
  const dispatch = useDispatch()
  const classes = useStyles();
  const [orderColumn, setOrderColumn] = useState([] as any)
  const [modalDownload, setModalDownload] = useState(false)

  const [actionOpen, setActionOpen] = useState(false);
  const [actionOpenShow, setActionOpenShow] = useState(false);
  const toggleAction = () => setActionOpen(prevState => !prevState);
  const toggleActionShow = () => setActionOpenShow(prevState => !prevState);
  const [dropdownMenu, setDropdownOpen] = useState(false);
  const toggleMenu = () => setDropdownOpen(!dropdownMenu);

  let campaignList = useSelector(ContentSelectors.getMyCampaigns)
  const sortColumnValue = useSelector(ContentSelectors.getSortColumnValue)
  const sortOrderAsc = useSelector(ContentSelectors.getSortOrderAsc)

  const downloadUrls = useSelector(ContentSelectors.getDownloadUrls)

  const getOrders = (campaign: any, orders: any) => {
    //questa funzione serve qui staccata per via di alcuni problemi con onClick del componente
    dispatch(ContentActions.willGetCampaignOrders({ campaign_id: campaign.id, orders: orders }))
    dispatch(ContentActions.didSetSelectedCampaign(campaign))
    dispatch(UiActions.goTo("/campaign/campaigndetails"));
  }

  const defaultColumn = useMemo(
    () => ({
      width: 50,
      //expanded: false
    }),
    []
  );

  const columns = useMemo(
    () => [
      {
        id: "Nome Campagna",
        Header: "Nome Campagna",
        width: 60,
        accessor: (props: any) => {
          // console.log("PROPS:ID", props.id)
          //console.log(props)
          // const plantImage = `https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg`
          return (
            <div className="row d-sm-flex align-items-center text-dark poppinsFont hoverPointer" onClick={() => getOrders(props, props.orders)}>
              <img data-cy="avatar2" src={placeholder} className="mr-2 plantAvatar" />
              <div data-cy="goToDetails">{(props.name && props.name.length > 25) ? `${props.name.substring(0, 25)}..` : props.name}</div>
            </div>)
        }
      },
      {
        id: "Stato",
        width: 25,
        Header: "Stato",
        accessor: (props: any) => <div style={{ cursor: "pointer", fontSize: 15 }} className="d-sm-flex align-items-center justify-content-center maintenanceOverview" onClick={() => getOrders(props, props.orders)}>{orderStatusSwitch(props.status, props.from, props.to)}</div>
      },
      {
        id: "Inizio/Fine",
        Header: "Inizio / Fine",
        width: 55,
        accessor: (props: any) => {
          return <div style={{ cursor: "pointer" }} onClick={() => getOrders(props, props.orders)}>dal {moment(props.from).format("DD-MM-YYYY")} al {moment(props.to).format("DD-MM-YYYY")}</div>
        }
      },
      {
        id: "Comuni",
        Header: "Comuni",
        width: 20,
        accessor: (props: any) => <div style={{ cursor: "pointer" }} onClick={() => getOrders(props, props.orders)}>{props.n_municipality}</div>
      },
      // {
      //   id: "Formati",
      //   Header: "Formati",
      //   width: 20,
      //   accessor: (props: any) => <div style={{ cursor: "pointer" }} onClick={() => getOrders(props, props.orders)}>{_.map(props.orders.billboards)}</div>
      // },
      {
        id: "Importo Totale",
        Header: "Importo tot.",
        width: 30,
        accessor: (props: any) => <div style={{ cursor: "pointer" }} onClick={() => getOrders(props, props.orders)}>{currencyFormatter(_.ceil(props.total_amount, 2))}</div>
      },
      {
        id: "icons",
        width: 20,
        accessor: (props: any) => <div className="d-sm-flex align-items-center">
          {props.status !== "Draft" && props.status !== "pass" && props.status !== "canceled" && props.status !== "rejected" && moment().isAfter(moment(props.order_date).add(24, 'hours'))
            ? <button className="d-sm-flex flex-row-reverse text-secondary bg-transparent border-0 ml-2 mt-1" data-cy="assignement" onClick={() => {
              setModalDownload(true);
              dispatch(ContentActions.willGetContracts({ campaign: props.id, orders: props.orders }))
            }} ><Icon color="#66b0ff" size={25} icon="draft" /></button>
            : <button className="d-sm-flex flex-row-reverse text-secondary bg-transparent border-0 ml-2 mt-1" data-cy="assignement" ><Icon size={25} icon="draft" /></button>}
        </div>
      },
      {
        accessor: "name"
      },
      {
        accessor: "status"
      },
      {
        accessor: "from"
      },
      {
        accessor: "to"
      },
      {
        accessor: "total_amount"
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    toggleSortBy,
    preGlobalFilteredRows,
    setGlobalFilter,
    prepareRow,
    selectedFlatRows,
    state
  }: any = useTable(
    {
      columns,
      data: campaignList,
      defaultColumn,
      initialState: {
        sortBy: orderColumn,
        hiddenColumns: ["name", "status", "from", "to", "total_amount"]
      },
      autoResetSelectedRows: false,
      // autoResetExpanded: false,
      // expandSubRows: false
    } as any,
    useFlexLayout,
    useGroupBy,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          width: 7,
          Header: ({ getToggleAllRowsSelectedProps }: any) => (
            <div data-cy="selectAllrows">
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }: any) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div data-cy="orderRow" {...row.getRowProps({ className: `${row.isSelected ? "borderSelected" : ""} tr-group hoverPointerOrders` })} style={style}
        >
          <div className="d-flex align-items-center trCampaign">
            {row.cells.map((cell) => {
              return (
                <div {...cell.getCellProps()} className="td">
                  {cell.render("Cell")}
                </div>
              );
            })}
          </div>
          {/* {row.isExpanded ? renderRowSubComponent({ row }) : null} */}
        </div>
      );
    },
    [prepareRow, rows]
  );

  const fetchMoreData = () => {
    console.log('fetch More campaign...')
    // dispatch(ContentActions.willFetchMoreFirstBillboards({ orderFilters: {}, fetchMore: true }))
  };

  const itemData = {
    rows,
    prepareRow,
  }

  const SortColumn = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const [sorted, setSorted] = useState("")

    useEffect(() => {
      setSorted(sortColumnValue)
    }, [sortColumnValue])

    useEffect(() => {
    }, [downloadUrls])
    return (
      <div >
        <Dropdown data-cy="orderBy" className="mr-3" isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle data-cy="sortButton" className="bg-white text-dark border-0 mr-3" caret>Ordina per: <b>{sorted}</b>  </DropdownToggle>
          <DropdownMenu className={classes.drowdownMenu}>
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps({ style: { width: 200 } })} >
                <div className="column">
                  {headerGroup.headers.filter((item) => { return item.id !== "selection" && item.id !== "Opzioni" && item.id !== "icons" }).map((column) => {
                    // console.log("COLUMS", column)
                    return (
                      <DropdownItem
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        onClick={() => {
                          console.log('in sorting orders: ', column.id)
                          dispatch(ContentActions.didSetSortColumnValue({ sortColumnValue: column.id, sortOrderAsc: true }))
                          // toggleSortBy(column.id, true, false)                                        //ordinamento
                          // listRef.current && (listRef.current! as any).resetAfterIndex(0, true);
                        }}
                        style={{ width: 200 }}
                        data-cy={`choice${column.id}`}
                      >
                        <input className="mr-2" type="checkbox" checked={sorted == column.id ? true : false} readOnly />
                        {column.render("Header")}
                      </DropdownItem>
                    )
                  })}
                </div>
              </div>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    )
  }

  return (
    <div className="campaignListPadding">
      {/* {!_.isEmpty(downloadUrls) && <DraftContracts setContractsUrls={downloadUrls} />} */}

      <>
        <div className="d-sm-flex">
          <div className="column mr-auto mb-2" >
            <div className="d-sm-flex align-items-center p-2 poppinsFont" >
              <span style={{ fontSize: 30 }} >Campagne</span>
              <Dropdown isOpen={actionOpenShow} toggle={toggleActionShow}>
                <DropdownToggle disabled data-cy="sortButton" className="d-sm-flex align-items-center bg-transparent text-dark border-0 ml-4" caret><span style={{ fontSize: 23, opacity: 0.5, marginRight: 7 }}>Mostra:</span> <b style={{ fontSize: 20 }}>{"Tutti"}</b>  </DropdownToggle>
                <DropdownMenu className={classes.drowdownMenu}>
                  <DropdownItem disabled onClick={() => { }}><Icon color="gray" icon="refund" /> Aggiorna Stato</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="p-2 mr-6">
            <div className="row">
              <SortColumn />
              {/* <OrdersFilter /> */}
            </div>
          </div>
        </div>
      </>
      <div {...getTableProps({ style: { padding: 10, borderRadius: 15 } })} className="table bg-white p-3">
        <div className="d-sm-flex bd-highlight ml-2 mb-3 p-2 poppinsFont">
          <Dropdown disabled={selectedFlatRows[0] ? false : true} isOpen={actionOpen} toggle={toggleAction}>
            <DropdownToggle data-cy="actionButton" className={`${selectedFlatRows[0] ? "backgroundSelected text-white" : "bg-transparent text-dark"} border`} caret><>Azioni</>  </DropdownToggle>
            <DropdownMenu className={classes.drowdownMenu}>
              <DropdownItem disabled onClick={() => { }}><Icon color="gray" icon="block" /> Rimuovi</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Form data-cy="globalFilter" className="ml-3">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter} />
          </Form>
        </div>
        <Modal style={{ maxWidth: 400, width: '100%' }} isOpen={!_.isEmpty(downloadUrls)} toggle={() => {
          dispatch(ContentActions.didPutDownloadUrls({ urls: [] }))
        }}>
          <ModalHeader toggle={() => {
            dispatch(ContentActions.didPutDownloadUrls({ urls: [] }))
          }}>
            <b className="ml-3">Contratti</b>
          </ModalHeader>
          <ModalBody>
            {/* <div className="d-sm-flex justify-content-between align-items-center border-bottom p-2"> */}
            <br />
            <div >
              <ul>
                {_.map(downloadUrls, function (e, i) {
                  // if (e.includes("contratto_ordine")) {
                  if (e.includes("contratto_campagna")) {
                    return (
                      <dl><button className="mt-1" style={draftButtons} onClick={() => window.open(e)}>Scarica conferma della campagna</button></dl>
                    )
                  } else {
                    return (
                      <dl> <button className="mt-1" style={draftButtons} onClick={() => window.open(e)}>Scarica contratto concessionaria n {i}</button></dl>
                    )
                  }
                })}
                <dl><button className="mt-1" style={draftButtons} onClick={() => {
                  let url = window.location.origin
                  if (window.location.origin.includes("8080") || window.location.origin.includes("3000")) {
                    url = "https://ssp-dev.billalo.it"
                  }
                  window.open(`${url}/resources/termini/Termini+e+Condizioni+generali+di+utilizzo+Advertiser.pdf`)
                }}>Scarica i termini e condizioni generali di utilizzo</button></dl>
              </ul>
            </div>
          </ModalBody>
        </Modal>

        <div className="thead">
          {headerGroups.map((headerGroup: any) => (
            <div {...headerGroup.getHeaderGroupProps({ style: { backgroundColor: "#fafafb", borderRadius: 15 } })} className="tr hoverPointer">
              {headerGroup.headers.map((column: any) => (
                <div
                  {...column.getHeaderProps()}
                  className="th text-dark"              //ordinamento
                  onClick={() => {
                    if (column.id === "selection" || column.id === "expander" || column.id === "icons" || column.id === "Opzioni") {
                      console.log("not sortable to:", column.id)
                    } else {
                      dispatch(ContentActions.didSetSortColumnValue({ sortColumnValue: column.id, sortOrderAsc: !sortOrderAsc }))
                    }
                  }}
                >
                  {column.render("Header")}
                  <span>
                    {sortOrderAsc == column.id ? (sortOrderAsc ? " 🔼" : " 🔽") : ""}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div data-cy="tableBody" className="tbodyCampaign" {...getTableBodyProps({ style: { margin: 2, borderRadius: 15, backgroundColor: "white", overflow: "hidden" } })}>
          {(campaignList[0] && campaignList[0] !== 'empty') ? <AutoSizer onResize={rebuildTooltip}>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={(index: any) => index < rows.length - 4 ? true : false}
                itemCount={rows.length}
                loadMoreItems={fetchMoreData as any}
              >
                {({ onItemsRendered, ref }: any) => (
                  <VariableSizeList
                    className="overflowCampaignList"
                    height={height}
                    itemCount={rows.length}
                    itemData={itemData}
                    // Hardcoded values only :/
                    itemSize={(i) => (rows[i] && rows[i].isExpanded ? 250 : 80)}
                    width={width}
                    onScroll={rebuildTooltip}
                    onItemsRendered={onItemsRendered}
                    ref={listRef}
                  >
                    {RenderRow}
                  </VariableSizeList>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer> : <span style={{ fontSize: 30 }} className="d-sm-flex justify-content-center mt-5 poppinsFont">Nessun risultato trovato</span>}
        </div>
      </div>
    </div>
  );
}

export const CampaignList = () => {
  // const token = useSelector(ContentSelectors.getMoreMyBillboardsNextToken)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(ContentActions.willListMyCampaigns())
    dispatch(UiActions.setSelectedPage("Campagne"))
  }, [])

  return (
    <>
    <NotificationContainer />
    <Styles>
      <Table />
    </Styles>
    </>
  );
}
