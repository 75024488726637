import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { useStyles, cancelButtonFilters, confirmButtonFilters } from '../campaign/Campain.css';
import { Grid, Chip, FormControlLabel, Checkbox } from '@material-ui/core';
import Icon from '../IconsComponent';
import _ from 'lodash';

const EmptyCheck = () => {
  return (
    <div data-cy="emptyCheck" style={{ width: 25, height: 25, borderWidth: 1, borderStyle: "solid", borderColor: "#92929D", borderRadius: 5 }} />
  )
}
const FilledCheck = () => {
  return (
    <div style={{ width: 25, height: 25, borderWidth: 1, borderStyle: "solid", borderColor: "#00e68a", borderRadius: 5, position: "relative" }} >
      <div className="d-sm-flex align-items-center">
        <Icon size={25} color="#00e68a" icon="check" />
      </div>
    </div>
  )
}

const CustomCheckBox = (props: any) => {
  const { value, type, name, setData, disabled } = props
  // const [checked, setChecked] = useState(false)
  let array = [...value] as any[]

  const updateArray = (format: string) => {
    // let array: any[] = [...data]
    if (array.includes(format)) {
      setData(array.filter((o: string) => { return o !== format }))
    } else {
      array.push(format)
      setData(array)
    }
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={array.includes(type)}
          onClick={() => updateArray(type)}
          disabled={disabled}
          icon={<EmptyCheck />}
          checkedIcon={<FilledCheck />}
          name={name} />} label=""
    />
  )
}

export const Format = (props: any) => {
  const { setAnchor, setPlanningType } = props
  const classes = useStyles();
  const dispatch = useDispatch()

  const filters = useSelector(ContentSelectors.didGetFilter)
  const [data, setData] = useState(filters.format ? filters.format : [] as any[])

  const applyFunction = () => {
    dispatch(ContentActions.willUploadFilter({ key: "format", value: data }))
    setAnchor(null)
    setPlanningType("")
    dispatch(ContentActions.willFilterBillboards({ filters: {}, is_confirm: false }))
  }

  return (
    <div className={`${classes.root} poppinsFont`}>
      <Grid container spacing={3}>
        <Grid item xs={12}><b>Formato</b></Grid>
        <Grid container item xs={12}>

          <Grid container alignItems="center" >
            <Grid item xs={10}>1200x600</Grid>
            <Grid item xs={2}><CustomCheckBox disabled={false} value={data} type={"1200x600"} name={"1200x600"} setData={setData} /></Grid>
          </Grid>

          <Grid container alignItems="center" >
            <Grid item xs={10}>600x300</Grid>
            <Grid item xs={2}><CustomCheckBox disabled={false} value={data} type={"600x300"} name={"600x300"} setData={setData} /></Grid>
          </Grid>

          <Grid container alignItems="center" >
            <Grid item xs={10}>400x300</Grid>
            <Grid item xs={2}><CustomCheckBox disabled={false} value={data} type={"400x300"} name={"400x300"} setData={setData} /></Grid>
          </Grid>

          <Grid container alignItems="center" >
            <Grid item xs={10}>300x200</Grid>
            <Grid item xs={2}><CustomCheckBox disabled={false} value={data} type={"300x200"} name={"300x200"} setData={setData} /></Grid>
          </Grid>

          <Grid container alignItems="center" >
            <Grid item xs={10}>140x200</Grid>
            <Grid item xs={2}><CustomCheckBox disabled={false} value={data} type={"140x200"} name={"140x200"} setData={setData} /></Grid>
          </Grid>

          <Grid container alignItems="center" >
            <Grid item xs={10}>100x140</Grid>
            <Grid item xs={2}><CustomCheckBox disabled={false} value={data} type={"100x140"} name={"100x140"} setData={setData} /></Grid>
          </Grid>

          <Grid container alignItems="center" >
            <Grid item xs={10}>70x100</Grid>
            <Grid item xs={2}><CustomCheckBox disabled={false} value={data} type={"70x100"} name={"70x100"} setData={setData} /></Grid>
          </Grid>

          <Grid container alignItems="center" >
            <Grid item xs={10}>Altro</Grid>
            <Grid item xs={2}><CustomCheckBox disabled={false} value={data} type={"Altro"} name={"altro"} setData={setData} /></Grid>
          </Grid>

        </Grid>
        <Grid className={classes.buttonsFilterContainer} container>
          <Grid container item xs={6} alignItems="center" justify="center">
            <button
              style={cancelButtonFilters}
              data-cy="goBack"
              onClick={() => { setAnchor(null); setPlanningType("") }}
            >Annulla</button>
          </Grid>
          <Grid container item xs={6} alignItems="center" justify="center">
            <button data-cy="apply" style={confirmButtonFilters} onClick={applyFunction}>Applica</button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}