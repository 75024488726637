import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTable, useFlexLayout } from "react-table";
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import debounce from "lodash/debounce";
import ReactTooltip from "react-tooltip";
import { useStyles, goToPaymentButton, municipalityGraphic, Styles, headerTableConcessionarie, rowTableConcessionarie, cancelButton } from '../Campain.css';
import "react-circular-progressbar/dist/styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import placeholder from '../../../assets/images/placeholder.jpg'
import _ from 'lodash';

const rebuildTooltip = debounce(() => ReactTooltip.rebuild(), 200, {
  leading: false,
  trailing: true
});

export const ListMediaOwners = (props: any) => {
  const { ownersArray } = props
  const listRef = useRef(null);

  const columns = useMemo(
    () => [
      {
        Header: "RAGIONE SOCIALE",
        width: 80,
        accessor: (props: any) => {
          const imageUrl = (props.imageUrl ? props.imageUrl : placeholder)
          return (
            <div className="d-sm-flex align-items-center">
              <img data-cy="avatar2" className="mr-1 plantAvatar" src={imageUrl} />
              <span>{_.startCase(props.media_owner)}</span>
            </div>)
        }
      },
      {
        Header: "SEDE LEGALE",
        width: 80,
        accessor: () => <>Viale Marconi 58 - Cagliari</>
      },
      {
        Header: "P.IVA",
        width: 80,
        accessor: () => <>321681313813513813GF</>
      },
      {
        Header: "N.IMPIANTI",
        width: 40,
        accessor: "plants"
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  }: any = useTable(
    {
      columns,
      data: ownersArray, /// <--- qui ci vanno i dati della lista
    } as any,
    useFlexLayout,
  )

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);

      return (
        <div className="tr-group mt-4" style={style}>
          <div className="tr d-sm-flex ml-3 align-items-center">
            {row.cells.map((cell: any) => {
              return (
                <div {...cell.getCellProps()} className="td text-dark poppinsFont">
                  {cell.render("Cell")}
                </div>
              );
            })}
          </div>
        </div>
      );
    },
    [prepareRow, rows]
  );

  const itemData = {
    rows,
    prepareRow,
  }
  // useEffect(() => {
  //   console.log("ownersArray in ListConcessionarie", ownersArray)
  // }, [rows])

  return (
    <Styles>
      <div {...getTableProps({ style: { overflow: "none" } })} className="mt-3">
        <div data-cy="tableBody" className="tbody" {...getTableBodyProps({ style: rowTableConcessionarie })}>
          <div className="thead">
            {headerGroups.map((headerGroup) => {
              // console.log("headerGroup", headerGroup, headerGroups)
              return (
                <div {...headerGroup.getHeaderGroupProps({ style: headerTableConcessionarie })} className="tr">
                  {headerGroup.headers.map((column) => (
                    <div
                      {...column.getHeaderProps()}    //ordinamento
                      className="th text-dark"
                    >
                      {column.render("Header")}
                    </div>
                  ))}
                </div>
              )
            })}
          </div>
          <AutoSizer onResize={rebuildTooltip}>
            {({ height, width }) => (
              <VariableSizeList
                height={height}
                itemCount={rows.length}
                itemData={itemData}
                // Hardcoded values only :/
                itemSize={(i) => (rows[i] && rows[i].isExpanded ? 250 : 80)}
                width={width}
                onScroll={rebuildTooltip}
                ref={listRef}
              >
                {RenderRow}
              </VariableSizeList>
            )}
          </AutoSizer>
        </div>
      </div>
    </Styles>
  );
}