import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useTable, useFlexLayout } from "react-table";
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { Tooltip, Zoom } from '@material-ui/core';
import { Styles, headerTableConcessionarie, rowTableConcessionarie, useStyles, tooltip } from './campaign/Campain.css';
import { notificationTextChange, setBadge } from './CampaignDetails.css';
import debounce from "lodash/debounce";
import ReactTooltip from "react-tooltip";
import "react-circular-progressbar/dist/styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import placeholder from '../assets/images/placeholder.jpg'
import Icon from './IconsComponent';
import _ from 'lodash';

const rebuildTooltip = debounce(() => ReactTooltip.rebuild(), 200, {
  leading: false,
  trailing: true
});

export const MediaOwnersList = (props: any) => {
  const { setAnchorEl, ownersArray, campaignOrders } = props
  const listRef = useRef(null);
  const classes = useStyles()
  // console.log("campaignOrders", campaignOrders)

  const columns = useMemo(
    () => [
      {
        Header: "RAGIONE SOCIALE",
        width: 60,
        accessor: (props: any) => {
          const imageUrl = (props.imageUrl ? props.imageUrl : placeholder)
          // let invisible: boolean = false
          let invisible: boolean = setBadge(props.order_date, props.status, 500, props.billboards.length, props.from, props.to).invisible
          let notification = notificationTextChange(setBadge(props.order_date, props.status, 500, props.billboards.length, props.from, props.to).notification)
          let badgeStyle = setBadge(props.order_date, props.status, 500, props.billboards.length, props.from, props.to).style

          return (
            <div className="d-sm-flex align-items-center">
              <img data-cy="avatar2" className="mr-1 plantAvatar" src={imageUrl} />
              <span>{_.startCase(props.media_owner)}</span>
              {invisible ? null :
                <Tooltip
                  title={<span style={{ color: notification === "Attesa materiali" ? "#92929d" : "#fc5a5a" }}><Icon color={notification === "Attesa materiali" ? "#ffc542" : "#fc5a5a"} size={15} icon="info" /> {notification}</span>} TransitionComponent={Zoom}>
                  <div style={badgeStyle} />
                </Tooltip>}
            </div>)
        }
      },
      {
        Header: "SEDE LEGALE",
        width: 60,
        accessor: () => <>Viale Marconi 58 - Cagliari</>
      },
      {
        Header: "P.IVA",
        width: 30,
        accessor: () => <>3216813138..</>
      },
      {
        Header: "N.IMPIANTI",
        width: 20,
        accessor: (props: any) => <>{props.billboards.length}</>
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  }: any = useTable(
    {
      columns,
      data: campaignOrders,
    } as any,
    useFlexLayout,
  )

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);

      return (
        <div className="tr-group mt-4" style={style}>
          <div className="tr d-sm-flex ml-3 align-items-center">
            {row.cells.map((cell: any) => {
              return (
                <div {...cell.getCellProps()} className="td text-dark poppinsFont">
                  {cell.render("Cell")}
                </div>
              );
            })}
          </div>
        </div>
      );
    },
    [prepareRow, rows]
  );

  const itemData = {
    rows,
    prepareRow,
  }
  useEffect(() => {
    // console.log("ownersArray in ListConcessionarie", ownersArray)
    console.log("campaignOrders in ListConcessionarie", campaignOrders)
  }, [rows, campaignOrders])

  return (
    <Styles>
      <div {...getTableProps({ style: { overflow: "hidden", width: setAnchorEl ? 900 : "", minHeight: 250, maxHeight: 320 } })} className="mt-3">
        <div data-cy="tableBody" className="tbody" {...getTableBodyProps({ style: rowTableConcessionarie })}>
          <div className="thead">
            {headerGroups.map((headerGroup) => {
              // console.log("headerGroup", headerGroup, headerGroups)
              return (
                <div {...headerGroup.getHeaderGroupProps({ style: headerTableConcessionarie })} className="tr">
                  {headerGroup.headers.map((column) => (
                    <div
                      {...column.getHeaderProps()}    //ordinamento
                      className="th text-dark"
                    >
                      {column.render("Header")}
                    </div>
                  ))}
                </div>
              )
            })}
          </div>
          <AutoSizer onResize={rebuildTooltip}>
            {({ height, width }) => (
              <VariableSizeList
                height={height}
                itemCount={rows.length}
                itemData={itemData}
                // Hardcoded values only :/
                itemSize={(i) => (rows[i] && rows[i].isExpanded ? 250 : 80)}
                width={width}
                onScroll={rebuildTooltip}
                ref={listRef}
              >
                {RenderRow}
              </VariableSizeList>
            )}
          </AutoSizer>
        </div>
      </div>
    </Styles>
  );
}