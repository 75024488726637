import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui'
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { Spinner } from 'reactstrap';
import { useStyles, draftButtons } from './campaign/Campain.css';
import { ActivityButton } from './ActivityButton';
import { LegalAcceptance } from '../components/LegalAcceptance';
import { ReservationComponent } from '../components/advanced-planning/ReservationComponent';
import { ObjectiveChoice } from '../components/advanced-planning/ObjectiveChoice';

import _ from 'lodash';
import moment from 'moment';

const NoProfileDraft = () => {
  const dispatch = useDispatch()

  return (
    <div style={{ width: 500 }} className="d-sm-flex flex-column justify-content-center align-items-center bg-white h-25 rounded">
      <p>Prima di continuare, inserire i dati del profilo</p>
      <div className="d-sm-flex flex-row justify-content-center align-items-center mt-3" >
        <a style={draftButtons} href="/profile" onClick={() => dispatch(ContentActions.didSetOrderDate(moment().format('YYYY-MM-DD')))}>Vai al Profilo</a>
      </div>
    </div>
  )
}

const EndOfTimeDraft = () => {
  const dispatch = useDispatch()

  return (
    <div style={{ width: 500 }} className="d-sm-flex flex-column justify-content-center align-items-center bg-white h-25 rounded">
      <p>Tempo per la creazione della campagna esaurito.</p>
      <div className="d-sm-flex flex-row justify-content-center align-items-center mt-3" >
        <a style={draftButtons} href="/planning" onClick={() => dispatch(ContentActions.didSetOrderDate(moment().format('YYYY-MM-DD')))}>Torna alla Pianificazione</a>
      </div>
    </div>
  )
}

export const ConfirmDraft = (props: any) => {
  const { handleStepsArray, budgetAndTarget, campaignName } = props
  const dispatch = useDispatch()

  let order_date = useSelector(ContentSelectors.getOrderDate)
  const reminingTime = moment(order_date).to(moment(order_date).add(30, 'minutes'))
  let activityRunning = useSelector(UiSelectors.getActivitiesRunningGeneric)

  let is_confirm = useSelector(ContentSelectors.getIfIsConfirm)
  let filters = useSelector(ContentSelectors.didGetFilter)
  let discount = useSelector(ContentSelectors.getDiscount)
  let total_price = useSelector(ContentSelectors.getPrezzoTotale)
  let fee = total_price * 10 / 100
  let totalCost = total_price + 0 + fee + discount
  let plantsSelected = useSelector(ContentSelectors.getSelectedPlants)
  let pieChartStats = [] as any
  let ownersArray = [] as any
  let obj = {} as any
  let obj2 = {} as any
  _.forEach(plantsSelected, (value: any) => {
    if (obj[value.municipality]) {
      obj[value.municipality]++
    } else {
      obj[value.municipality] = 1
    }
    if (obj2[value.media_owner]) {
      obj2[value.media_owner]++
    } else {
      obj2[value.media_owner] = 1
    }
  })
  _.forOwn(obj, (value: any, key: any) => {
    // console.log("value", value, _.keys(obj))
    pieChartStats.push({ municipality: key, plants: value })
    console.log("value pieChartStats", pieChartStats)

  })
  _.forOwn(obj2, (value: any, key: any) => {
    ownersArray.push({ media_owner: key, plants: value })
  })

  useEffect(() => {

    if (is_confirm) {
      dispatch(ContentActions.willFilterPricingIds({ filters: {}, selectedPlants: {}, uscite: 0, n_uscite: filters.period.uscite.length, media_owners: _.map(ownersArray, 'media_owner').length, n_municipality: _.size(pieChartStats) }))

      // dispatch(ContentActions.willCreateCampaign({ campaignID: "", filters: {}, uscite: 0, n_uscite: filters.period.uscite.length, sconto: {}, orders: [], discount: discount, campaignData: {}, selectedPlants: [], total_amount: totalCost, period: {}, media_owners: _.map(ownersArray, 'media_owner').length, n_municipality: _.size(pieChartStats), fee: fee }))
      dispatch(UiActions.switchDraftCampainModal(''))
      dispatch(UiActions.didSetCampaignStep("Conferma"))
      handleStepsArray("Conferma")
    }
    console.log("activityRunning:", activityRunning)

  }, [is_confirm, activityRunning])

  const confirmButtonAction = (stampaConsegna: Boolean) => {
    // console.log("confirmButtonAction stampaConsegna", stampaConsegna)
    dispatch(UiActions.setActivityRunning(true))
    dispatch(ContentActions.willFilterBillboards({ filters: {}, is_confirm: true }))
    dispatch(ContentActions.didSetStampaConsegna(stampaConsegna))

  }

  return (
    <LegalAcceptance confirmButtonAction={confirmButtonAction} />
  )
}

const DiscardDraft = (props: any) => {
  const { setPlanningType, setAnchor } = props
  const dispatch = useDispatch()
  return (
    <div style={{ width: 500 }} className="d-sm-flex flex-column justify-content-center align-items-center bg-white h-25 rounded">
      <p>Per iniziare imposta una <a data-cy="selectLocality" className="hoverPointer" href="javascript:;" onClick={() => {
        // console.log("in DiscardDraft component")
        dispatch(UiActions.switchDraftCampainModal(''))
        setAnchor(document.getElementById("cityGrid"))
        setPlanningType("Municipality")
      }}>Località</a> e il <a data-cy="selectPeriod" className="hoverPointer" href="javascript:;" onClick={() => {
        // console.log("in DiscardDraft component")
        dispatch(UiActions.switchDraftCampainModal(''))
        setAnchor(document.getElementById("periodGrid"))
        setPlanningType("Period")
      }}>Periodo</a></p>
    </div>
  )
}

const RequiredModal = (props: any) => {
  const { setPlanningType, setAnchor } = props
  const dispatch = useDispatch()
  const [required, setRequired] = useState("")

  let filters = useSelector(ContentSelectors.didGetFilter)
  let campaignData = useSelector(ContentSelectors.getCampaignData)

  useEffect(() => {
    if (!filters.period) {
      setRequired("Periodo")
    }
    if (!filters.city) {
      setRequired("Località")
    }
    /// la cond
    // if()
  }, [filters])

  return (
    <div style={{ width: 500 }} className="d-sm-flex flex-column justify-content-center align-items-center bg-white h-25 rounded">
      <p><a data-cy="selectRequired" className="hoverPointer" href="javascript:;" onClick={() => {
        console.log("in DiscardDraft component")
        dispatch(UiActions.switchDraftCampainModal(''))
        if (required === "Località") {
          setAnchor(document.getElementById("locationGrid"))
          setPlanningType("Municipality")
        }
        if (required === "Periodo") {
          setAnchor(document.getElementById("periodGrid"))
          setPlanningType("Period")
        }
      }}>{required}</a> {required === "Località" ? "richiesta" : "richiesto"} per continuare.</p>
    </div>
  )
}

const RequiredSecondStepModal = (props: any) => {
  const { budgetAndTarget, campaignName } = props
  const dispatch = useDispatch()
  const [required, setRequired] = useState("")

  let selectedBillboards = useSelector(ContentSelectors.getSelectedBillboards)

  useEffect(() => {
    if (_.isEmpty(budgetAndTarget)) {
      setRequired("Budget e Obiettivo richiesti")
    }
    if (campaignName === "") {
      setRequired("Nome Campagna richiesto")
    }
    if (_.isEmpty(selectedBillboards)) {
      setRequired("Selezionare almeno un impianto")
    }
  }, [budgetAndTarget, campaignName])

  return (
    <div style={{ width: 500 }} className="d-sm-flex flex-column justify-content-center align-items-center bg-white h-25 rounded">
      <p>{required} per continuare.</p>
      <button data-cy="backToCampaignButton" style={draftButtons} onClick={() => dispatch(UiActions.switchDraftCampainModal(''))}>Torna alla Campagna</button>
    </div>
  )
}

export const DraftCampainStart = (props: any) => {
  const { setPlanningType, setAnchor } = props
  const dispatch = useDispatch()
  let choice = useSelector(UiSelectors.getDraftChoice)
  const [draftChoice, setDraftChoice] = useState(choice)

  return (
    <>
      {draftChoice === "" &&
        <div style={{ width: 500 }} className="d-sm-flex flex-column justify-content-center align-items-center bg-white h-25" >
          <span>Esiste un draft in questo device, vuoi recuperarlo o scartarlo?</span>
          <div className="d-sm-flex flex-row justify-content-center align-items-center mt-3" >
            <button data-cy="recoverButton" style={draftButtons} onClick={() => {
              //setDraftChoise("recover");
              dispatch(ContentActions.didSyncDraftFilter());
              dispatch(UiActions.switchDraftCampainModal(''))
              dispatch(ContentActions.willFilterBillboards({ filters: {}, is_confirm: false }))
            }} >Recupera Draft</button>
            <button style={draftButtons} className="ml-3" onClick={() => {
              setDraftChoice("discard");
              dispatch(ContentActions.didDiscardDraftFilter());
            }} >Scarta Draft</button>
          </div>
        </div>
      }
      {draftChoice === "discard" &&
        <DiscardDraft
          setPlanningType={setPlanningType}
          setAnchor={setAnchor}
        />}
    </>
  )
}

export const DraftCampainModal = (props: any) => {
  const { setPlanningType, setAnchor, modalType, budgetAndTarget, campaignName, handleStepsArray, selectedBillboard } = props
  const dispatch = useDispatch()

  let filters = useSelector(ContentSelectors.didGetFilter)

  // useEffect(() => {
  //   console.log("modaltype selected", modalType)
  // }, [modalType])

  //console.log('choiceeeee', choice, draftChoice)
  // console.log("modalType", modalType)
  return (
    <div className="d-flex justify-content-center align-items-center" >
      {modalType === "required" && <RequiredModal setPlanningType={setPlanningType} setAnchor={setAnchor} />}
      {_.isEmpty(filters) && modalType === "none" && <DraftCampainStart setPlanningType={setPlanningType} setAnchor={setAnchor} />}
      {modalType === "requiredSecondStep" && <RequiredSecondStepModal budgetAndTarget={budgetAndTarget} campaignName={campaignName} />}
      {modalType === "confirm" && <ConfirmDraft budgetAndTarget={budgetAndTarget} campaignName={campaignName} handleStepsArray={handleStepsArray} />}
      {modalType === "endOfTime" && <EndOfTimeDraft />}
      {modalType === "noProfile" && <NoProfileDraft />}
      {modalType === 'reservation' && <ReservationComponent selectedBillboard={selectedBillboard} />}
      {/* {modalType === 'objectiveChoice' && <ObjectiveChoice handleStepsArray={handleStepsArray} />} */}
    </div>
  )
}
