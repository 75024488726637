import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../../../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../../../store/slices/ui';
import { Grid, Modal, Tooltip, Zoom } from '@material-ui/core';
import { useStyles, randomColor } from '../Campain.css';
import { ConfirmDraft, DraftCampainModal } from '../../DraftCampainModal';
import { ListSelectedPlants } from './ListSelectedPlants';
import { ListMediaOwners } from './ListMediaOwners';
import { Summary } from './Summary';
import { TypeFormat } from './TypeFormat';
import { VisibilityIndex } from './VisibilityIndex';
import { customBillboardIcon } from '../../CustomBillboardIcon';
import "react-circular-progressbar/dist/styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment'
import _ from 'lodash';

const numFormatter = (num: any) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
}

export const CampainConfirmStep = (props: any) => {
  const { setData } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const [billboardsNumber, setBillboardsNumber] = useState()
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [modalType, setModalType] = useState("")

  let plantsSelected = useSelector(ContentSelectors.getSelectedPlants)
  let order_date = useSelector(ContentSelectors.getOrderDate)
  const switchModalOpen = useSelector(UiSelectors.switchDraftCampainModal)

  let remainingTime = {} as any

  if (order_date) {
    remainingTime = moment(order_date).diff(moment().add(30, 'minutes'))
  } else {
    remainingTime = moment()
  }

  useEffect(() => {
    if (order_date) {
      if (moment() >= moment(order_date).add(30, 'minutes')) {
        console.log("time is over")
        setModalType("endOfTime")
        dispatch(UiActions.switchDraftCampainModal(''))
      } else {
        console.log("remaining time:", moment(remainingTime).format('mm:ss'))
      }
    } else {
      dispatch(ContentActions.didSetOrderDate())
    }
  }, [])

  let pieChartStats = [] as any[]
  let ownersArray = [] as any[]


  let obj = {} as any
  let obj2 = {} as any
  _.forEach(plantsSelected, (value: any) => {
    if (obj[value.municipality]) {
      obj[value.municipality]++
    } else {
      obj[value.municipality] = 1
    }
    if (obj2[value.media_owner]) {
      obj2[value.media_owner]++
    } else {
      obj2[value.media_owner] = 1
    }
  })
  _.forOwn(obj, (value: any, key: any) => {
    // console.log("value", value, _.keys(obj))
    pieChartStats.push({ municipality: key, plants: value, color: randomColor() })
    // console.log("value pieChartStats", pieChartStats)

  })
  _.forOwn(obj2, (value: any, key: any) => {
    ownersArray.push({ media_owner: key, plants: value })
  })


  return (
    <>
      <Modal open={switchModalOpen} onClose={() => dispatch(UiActions.switchDraftCampainModal(''))}>
        <DraftCampainModal setAnchor={setAnchor} modalType={modalType} />
      </Modal>

      <Grid container className="poppinsFont" style={{ backgroundColor: "#fafafb", minWidth: 1200, overflowX: 'auto' }} justify="center">
        <Grid container item xs={10} className="mb-3">

          <Grid container item xs={8}>

            <Grid container item xs={12}>
              <Grid className={classes.confirmStepBorders} item xs={6}>
                <Grid style={{ fontSize: 20 }} className="p-3" item xs={12}><b>Impianti selezionati: {`(${billboardsNumber})`}</b></Grid>
                <ListSelectedPlants setBillboardsNumber={setBillboardsNumber} selectedPlants={plantsSelected} />
              </Grid>
              <Grid container item xs={6} alignItems="center" alignContent="flex-start" justify="center">
                <TypeFormat />
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid style={{ height: 300 }} /* className={`${classes.confirmStepBorders}`} */ item xs={6}>
                <VisibilityIndex />
              </Grid>

              <Grid container item xs={6} justify="center">
                <Grid style={{ height: 300 }} className={`${classes.confirmStepBorders} overflow-auto`} container direction="column">
                  <Grid style={{ fontSize: 20 }} className="p-3">
                    <b>{_.size(pieChartStats) > 1 ? "Comuni coinvolti" : "Comune coinvolto"} {`(${_.size(pieChartStats)})`}</b>
                  </Grid>
                  {pieChartStats.map((stat: any, index: number) => {
                    return (
                      <Grid key={index} className="mt-2" container direction="row" alignItems="center" justify="center" >
                        <Grid container item xs={2} alignItems="center" justify="center">
                          <div style={{ backgroundColor: stat.color, width: 17, height: 12, borderRadius: 6 }} />
                        </Grid>
                        <Grid item xs={7}>{stat.municipality}</Grid>
                        <Grid container item xs={3} alignItems="center" justify="center">{numFormatter(stat.plants)}</Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>

          </Grid>

          <Grid container item xs={4}>
            <Grid className={`${classes.confirmStepBordersBlue} p-3`} item xs={12}>
              <Summary setData={setData} pieChartStats={pieChartStats} ownersArray={ownersArray} />
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={4} />
            <Grid className={`${classes.confirmStepBorders} mb-3`} container item xs={8}>
              <Grid style={{ fontSize: 20 }} className="p-4" item xs={12}><b>Concessionarie {`(${_.size(ownersArray)})`}</b></Grid>
              <Grid className="p-4" item xs={12}>
                <ListMediaOwners ownersArray={ownersArray} />
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </>
  )

}