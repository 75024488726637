import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Grid, FormControlLabel, Checkbox, TextField, InputAdornment } from '@material-ui/core';
import { useStyles, confirmButton } from '../campaign/Campain.css';

import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../../store/slices/ui';
import Icon from '../IconsComponent';

interface ObjectiveOBJ {
  objective: string;
  description: string;
}

const objectiveList: ObjectiveOBJ[] = [
  {
    objective: 'Notorietà',
    description: 'Raggiungi quante più persone del tuo target con gli spazi più visibili. Selezioneremo gli impianti a più alta visibilità ed esposti al pubblico scelto.'
  },
  {
    objective: 'Interazioni',
    description: 'Incentiva il tuo pubblico a compiere azioni online e/o offline. Selezioneremo impianti di grande impatto ed in luoghi ad alto passaggio pedonale.'
  }
]

const ObjectiveContainer = ({ objective, description, handleStepsArray, setObjectiveModalOpen }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [budget, setBudget] = useState(false)

  let campaignData = useSelector(ContentSelectors.getCampaignData)
  let myBillboardsInMap = useSelector(ContentSelectors.listBillboards)
  const switchModalOpen = useSelector(UiSelectors.switchDraftCampainModal)

  return (
    <>
      {budget
        ? <Grid className={`${classes.confirmStepBordersBlue} p-3`} item xs={4} >
          <Grid container item xs={12} alignItems="center">
            <Grid container alignItems="center" justify="space-between" item xs={8}><h4>{objective}</h4></Grid>
            <Grid item xs={4}> <Icon className="right" color="white" size={24} icon="chevronRight" /></Grid>
          </Grid>
          <Grid style={{ color: '#B5B5BE', fontSize: 14 }} item xs={12}>{description}</Grid>
          <Grid className='my-3' item xs={12}>
            <TextField
              className={classes.campaignInput}
              id="budget"
              data-cy="budget"
              placeholder="Inserisci budget"
              variant="outlined"
              size='small'
              value={campaignData.budgetAndTarget.budget}
              fullWidth
              onChange={(e: any) => dispatch(ContentActions.didSetCampaignData({ campaignName: campaignData.campaignName, budgetAndTarget: { target: campaignData.budgetAndTarget.target, budget: e.target.value } }))}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Icon size={20} icon="edit" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid className="d-sm-flex justify-content-center" item xs={12}>
            <button disabled={campaignData.budgetAndTarget.budget ? false : true} className="border-0 bg-transparent" style={{ height: 38, color: 'white' }} onClick={() => {
              const totalCost = parseInt(campaignData.budgetAndTarget.budget)
              const tenPercent = totalCost * 10 / 100
              dispatch(ContentActions.didSortByObjective({ n_uscite: 1, billboards: myBillboardsInMap, budgetAndTarget: { target: objective, budget: (totalCost + tenPercent) } }))

              dispatch(UiActions.didSetCampaignStep("Pianifica campagna"))
              handleStepsArray("Pianifica campagna")
              setObjectiveModalOpen(false)
              switchModalOpen && dispatch(UiActions.switchDraftCampainModal(''))
            }}>Conferma</button>
          </Grid>
        </Grid>
        : <Grid style={{ cursor: 'pointer' }} className={classes.objectiveContainer} item xs={4} onClick={() => {
          setBudget(true)
          dispatch(ContentActions.didSetCampaignData({ campaignName: campaignData.campaignName, budgetAndTarget: { target: objective, budget: campaignData.budgetAndTarget.budget } }));
        }} >
          <Grid container item xs={12} >
            <Grid item xs={8}><h4>{objective}</h4></Grid>
            <Grid item xs={4}> <Icon className="right" color="#0062FF" size={24} icon="chevronRight" /></Grid>
          </Grid>
          <Grid style={{ color: '#B5B5BE', fontSize: 14 }} item xs={12}>{description}</Grid>
        </Grid>}
    </>
  )
}


export const ObjectiveChoice = ({ handleStepsArray, setObjectiveModalOpen }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  
  const switchModalOpen = useSelector(UiSelectors.switchDraftCampainModal)

  return (
    <div style={{ width: 900, height: 440, borderRadius: 20 }} className="d-sm-flex flex-column bg-white poppinsFont">
      <Grid className={classes.draftModalHeader} container item xs={12}>
        <Grid item xs={11}>Pianifica campagna</Grid>
        <Grid item xs={1}>
          <button
            data-cy="goBack"
            className="border-0 bg-transparent"
            onClick={() => { setObjectiveModalOpen(false) }}
          >
            <Icon size={25} icon="close" />
          </button>
        </Grid>
      </Grid>
      <Grid className="p-4" container item xs={12} >
        <Grid item xs={8}>
          <b>Procedi alla selezione manuale degli impianti oppure scegli un obiettivo</b>
        </Grid>
        <Grid item xs={4}>
          <button style={{ ...confirmButton, height: 38 }} onClick={() => {
            dispatch(ContentActions.willFilterBillboards({ filters: {}, is_confirm: false }))
            dispatch(UiActions.didSetCampaignStep("Pianifica campagna"))
            handleStepsArray("Pianifica campagna")
            setObjectiveModalOpen(false)
            switchModalOpen && dispatch(UiActions.switchDraftCampainModal(''))
          }}>Aggiungi manualmente</button>
        </Grid>
      </Grid>
      <Grid style={{ backgroundColor: '#FAFAFB', borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }} className="p-4" container item xs={12} justify="space-between">
        {objectiveList.map((objective: any) => {
          return <ObjectiveContainer
            objective={objective.objective}
            description={objective.description}
            handleStepsArray={handleStepsArray}
            setObjectiveModalOpen={setObjectiveModalOpen}
          />
        })}
        <Grid item xs={3} />
      </Grid>
    </div>
  )
}