import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Profile {
  publicKey: string
}

export const currentSlice = createSlice({
  name: 'profile',
  initialState: {
    publicKey: "",
    attributes: {},
    advertiserData: {} as any
  },
  reducers: {
    addPublicKey: (state, action: PayloadAction<any>) => state.publicKey = action.payload,
    willRetrieveProfileData: (state, action: PayloadAction<any>) => state,
    didRetrieveProfileData: (state, action: PayloadAction<any>) => void (state.attributes = action.payload),
    willAddAdvertiser: (state, action: PayloadAction<any>) => {
      // console.log("in willAddAdvertiser")
    },
    didAddAdvertiser: (state, action: PayloadAction<any>) => {
      // console.log("in didAddAdvertiser")
      state.advertiserData = action.payload
    },
    willGetAdvertiser: (state, action: PayloadAction<any>) => {
      console.log("in willGetAdvertiser")
    },
  }
})

export const { actions, reducer } = currentSlice
export const {
  addPublicKey,
  willRetrieveProfileData,
  didRetrieveProfileData,
  willAddAdvertiser,
  didAddAdvertiser,
  willGetAdvertiser

} = actions
export const selectors = {
  getPublicKey: (state: any) => state.profile.publicKey,
  getProfile: (state: any) => state.profile.attributes,
  getAdvertiser: (state: any) => state.profile.advertiserData
}
