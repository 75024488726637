import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { useStyles, cancelButtonFilters, confirmButtonFilters } from '../campaign/Campain.css';
import { Grid, Chip, FormControlLabel, Checkbox } from '@material-ui/core';
import Icon from '../IconsComponent';
import _ from 'lodash';
import { billboardClassification, billboardsTypesFacade } from '../Autocomplete.options';
import { customIcon } from '../CustomMarker';

const openedClassFunction = (selectedClass: string, array: string[], setOpendedClass: any) => {
  let newArray: string[] = [...array]
  if (newArray.includes(selectedClass)) {
    newArray = newArray.filter((includedClasses: string) => { return includedClasses !== selectedClass })
  } else {
    newArray.push(selectedClass)
  }
  setOpendedClass(newArray)
}

const updateNewObj = (typology: string, setData: any, classification: string, value: any) => {
  let newObj = { ...value }

  if (newObj[classification]) {
    if (newObj[classification].includes(typology)) {
      newObj[classification] = newObj[classification].filter((o: string) => { return o !== typology })
    } else {
      newObj[classification].push(typology)
    }
  } else {
    newObj[classification] = [typology]
  }

  if (newObj[classification].length === 0) {
    delete newObj[classification]
  }

  setData(newObj)
}

const checkboxSize = 20
const EmptyCheck = () => {
  return (
    <div data-cy="emptyCheck" style={{ width: checkboxSize, height: checkboxSize, borderWidth: 1, borderStyle: "solid", borderColor: "#92929D", borderRadius: 5 }} />
  )
}
const FilledCheck = () => {
  return (
    <div style={{ backgroundColor: "#3DD598", width: checkboxSize, height: checkboxSize, borderWidth: 1, borderStyle: "solid", borderColor: "#00e68a", borderRadius: 5, position: "relative" }} >
      <div className="d-sm-flex align-items-center">
        <Icon size={checkboxSize} color="white" icon="check" />
      </div>
    </div>
  )
}

const CustomCheckBox = ({ value, type, setData, classification }) => {

  let newObj = { ...value }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={newObj[classification] && newObj[classification].includes(type) ? true : false}
          onClick={() => updateNewObj(type, setData, classification, value)}
          icon={<EmptyCheck />}
          checkedIcon={<FilledCheck />}
          name={type} />} label=""
    />
  )
}

const setAllFacades = (classType: string, setData: any, data: any) => {
  let newData = { ...data }

  if (newData[classType]) {
    if (newData[classType].length !== billboardsTypesFacade[classType].length) {
      newData[classType] = billboardsTypesFacade[classType].map((facade: any) => facade.facade)
    } else {
      delete newData[classType]
    }
  } else {
    newData[classType] = billboardsTypesFacade[classType].map((facade: any) => facade.facade)
  }
  setData(newData)
}

export const ClassAndTypology = (props: any) => {
  const { setAnchor, setPlanningType } = props

  const classes = useStyles();
  const dispatch = useDispatch()

  const [data, setData] = useState({} as any)
  const [allData, setAllData] = useState({} as any)
  const [openedClass, setOpenedClass] = useState([]) as any[]

  const filters = useSelector(ContentSelectors.didGetFilter)

  useEffect(() => {
    if (filters.media_type && filters.media_type.length !== 0) {
      // console.log("prima di farlo", (filters.media_type))
      // let obj = { } as any

      const mediaTypeList: string[] = [...filters.media_type]
      const mediaBillboardClass: string[] = [...filters.billboard_class]

      mediaBillboardClass.map((selectedClass: string) => {
        // setAllFacades(selectedClass, setAllData, allData)
        openedClassFunction(selectedClass, openedClass, setOpenedClass)
        mediaTypeList.map((type: string) => {
          updateNewObj(type, setAllData, selectedClass, allData)
        })
      })

      // mediaBillboardClass.map((selectedClass: any) => {
      //   mediaTypeList.map((type: string) => {
      //     if (billboardsTypesFacade[selectedClass].includes(type)) {
      //       if (obj[selectedClass]) {
      //         obj[selectedClass].push(type)
      //       } else {
      //         obj[selectedClass] = [type]
      //       }
      //     }

      //   })
      // })
      // setAllData(obj)
      // console.log("obj appena fatto", obj)
      // setOpenedClass(filters.billboard_class)
    }
  }, [])

  const applyFunction = () => {
    let arrayOfTypes: string[] = []
    openedClass.map((selectedClass: any) => {
      if (allData[selectedClass]) {
        arrayOfTypes.push(...allData[selectedClass])
      }
    })

    dispatch(ContentActions.willUploadFilter({ key: "media_type", value: { media_type: arrayOfTypes, billboard_class: openedClass } }))
    dispatch(ContentActions.willFilterBillboards({ filters: {} }))
    setAnchor(null)
    setPlanningType("")
    console.log("ClassAndTypology applyFunction", arrayOfTypes)
  }

  return (
    <div className={`${classes.root} poppinsFont`}>
      <Grid container spacing={3}>
        <Grid item xs={12}><b>CLASSE</b></Grid>
        <Grid className={classes.filterBody} item xs={12}>

          {billboardClassification.map((classification: any, index: number) => {
            return (
              <Grid key={index} container alignItems="center" >
                <Grid style={{ cursor: 'pointer' }} container alignItems="center" item xs={3} onClick={() => openedClassFunction(classification.classification, openedClass, setOpenedClass)}>{classification.classification}</Grid>
                <Grid style={{ cursor: 'pointer' }} container alignItems="center" justify="center" item xs={2} onClick={() => openedClassFunction(classification.classification, openedClass, setOpenedClass)}>
                  <Icon
                    className={openedClass.includes(classification.classification) ? "" : "right"}
                    icon={openedClass.includes(classification.classification) ? 'chevronDown' : 'chevronRight'} />
                </Grid>
                <Grid container alignItems="center" item xs={6} />
                <Grid container alignItems="center" item xs={1}>
                  <FormControlLabel
                    label=""
                    control={
                      <Checkbox
                        checked={allData[classification.classification] ? true : false}
                        onClick={() => {
                          setAllFacades(classification.classification, setAllData, allData)
                          openedClassFunction(classification.classification, openedClass, setOpenedClass)
                        }}
                        icon={<EmptyCheck />}
                        checkedIcon={<FilledCheck />}
                        name={classification.classification} />}
                  />
                </Grid>
                {openedClass.includes(classification.classification) &&
                  billboardsTypesFacade[classification.classification].map((facade: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <Grid item xs={3}></Grid>
                        <Grid container alignItems="center" item xs={2}>{customIcon(classification.classification, facade.facade, 40)}</Grid>
                        <Grid container alignItems="center" style={{ fontSize: 11 }} item xs={6}>{facade.facade}</Grid>
                        <Grid container alignItems="center" item xs={1}><CustomCheckBox value={allData} type={facade.facade} setData={setAllData} classification={classification.classification} /></Grid>
                      </React.Fragment>
                    )
                  })}
              </Grid>
            )
          })}
        </Grid>
        <Grid className={classes.buttonsFilterContainer} container>
          <Grid container item xs={6} alignItems="center" justify="center">
            <button
              style={cancelButtonFilters}
              data-cy="goBack"
              onClick={() => { setAnchor(null); setPlanningType("") }}
            >Annulla</button>
          </Grid>
          <Grid container item xs={6} alignItems="center" justify="center">
            <button data-cy="apply" style={confirmButtonFilters} onClick={applyFunction}>Applica</button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}