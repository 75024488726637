import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Grid, TextField, Popover, InputAdornment, Chip, Checkbox, Modal } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStyles, buttonApply, simpleObjectiveButton, imageSimpleObjective, goToAdvancedPlannyng, advancedPlanningButton, matchButton, matchSelectedButton } from '../campaign/Campain.css';
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../../store/slices/ui';
import { actions as NotificationActions } from '../../store/slices/notification';
import Icon from '../IconsComponent';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, } from '@material-ui/pickers';
import Autosuggest, { BlurEvent, ChangeEvent } from 'react-autosuggest';
import { useSuggestion } from "../Suggestion.hooks";
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { Period } from '../filters/Period';
import { Map } from '../Map';
import { ConfirmDraft, DraftCampainModal } from '../DraftCampainModal';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export const SecondStep = (props: any) => {
  const {  budget, handleStepsArray } = props
  console.log("SecondStep budget", budget)
  
  const classes = useStyles()
  const dispatch = useDispatch()

  let campaignData = useSelector(ContentSelectors.getCampaignData)
  let filters = useSelector(ContentSelectors.didGetFilter)
  const switchModalOpen = useSelector(UiSelectors.switchDraftCampainModal)
  let myBillboardsInMap = useSelector(ContentSelectors.listBillboards)

  const [selected, setSelected] = useState('')
  const [modalType, setModalType] = useState("")
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if(switchModalOpen){
      dispatch(UiActions.switchDraftCampainModal(''))
    }
    
  },[])

  return (
    <Grid className="mt-5" container item xs={12} alignItems="center" justify="space-around">
      <Modal open={switchModalOpen} onClose={() => dispatch(UiActions.switchDraftCampainModal(''))}>
        <DraftCampainModal setAnchor={setAnchor} modalType={modalType} handleStepsArray={handleStepsArray} />
      </Modal>
      <Grid className={classes.guidedPlanningSecondStepCard} container item xs={3} alignItems="center" justify="space-around">
        <h5>Entry Level</h5>
        <Grid item xs={11}>
          <p style={{ opacity: 0.5 }} >Rimani dentro il tuo budget, soddisfando i requisiti minimi della tua campagna.</p>
          <p style={{ opacity: 0.0, fontSize: 12 }}>invisibile</p>
        </Grid>
        <Grid container item xs={12} alignItems="center" justify="space-around">
          <Grid className={classes.fillStep} item xs={2} />
          <Grid className={classes.notFillStep} item xs={2} />
          <Grid className={classes.notFillStep} item xs={2} />
          <Grid className={classes.notFillStep} item xs={2} />
        </Grid>
        <Grid item xs={6}>
          <button data-cy="apply" className='w-100' style={selected === 'Entry Level' ? matchSelectedButton : matchButton} onClick={() => {
            setSelected('Entry Level')
            dispatch(ContentActions.didSortByObjective({ n_uscite: 1, billboards: myBillboardsInMap, budgetAndTarget: { target: campaignData.budgetAndTarget.target, budget: budget } }))
          }}>Seleziona</button>
        </Grid>
      </Grid>

      <Grid className={classes.guidedPlanningSecondStepCard} container item xs={3} alignItems="center" justify="space-around">
        <h5>Perfect match</h5>
        <Grid item xs={11}>
          <p style={{ opacity: 0.5 }} >Ottimizza la tua campagna per raggiungere l’obiettivo.</p>
          <p style={{ opacity: 0.5, fontSize: 12 }}>NB: il budget potrebbe essere superato fino al 10%.</p>
        </Grid>
        <Grid container item xs={12} alignItems="center" justify="space-around">
          <Grid className={classes.fillStep} item xs={2} />
          <Grid className={classes.fillStep} item xs={2} />
          <Grid className={classes.notFillStep} item xs={2} />
          <Grid className={classes.notFillStep} item xs={2} />
        </Grid>
        <Grid item xs={6}>
          <button data-cy="apply" className='w-100' style={selected === 'Perfect match' ? matchSelectedButton : matchButton} onClick={() => {
            setSelected('Perfect match')
            const totalCost = parseInt(budget)
            const tenPercent = totalCost * 10 / 100
            // console.log("tenPercent", totalCost, tenPercent, (totalCost + tenPercent))
            dispatch(ContentActions.didSortByObjective({ n_uscite: 1, billboards: myBillboardsInMap, budgetAndTarget: { target: campaignData.budgetAndTarget.target, budget: (totalCost + tenPercent) } }))
          }}>Seleziona</button>
        </Grid>
      </Grid>

      <Grid className={classes.guidedPlanningSecondStepCard} container item xs={3} alignItems="center" justify="space-around">
        <h5>Domination</h5>
        <Grid item xs={11}>
          <p style={{ opacity: 0.5 }} >Domina le località selezionate con una presenza massiva.</p>
          <p style={{ opacity: 0.5, fontSize: 12 }}>NB: il budget potrebbe essere superato fino al 20%.</p>
        </Grid>
        <Grid container item xs={12} alignItems="center" justify="space-around">
          <Grid className={classes.fillStep} item xs={2} />
          <Grid className={classes.fillStep} item xs={2} />
          <Grid className={classes.fillStep} item xs={2} />
          <Grid className={classes.fillStep} item xs={2} />
        </Grid>
        <Grid item xs={6}>
          <button data-cy="apply" className='w-100' style={selected === 'Domination' ? matchSelectedButton : matchButton} onClick={() => {
            setSelected('Domination')
            const totalCost = parseInt(budget)
            const twentyPercent = totalCost * 20 / 100
            // console.log("tenPercent", totalCost, twentyPercent, (totalCost + twentyPercent))
            dispatch(ContentActions.didSortByObjective({ n_uscite: 1, billboards: myBillboardsInMap, budgetAndTarget: { target: campaignData.budgetAndTarget.target, budget: (totalCost + twentyPercent) } }))
          }}>Seleziona</button>
        </Grid>
      </Grid>

      <Grid container alignItems="center" justify="center">
        {selected
          ? <h3 className='mt-7'>Tipologia selezionata: {selected}</h3>
          : <h3 className='mt-7'>Seleziona la tipologia di campagna</h3>}
      </Grid>

      {selected &&
        <Grid className='mt-4' item xs={4}>
          <button data-cy="apply" className='w-100' style={{...buttonApply, fontSize:25}} onClick={() => {
            setModalType("confirm")
            dispatch(UiActions.switchDraftCampainModal(''))
          }}>Crea campagna</button>
        </Grid>}

    </Grid>
  )
}