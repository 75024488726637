import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../../store/slices/ui';
import { useStyles, cancelButton, confirmButton, cancelButtonCampaign, confirmButtonCampaign } from '../campaign/Campain.css';
import { Grid, InputAdornment, TextField, Chip, Popover, Modal } from '@material-ui/core';
import { PlantDetailOverview } from '../PlantDetailsOverview';
import { ConfirmDraft, DraftCampainModal } from '../DraftCampainModal';
import { CampaignSecondStepBillboardsList } from './CampaignSecondStepBillboardsList';
import Icon from '../IconsComponent';
import moment from 'moment';
import _ from 'lodash';

const currencyFormatter = (value: number) => {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
}

export const CampainSecondStep = (props: any) => {
  const { handleStepsArray } = props
  const classes = useStyles();
  const dispatch = useDispatch()

  let campaignData = useSelector(ContentSelectors.getCampaignData)
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [planningType, setPlanningType] = useState("")
  // const [campaignName, setCampaignName] = useState(campaignData.campaignName)
  // const [budgetAndTarget, setBudgetAndTarget] = useState(campaignData.budgetAndTarget) as any
  const [modalType, setModalType] = useState("")
  const [selectedBillboard, setSelectedBillboard] = useState() as any

  let selectedBillboards = useSelector(ContentSelectors.getSelectedBillboards)
  const switchModalOpen = useSelector(UiSelectors.switchDraftCampainModal)
  let order_date = useSelector(ContentSelectors.getOrderDate)

  let remainingTime = {} as any

  if (order_date) {
    remainingTime = moment(order_date).diff(moment().add(30, 'minutes'))
  } else {
    remainingTime = moment()
  }

  const open = Boolean(anchor);
  const id = open ? 'simple-popover' : undefined;

  const required = () => {
    if (_.isEmpty(selectedBillboards) || !campaignData.campaignName) {
      setModalType("requiredSecondStep")
      dispatch(UiActions.switchDraftCampainModal(''))
    }
    else if (campaignData.campaignName === "") {
      setModalType("requiredSecondStep")
      dispatch(UiActions.switchDraftCampainModal(''))
    }
    else {
      setModalType("confirm")
      dispatch(UiActions.switchDraftCampainModal(''))
    }
  }

  useEffect(() => {
    // if (moment() >= moment(order_date).add(30, 'minutes')) {
    //   console.log("time is over")
    //   setModalType("endOfTime")
    //   dispatch(UiActions.switchDraftCampainModal(''))
    // } else {
    //   console.log("remaining time:", moment(remainingTime).format('mm:ss'))
    // }
    // setBudgetAndName({ budgetAndTarget, campaignName })
    // console.log("campaignData", campaignData)
  }, [/* budgetAndTarget, campaignName, */ selectedBillboards])

  return (
    <div className="d-sm-flex flex-column justify-content-center align-items-center position-relative h-100 overflow-hidden">
      <Modal open={switchModalOpen} onClose={() => { dispatch(UiActions.switchDraftCampainModal('')) }}>
        <DraftCampainModal modalType={modalType} budgetAndTarget={campaignData.budgetAndTarget} campaignName={campaignData.campaignName} handleStepsArray={handleStepsArray} selectedBillboard={selectedBillboard} />
      </Modal>
      <div style={{ backgroundColor: "#fafafb", height: '100%', width: '100%' }}>
        <Grid className="poppinsFont" container >
          <div className="mx-3 mt-3">
            {campaignData.budgetAndTarget.target &&
              <Chip
                className={classes.chipContainer}
                data-cy="targetChip"
                variant="outlined"
                label={`${campaignData.budgetAndTarget.target}`}
              />
            }
          </div>
          <div className="mx-3 mt-3">
            {campaignData.budgetAndTarget.budget &&
              <Chip
                className={classes.chipContainer}
                data-cy="budgetChip"
                variant="outlined"
                label={`${currencyFormatter(campaignData.budgetAndTarget.budget)}`}
              />
            }
          </div>
          <Grid>
            <Popover
              className={classes.popoverContainer}
              id={id}
              open={open}
              anchorEl={anchor}
              onClose={() => { setAnchor(null); setPlanningType(""); }}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }} >
              {/* {planningType === "Budget&Target" && <BudgetAndTarget setAnchor={setAnchor} setPlanningType={setPlanningType} setBudgetAndTarget={setBudgetAndTarget} />} */}
              {planningType === "PlantDetails" && <PlantDetailOverview setAnchor={setAnchor} setPlanningType={setPlanningType} />}
            </Popover>
          </Grid>
        </Grid>
        <CampaignSecondStepBillboardsList setModalType={setModalType} setSelectedBillboard={setSelectedBillboard} />
      </div>
      <Grid style={{ position: 'absolute', bottom: 30, right: 0, left: 0 }} className={`${classes.buttonBackground}`} container>
        <button data-cy="goBack" style={cancelButtonCampaign} onClick={() => {
          dispatch(UiActions.didSetCampaignStep("Seleziona impianti"))
          handleStepsArray("Seleziona impianti")
        }}>Torna indietro</button>
        <button data-cy="confirm" style={confirmButtonCampaign} onClick={required}>Conferma</button>
      </Grid>
    </div>
  )
}