import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import styled from "styled-components";
import moment from 'moment';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    cancelButton: {
      borderColor: "transparent",
      backgroundColor: "#66b0ff",
      color: "white",
      borderRadius: 10,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      "&:hover": {
        backgroundColor: "#66b0ff",
      }
    },
    confirmButton: {
      borderColor: "transparent",
      backgroundColor: "#007bff",
      color: "white",
      borderRadius: 10,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      "&:hover": {
        backgroundColor: "#007bff",
      }
    },
    borderColor: {
      '& label.Mui-focused': {
        color: '#00bfff',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#00bfff',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: 10
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00bfff',
        },
      },
    },
    noBorders: {
      border: "none",
    },
    noBordersAll: {
      // width: 180,
      // marginLeft: 30,
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: "#E6E6E6",
      borderRadius: 10,
      backgroundColor: "white",
    },
    rootMaintenance: {
      // width: 300,
      // height: "70vh",
      padding: theme.spacing(2),
      //backgroundColor: "#fafafb",
      overflow: "hidden"
    },
    csvButtonUpload: {
      backgroundColor: "#E6E6E6",
      color: "gray",
      border: "none",
      borderRadius: 14,
      height: 60,
      width: 150,
      "&:hover": {
        backgroundColor: "#E6E6E6",
      }
    },
    csvButtonUploaded: {
      backgroundColor: "hsl(80, 100%, 98%)",
      color: "gray",
      border: "none",
      borderRadius: 14,
      height: 60,
      width: 150,
      "&:hover": {
        backgroundColor: "hsl(80, 100%, 98%)",
      }
    },
    csvButtonDownload: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "hsl(200, 100%, 98%)",
      borderWidth: 0,
      borderRadius: 14,
      "&:hover": {
        backgroundColor: "hsl(200, 100%, 98%)",
      }
    },
    csvButtonIcon: {
      height: 60,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#e6f2ff",
      borderWidth: 0,
      borderRadius: 14,
    },
    csvButtonIconUploaded: {
      height: 60,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "hsl(80, 100%, 94%)",
      borderWidth: 0,
      borderRadius: 14,
    },
    uncontrolledNoOutline: {
      outline: "hidden"
    },
    csvUploaded: {
      // height:481
      height: 200
    },
    tooltip: {
      display:"flex",
      alignItems:"center",
      backgroundColor: "white",
      borderWidth: 1,
      borderColor: "#f1f1f5",
      borderStyle:"solid",
      borderRadius: 5,
      padding:15,
      fontSize: 15
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      display:"flex",
      alignItems: "center",
      position:"absolute"
    },
  }))

export const Styles = styled.div`
   padding: 0.5rem;
// margin-top: 5px;
// padding: 13px;

.table {
  
  width: 100%;

  .thead {
    padding-right: 15px;
    font-family: 'Poppins', sans-serif;
  }

  .tbody {
    height: 70vh;
  //  padding: 17px;
  }

  .tbodyMap {
    height: 72vh;
  }

  .tr-group {
    display: flex;
    flex-direction: column;
    //  overflow-x: hidden;
    // border: 1px solid #00e68a;
    // border-radius: 10px;
  }

  .tr {
    //  overflow: hidden;
    display: flex;
    :last-child {
      .td {
        border-bottom: 0;
        color: #6c757d;
      }
    }
  }

  .th,
  .td {
    margin: 0;
    // padding: 0.5rem;
    margin-left: 10px;
    color: #6c757d;

    :last-child {
      border-right: 0;
    }
  }
}
`;

export const cancelButton = {
  backgroundColor: "#E6E6E6",
  color: "#8C8C8C",
  border: "none",
  borderRadius: 10,
  height: 50,
  width: "100%"
}

export const confirmButton = {
  borderRadius: 10,
  backgroundColor: "#007bff",
  color: "white",
  border: "none",
  height: 50,
  width: "100%"
}

export const actionButton = {
  borderWidth: 2,
  borderStyle: "solid",
  borderColor: "#E6E6E6",
  borderRadius: 10,
  backgroundColor: "white",
}

export const csvButton = {
  display: "flex",
  alignItems: "center",
  fontSize: 12,
  marginRight: 10,
  padding: 10,
  backgroundColor: "#e6e6ff",
  color: "#0062cc",
  borderWidth: 0,
  borderRadius: 10,
  "&:hover": {
    backgroundColor: "#e6e6ff",
    color: "#0062cc",
  }
}

export const csvButtonDownload = {
  display: "flex",
  alignItems: "center",
  fontSize: 12,
  marginRight: 10,
  padding: 10,
  backgroundColor: "#e6e6ff",
  color: "#0062cc",
  borderWidth: 0,
  borderRadius: 10,
  "&:hover": {
    backgroundColor: "#e6e6ff",
    color: "#0062cc",
  }
}

export const csvButtonUpload = {
  backgroundColor: "#E6E6E6",
  color: "gray",
  border: "none",
  borderRadius: 10,
  height: 60,
  width: 150,
  "&:hover": {
    backgroundColor: "#E6E6E6",
  }
}

export const buttonOrder = {
  backgroundColor: "#007bff",
  width: 60,
  height: 17,
  fontSize: 10,
  color: "white",
  borderWidth: 0,
  borderRadius: 10,
  "&:hover": {
    backgroundColor: "#007bff"
  }
}

export const uploadImages = {
  backgroundColor: "transparent",
  width: 100,
  height: 100,
  marginTop: 31,
  marginLeft: 10,
  borderWidth: 1,
  borderRadius: 10,
  borderStyle: "dashed",
}

export const deleteImage = {
  backgroundColor: "transparent",
  top: 30,
  left: 25,
  border: "none"
}

export const CSVSStyles = styled.div`
  padding: 1rem;

  .table {
    
    width: 100%;

    .thead {
      padding-right: 15px;
    }

    .tbody {
      height: 50vh;
    }

    .tr-group {
      display: flex;
      flex-direction: column;
    }

    .tr {
      display: flex;

      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;

      :last-child {
        border-right: 0;
      }
    }
  }
`;