import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';
import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, } from '@material-ui/pickers';
import { ActivityButton } from '../ActivityButton';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import Icon from '../IconsComponent';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 400,
      margin: 10
    },
    typography: {
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    borderColor: {
      '& label.Mui-focused': {
        color: '#00bfff',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#00bfff',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: 10
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00bfff',
        },
      },
    },
    cancelButton: {
      border: '1px solid rgba(0, 0, 0, .125)',
      backgroundColor: "transparent",
      padding: '7px 17px 7px 17px',
      //color: "white",
      marginRight: 20,
      borderRadius: 10,
      // "&:hover": {
      //   backgroundColor: "#66b0ff",
      // }
    },
    confirmButton: {
      padding: '7px 17px 7px 17px',
      borderColor: "transparent",
      backgroundColor: "#007bff",
      color: "white",
      borderRadius: 10,
      "&:hover": {
        backgroundColor: "#007bff",
      }
    },
    popover: {
      marginTop: 10,
    },
    autosuggest: {
      border: "none"
    },
    inputFocused: {
      outlineStyle: "none"
    },
    absolute: {
      position: "absolute",
      zIndex: 4,
      backgroundColor: "white",
      borderTopWidth: 0,
      borderBottomWidth: 0,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      //border: "1 solid #bfbfbf",
      overflow: "auto",
      width: 385,
      maxHeight: 225,
    },
    billaloIndexSlide: {
      color: '#00e68a',
    },
    modalInProfile: {
      // backgroundColor: 'white',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
    },
    modalInProfileHeader: {
      borderBottom: '1px solid #E2E2EA',
      fontFamily: 'Poppins, sans-serif',
      fontSize: 14,
      color:'#92929D',
      padding: 20,
      marginBottom:20
    },
    cancelButtonModal: {
      fontSize: 12,
      width: 100,
      height: 38,
      border: '1px solid #E2E2EA',
      backgroundColor: "#FAFAFB",
      color: "#696974",
      borderRadius: 10,
      "&:hover": {
        backgroundColor: "#FAFAFB",
      }
    },
    confirmButtonModal: {
      fontSize: 12,
      width: 100,
      height: 38,
      borderColor: "transparent",
      backgroundColor: "#0062FF",
      color: "white",
      borderRadius: 10,
      "&:hover": {
        backgroundColor: "#0062FF",
      }
    },
    modalInProfileFooter: {
      borderTop: '1px solid #E2E2EA',
      paddingTop:20,
      marginBottom:20,
      position:'absolute',
      bottom:0,
      left:0,
      right:0,
    },
  }),
);

export const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#00e68a',
    },
  }),
)(LinearProgress);

export const Divider = () => <Grid className="border-bottom mt-2 mb-2" container item xs={12} alignItems="center"></Grid>

export const buttonSelected = {
  display: "flex",
  alignItems: "center",
  backgroundColor: "white",
  borderWidth: 1,
  borderColor: "#cccccc",
  borderStyle: "solid",
  borderRadius: 10,
  padding: "10px 10px 10px 10px",
  color: "#696974"
}

export const buttonNotSelected = {
  display: "flex",
  alignItems: "center",
  backgroundColor: "transparent",
  border: "none",
  padding: "10px 10px 10px 10px",
  color: "#696974"
}

export const borderColorDatePicker = {
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: "#e6e6e6",
  borderRadius: 10,
  // alignItems: "flex-end",
  justifyContent: "center",
  width: 180,
  // marginLeft: 25,
  // height: 39,
  '& .MuiInputInput': {
    marginLeft: 15
  },
  "& .MuiOutlinedInputNotchedOutline": {
    border: 'none'
  }
}
export const modalStyle =  {
  // display: 'flex',
  // alignItems:'center',
  // flexDirection:'column',
  justifyContent:'space-between',
  backgroundColor: 'white',
  width: 750,
  minHeight: 400,
  borderRadius: 20
}

export const mutableTextFieldProfilo = (id: string, placeholder: string, value: any, switchInput: boolean, profile: any, tab: string, gridNumber: any, className: string, setProfilo: any) => {
  let greedInput = (gridNumber - 1) as any
  // console.log("id", id)
  let date = value ? moment(value).format("DD-MM-YYYY") : ""

  const switchInputComponent = () => {
    return (
      <>
        {id === "subscription_date" ?
          <Grid item xs={gridNumber}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                style={borderColorDatePicker}
                disableToolbar
                disableFuture
                autoOk={true}
                format="DD-MM-YYYY"
                id={id}
                placeholder={placeholder}
                label=""
                keyboardIcon={<Icon color="grey" size={20} icon="calendar" />}
                value={date}
                helperText=""
                inputValue={date}
                onChange={(e: any, value: any) => { profile[id] = value; date = e; setProfilo({ [id]: moment(e).format("YYYY-MM-DD") }) }}
                InputAdornmentProps={{ position: "end" }}
                InputProps={{ disableUnderline: true, className: "ml-3" }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          :
          <Grid className="d-sm-flex flex-row-reverse" item xs={6}><span style={{ opacity: 0.4 }}>{tab}</span><br />{value}</Grid>
        }
      </>
    )
  }
  // console.log("profile[id]",profile[id])
  return (
    <>
      {switchInput
        ?
        switchInputComponent()
        : id === "subscription_date" ? <Grid item xs={gridNumber}><span style={{ opacity: 0.4 }}>{tab}</span><br />{date}</Grid>
        : <Grid className={className} item xs={gridNumber}><span style={{ opacity: profile[id] === value ? 1 : 0.4 }}>{tab}</span>{value}</Grid>
      }
    </>
  )
}

export const mutableTextFieldAnagrafica = (id: string, placeholder: string, value: any, switchInput: boolean, profile: any, tab: string, gridNumber: any, setProfilo: any) => {
  return (
    <>
      {switchInput
        ?
        <Grid item xs={gridNumber}><TextField size="small" id={id} label={placeholder} onChange={(e: any) => setProfilo({ [id]: e.target.value })} defaultValue={value} /></Grid>
        : <Grid item xs={gridNumber}><span style={{ opacity: 0.4 }}>{tab}</span><br />{value}</Grid>
      }
    </>
  )
}

export const mutableTextFieldPagamenti = (id: string, placeholder: string, value: any, switchInput: boolean, profile: any, tab: string, gridNumber: any, setProfilo: any) => {
  return (
    <>
      {switchInput
        ?
        <Grid item xs={gridNumber}><TextField size="small" id={id} label={placeholder} onChange={(e: any) => setProfilo({ [id]: e.target.value })} defaultValue={value} /></Grid>
        : <Grid item xs={gridNumber}><span style={{ opacity: 0.4 }}>{tab}</span><br />{value}</Grid>
      }
    </>
  )
}

export const mutableTextFieldUfficio = (id: string, placeholder: string, value: any, switchInput: boolean, ufficio: any, tab: string, gridNumber: any) => {

  return (
    <>
      {switchInput
        ?
        <Grid item xs={gridNumber}><TextField size="small" id={id} label={placeholder} onChange={(e: any) => ufficio[id] = e.target.value} defaultValue={value} /></Grid>
        : <Grid item xs={gridNumber}><span style={{ opacity: 0.4 }}>{tab}</span><br />{value}</Grid>
      }
    </>
  )
}