import React, { useState, useCallback, useMemo, Dispatch, SetStateAction, useEffect } from "react";
import cn from "classnames";
import moment from "moment";

import { CircularProgress, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';

import {
  CaptionDropdowns,
  CaptionNavigation,
  DayPicker,
  DayPickerProps,
  Matcher,
} from "react-day-picker";
import "react-day-picker/dist/style.css";

import localeIt from "date-fns/locale/it";

import {
  useStyles,
  usePickerStyles,
  usePickerModifierStyles,
} from "./PlantAvailability.styles";

import {
  Availability,
  AvailabilityModifiersMap,
  AvailabilityStatus,
} from "./PlantAvailability.types";

import {
  getDateRange,
  getRangesByStatus,
  getFirstFutureRangeDate,
  // makeRangesList,
  makeHoverModifier,
  mergeModifiers,
} from "./PlantAvailability.utils";

import {
  AVAILABILITY_DURATION,
  AVAILABILITY_YEAR_SPAN,
} from "./PlantAvailability.constants";

import {
  Billboard,
  // useListBillboardAvailabilityByRangeQuery,
} from "../../graphql/generated";

const DayPickerComponents: DayPickerProps["components"] = {
  Caption: (props) => (
    <>
      <CaptionDropdowns {...props} />
      <CaptionNavigation {...props} />
    </>
  ),
  CaptionLabel: () => null,
};

interface DateFromTo {
  from: string;
  to: string;
}

interface PlantAvailabilityProps {
  plant: Billboard;
  showList?: boolean;
  editable?: boolean;
  calendarHorizontal?: boolean;
  initialDate?: Date;
  className?: string;
  setDate: Dispatch<DateFromTo>;
  dates?: DateFromTo;
}

export const PlantAvailability: React.FC<PlantAvailabilityProps> = ({
  plant,
  initialDate,
  showList = true,
  editable = true,
  calendarHorizontal = false,
  className,
  setDate,
  dates,
  // setStartBusyDates
}) => {
  const classes = useStyles();
  const pickerClasses = usePickerStyles();
  const pickerModifiersClasses = usePickerModifierStyles();

  let filters = useSelector(ContentSelectors.didGetFilter)

  // const [popover, setPopover] = useState<PlantAvailabilityPopupParams>();
  const [hover, setHover] = useState<AvailabilityModifiersMap>({});
  const [active, setActive] = useState<AvailabilityModifiersMap>({});

  const [month, setMonth] = useState(() => initialDate || new Date());
  const nextMonth = useMemo(
    () => moment(month).add(1, "month").toDate(),
    [month]
  );

  const firstEnabledDate = useMemo(getFirstFutureRangeDate, []);

  const queryStartDate = moment(month)
    .startOf("month")
    .startOf("week")
    .toISOString();

  const queryEndDate = moment(nextMonth)
    .endOf("month")
    .endOf("week")
    .add(1, "week")
    .toISOString();

  const context = useMemo(
    () => ({ additionalTypenames: ["BillboardAvailability"] }),
    []
  );

  // const [{ data: availabilitiesData, fetching /*, error  */ }] =
  //   useListBillboardAvailabilityByRangeQuery({
  //     variables: {
  //       billboard_id: plant?.id,
  //       start_date: queryStartDate,
  //       stop_date: queryEndDate,
  //     },
  //     pause: !plant?.id,
  //     context,
  //   });

  const availabilities = /* availabilitiesData?.listBillboardAvailabilityByRange; */[]

  const { fromYear, toYear } = useMemo(() => {
    // const yearFrom = moment(filters.period.date.from, 'YYYY-MM-DD').year()
    // const yearTo = moment(filters.period.date.to, 'YYYY-MM-DD').year()
    const currentYear = new Date().getFullYear();
    const delta = Math.floor((AVAILABILITY_YEAR_SPAN - 1) / 2);
    return { fromYear: currentYear - delta, toYear: currentYear + delta };
  }, []);

  // const rangesList: Availability[] = useMemo(
  //   () => makeRangesList(availabilities || []),
  //   [availabilities]
  // );

  // const rangesByType: AvailabilityModifiersMap = useMemo(
  //   () => getRangesByStatus(rangesList),
  //   [rangesList]
  // );

  const modifiers = useMemo(
    () => mergeModifiers(/* rangesByType, */ hover, active),
    [/* rangesByType, */ hover, active]
  );

  const onDayClick = useCallback(
    (
      day: Date,
      event: React.MouseEvent,
      // params?: PlantAvailabilityPopupParams
    ) => {
      const { from, to } = getDateRange(day);

      const filterDateFrom = moment(filters.period.date.from, 'DD-MM-YYYY')
      const filterDateTo = moment(filters.period.date.to, 'DD-MM-YYYY')

      const dateFrom = moment(from, 'DD-MM-YYYY')
      const dateTo = moment(to, 'DD-MM-YYYY')

      if (!filterDateFrom.isAfter(dateFrom) && !filterDateTo.isBefore(dateTo)) {
        const selectedDates = {
          from: moment(from).format('YYYY-MM-DD'),
          to: moment(to).format('YYYY-MM-DD')
        }

        setActive(makeHoverModifier(day));
        setDate(selectedDates)
      } else {
        setDate(dates as DateFromTo)
        alert('Seleziona una data che sia tra il ' + filterDateFrom.format('DD-MM-YYYY') + ' e il ' + filterDateTo.format('DD-MM-YYYY'))
      }

      // // const availability = rangesList.find((item) =>
      // //   moment(item.from).isSame(from, "date")
      // // );
      // const status = availability?.status || AvailabilityStatus.FREE;


      // setPopover({
      //   ...params,
      //   anchorEl: event.target as Element,
      //   availability: availability ? availability : { from, to, status },
      //   showActions: editable && moment(from).isSameOrAfter(firstEnabledDate),
      // });
    },
    [/* rangesList, */ editable, firstEnabledDate]
  );

  // const onDayClick = useCallback(
  //   (
  //     day: Date,
  //     event: React.MouseEvent,
  //     params?: PlantAvailabilityPopupParams
  //   ) => {
  //     const { from, to } = getDateRange(day);
  //     // const availability = rangesList.find((item) =>
  //     //   moment(item.from).isSame(from, "date")
  //     // );
  //     const status = availability?.status || AvailabilityStatus.FREE;
  //     setActive(makeHoverModifier(day));
  //     setPopover({
  //       ...params,
  //       anchorEl: event.target as Element,
  //       availability: availability ? availability : { from, to, status },
  //       showActions: editable && moment(from).isSameOrAfter(firstEnabledDate),
  //     });
  //   },
  //   [rangesList, editable, firstEnabledDate]
  // );

  // const onClosePopover = useCallback(() => {
  //   setHover({});
  //   setActive({});
  //   setPopover(undefined);
  // }, []);

  const onDayMouseEnter = useCallback((day: Date, event: React.MouseEvent) => {
    setHover(makeHoverModifier(day));
  }, []);

  const onDayMouseLeave = useCallback((day: Date, event: React.MouseEvent) => {
    setHover({});
  }, []);

  const dayPickerCommonProps: Partial<DayPickerProps> = {
    locale: localeIt,
    classNames: pickerClasses,
    modifiers: modifiers as Record<string, Matcher[]>,
    modifiersClassNames: pickerModifiersClasses,
    showOutsideDays: true,
    fixedWeeks: true,
    fromYear: fromYear,
    toYear: toYear,
    min: AVAILABILITY_DURATION,
    max: AVAILABILITY_DURATION,
    disabled: [
      { before: new Date(queryStartDate) },
      { after: new Date(queryEndDate) },
      { before: firstEnabledDate },
    ],
    // onDayClick: (day, _, event) => onDayClick(day, event),
    onDayMouseEnter: (day, _, event) => onDayMouseEnter(day, event),
    onDayMouseLeave: (day, _, event) => onDayMouseLeave(day, event),
  };

  // const dateSelected = {from: new Date(), to: ''}
  useEffect(() => {
    if (dates?.from) {
      setActive(makeHoverModifier(new Date(dates.from)))
    }

  }, [])

  return (
    <Grid
      container
      spacing={4}
      direction="row"
      wrap="nowrap"
      className={className || "px-4 pt-2"}
    >
      <Grid item xs="auto">
        <Grid
          container
          spacing={4}
          wrap={"nowrap"}
          direction={calendarHorizontal ? "row" : "column"}
        >
          <Grid item>
            <DayPicker
              month={month}
              onMonthChange={setMonth}
              captionLayout="dropdown"
              components={DayPickerComponents}
              {...dayPickerCommonProps}
            />
          </Grid>
          {/* <Grid item>
            <DayPicker
              month={nextMonth}
              disableNavigation
              {...dayPickerCommonProps}
            />
          </Grid> */}
        </Grid>
        {/* <PlantAvailabilityPopup
          plant={plant}
          {...popover}
          onClose={onClosePopover}
        /> */}
      </Grid>
      {/* {showList ? (
        <Grid item xs className={cn(classes.listContainer, "pt-4")}>
          {fetching ? (
            <Grid container item justifyContent="center" alignItems="center">
              <CircularProgress className="pt-2" size={44} />
            </Grid>
          ) : null}
          {!fetching && !rangesList.length ? (
            <Grid container item justifyContent="center" alignItems="center">
              <span className={cn("poppinsFont", "pt-2", classes.emptyState)}>
                Nessun risultato trovato
              </span>
            </Grid>
          ) : null}
          {!fetching && rangesList.length ? (
            <Grid container spacing={2} direction="column">
              {rangesList.map((availability) => {
                const { id, from } = availability;
                const clickable = moment(availability.from).isSameOrAfter(
                  firstEnabledDate
                );
                return (
                  <PlantAvailabilityListItem
                    key={id}
                    availability={availability}
                    onMouseEnter={(e) => onDayMouseEnter(from, e)}
                    onMouseLeave={(e) => onDayMouseLeave(from, e)}
                    onClick={
                      clickable
                        ? (e) => onDayClick(from, e, { showInfo: false })
                        : undefined
                    }
                  />
                );
              })}
            </Grid>
          ) : null}
        </Grid>
      ) : null} */}
    </Grid>
  );
};

export default PlantAvailability;
