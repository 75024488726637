import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { Modal } from '@material-ui/core';
import { Planning } from '../components/Planning';
import { LegalAcceptance } from '../components/LegalAcceptance';
import { PlanningChoice } from '../components/PlanningChoice';
import { PlanningGuided } from '../components/PlanningGuided';
import { DraftCampainModal } from '../components/DraftCampainModal';
import { NotificationContainer } from "../components/notification-layer/NotificationContainer";

export const PlanningPage = () => {
  const dispatch = useDispatch()

  let campaignStep = useSelector(UiSelectors.getCampaignstep)

  const [planningType, setPlanningType] = useState(campaignStep ? "Avanzata" : "Scelta")
  // console.log("planningType", planningType)
  const [accettazione, setAccettazione] = useState(false)

  useEffect(() => {
    dispatch(UiActions.setSelectedPage("Pianificazione"))

    return() => {dispatch(UiActions.didSetCampaignStep(""))}
  }, [])

  return (
    <div>
      {/* {accettazione ? <div style={{ position: "absolute", top: 0 }} className="w-100 h-100">
        <LegalAcceptance setAccettazione={setAccettazione} setPlanningType={setPlanningType} />
      </div> :  */}
      {/* <Modal open={switchModalOpen} onClose={() => dispatch(UiActions.switchDraftCampainModal(''))}>
        <DraftCampainModal setAnchor={setAnchor} setPlanningType={setPlanningType} modalType={modalType} />
      </Modal> */}
      <NotificationContainer />
      <div style={{ overflow: "hidden" }}>
        {planningType === "Scelta" && <PlanningChoice setAccettazione={setAccettazione} setPlanningType={setPlanningType} />}
        {planningType === "Avanzata" && <Planning setPlanningType={setPlanningType} />}
        {planningType === "Guidata" && <PlanningGuided/>}
      </div>
      {/* } */}
    </div>
  )

}