import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Button, Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText } from 'reactstrap'
import * as AuthApi from './api/auth'
import { actions as UiActions, selectors as UiSelectors } from './store/slices/ui';
import { selectors as AuthSelectors } from './store/slices/auth'
import { actions as AuthActions } from './store/slices/auth'
import { actions as ProfileActions, selectors as ProfileSelectors } from './store/slices/profile'
import { actions as ContentActions, selectors as ContentSelectors } from './store/slices/content';
import placeholder_profile from '../src/assets/images/placeholder_profile.png'
import billalo_logo from './assets/logo.png'
import { useStyles } from "./components/campaign/Campain.css";
import _ from "lodash";

export const Header = ({ className }: any) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated);
  const userAttributes = useSelector(ProfileSelectors.getProfile)
  const selectedPage = useSelector(UiSelectors.getSelectedPage)
  let noGroup = useSelector(UiSelectors.getNoGroup)

  const userProfile = useSelector(ProfileSelectors.getAdvertiser)
  // console.log("Header userProfile", userProfile)

  // useEffect(() => {
  //   if(_.isEmpty(userProfile))
  //   dispatch(ProfileActions.willGetAdvertiser(''))
  // }, [userProfile])

  return (
    <Navbar style={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 4 }} className={className} light expand="md">
      {noGroup ? null : <NavbarBrand  href="/login" onClick={() => {
        dispatch(AuthActions.willLogoutUser(''));
      }}>
        <img src={billalo_logo} width="190" height="30" /></NavbarBrand>}
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        {noGroup
          ? <NavItem>
            <NavLink className="text-dark" href="/login/" onClick={() => {
              dispatch(AuthActions.willLogoutUser(''));
            }}>Torna a login</NavLink>
          </NavItem>
          : <Nav className="d-sm-flex w-100 align-self-center justify-content-center mt-2" navbar>
            {isAuthenticated &&
              <>
                <NavItem>
                  <NavLink data-cy="/dashboard" className="mr-2 text-dark poppinsFont" href="/">
                    <div className={selectedPage === "Dashboard" ? "selectedPage" : ""}>Dashboard</div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink data-cy="/planning" className="mr-2 text-dark poppinsFont" href="/planning">
                    <div className={selectedPage === "Pianificazione" ? "selectedPage" : ""}>Pianificazione</div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink data-cy="/campaign" className="mr-2 text-dark poppinsFont" href="/campaign">
                    <div className={selectedPage === "Campagne" ? "selectedPage" : ""}>Campagne</div>
                  </NavLink>
                </NavItem>
              </>
            }
          </Nav>}
        <Nav navbar>
          {isAuthenticated ? (noGroup ? null :
            <UncontrolledDropdown style={{ marginLeft: noGroup ? 80 : 0 }} nav inNavbar>
              <DropdownToggle data-cy="toAtavar" className="text-dark" nav caret>
                <img data-cy="avatar" className="loggedAvatar" src={placeholder_profile} />
                {/* {userAttributes.given_name} {userAttributes.family_name} */}
                {!_.isEmpty(userProfile) ? userProfile.name : <>{userAttributes.given_name} {userAttributes.family_name}</>}
              </DropdownToggle>
              <DropdownMenu className={classes.drowdownMenu} right>
                {noGroup ? null : <DropdownItem onClick={() => dispatch(UiActions.goTo("/profile"))}>Profilo</DropdownItem>}
                <DropdownItem divider />
                <DropdownItem onClick={() => {
                  dispatch(AuthActions.willLogoutUser(''));
                }}>Logout</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : (
            noGroup ? null : <NavItem>
              <NavLink className="text-dark"  href="/login/" onClick={() => {
                dispatch(AuthActions.willLogoutUser(''));
              }}>Login</NavLink>
            </NavItem>
          )
          }
        </Nav>
      </Collapse>
    </Navbar>
  )
}