import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { loader } from 'graphql.macro';
import { configuration } from '../config';
import moment from 'moment';
import _ from 'lodash';

export const configure = () => {
  const stage: string = process.env.REACT_APP_STAGE != undefined ? process.env.REACT_APP_STAGE : "dev"
  Amplify.configure(configuration[stage]);
}
export const listFilteredBillboards = async (filters: any, start_busy_dates: any, campaign_id: string) => {
  // console.log("in api", media_owner, nextToken)
  const query = loader('../graphql/listFilteredBillboards.gql');
  console.log('with query listFilteredBillboards: ', query, filters)

  try {
    const result: any = await API.graphql({ query: query, variables: { input: filters, start_busy_dates: start_busy_dates, campaign_id: campaign_id } });
    // console.log('result listFilteredBillboards: ', result)
    return result
  } catch (error) {
    console.log("errore api")
    throw error
  }
}
export const uploadFilter = async (filters: any) => {
  // console.log("in api", media_owner, nextToken)
  try {
    localStorage.setItem('filters', JSON.stringify(filters));
    return
  } catch (error) {
    console.log("errore api")
    throw error
  }
}
export const getFilter = async () => {
  try {
    let filters: any = localStorage.getItem('filters')
    filters = JSON.parse(filters)
    console.log("in api getFilter", filters)

    return filters
  } catch (error) {
    console.log("errore api")
    throw error
  }
}

export const listMyCampaigns = async () => {
  const query = loader('../graphql/listMyCampaigns.gql');

  try {
    let campaigns: any = await API.graphql({ query: query });
    let campagne = [] as any
    console.log('risultato mie campagne', campaigns)
    if (!_.isEmpty(campaigns.data.listMyCampaigns.items)) {
      let now = moment().subtract(29, 'm')
      campagne = _.filter(campaigns.data.listMyCampaigns.items, function (o: any) {
        // console.log('ee', moment(o.order_date), now, moment(o.order_date).isAfter(now))
        if (o.status === "Draft" || o.status === "draft") {
          return moment(o.order_date).isAfter(now)
        } else {
          return true
        }
      })
    }
    console.log("risultato mie campagne 2", campagne)
    return campagne
  } catch (error) {
    console.log("errore api")
    throw error
  }
}


export const batchGetOrders = async (campaign_id: string, orders: any) => {
  const query = loader('../graphql/batchGetOrders.gql');

  try {
    let ordini: any = await API.graphql({ query: query, variables: { campaign: campaign_id, orders: orders } });
    console.log('risultato miei ordini', ordini)

    return ordini.data.batchGetOrders
  } catch (error) {
    console.log("errore api")
    throw error
  }
}

export const updateOrderState = async (campaign_id: any, id: any, status: string) => {
  console.log("with query: ",campaign_id, id, status)
  const query = loader('../graphql/updateOrderState.gql');

  try {
    const result: any = await API.graphql({ query: query, variables: { campaign: campaign_id, id: id, action: status } });
    return result.data.updateOrderState
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}
export const updateCampaignState = async (campaign_id: any, status: string) => {
  console.log("with query: ",campaign_id, status)
  const query = loader('../graphql/updateCampaignState.gql');

  try {
    const result: any = await API.graphql({ query: query, variables: { id: campaign_id, action: status } });
    return result
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}
export const batchAddBillboardAvailability = async (input: any, dates: any, campaign_id: string) => {
  console.log("with query: ",input, dates, campaign_id)
  const query = loader('../graphql/batchAddBillboardAvailability.gql');

  try {
    const result: any = await API.graphql({ query: query, variables: { input: input, dates: dates, campaign_id: campaign_id } });
    return result.data.batchAddBillboardAvailability
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}

export const getResourceUrl = async (campaign: string, order: string, type: string) => {
  console.log("with query: ",campaign, type)
  const query = loader('../graphql/getResourceUrl.gql');

  try {
    const result: any = await API.graphql({ query: query, variables: {campaign: campaign, order: order, type: type } });
    
    return result
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}