import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TextField } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Icon from '../../IconsComponent';

import { actions as AuthActions, selectors as AuthSelectors } from '../../../store/slices/auth'
import { actions as UiActions } from '../../../store/slices/ui'
import { useStyles } from '../Profile.css';
import { ActivityButtonModal } from './ActivityButtonModal';

const ResetPasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  newPassword: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
});

export const ResetPassword = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  return (
    <Grid container alignItems="center" justify="center">
      <Grid className="mb-4 mr-4 poppinsFont" item xs={9}><h4>Reset password</h4></Grid>
      <Grid item xs={12}>
        <Formik
          initialValues={{
            oldPassword: '',
            newPassword: ''
          }}
          validationSchema={ResetPasswordSchema}
          validateOnBlur={true}
          onSubmit={values => {
            // console.log('in onsubmit with: ', values)
            dispatch(AuthActions.willResetPassword({ oldPassword: values.oldPassword, newPassword: values.newPassword }));
          }}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form>
              <Grid container alignItems="center" justify="space-between">

                <Grid container justify="center" item xs={6}>
                  <TextField
                    className={classes.borderColor}
                    size="small"
                    id="oldPassword"
                    variant='outlined'
                    label="vecchia password"
                    onChange={(e: any) => setFieldValue('oldPassword', e.target.value)}
                    value={values.oldPassword}
                    helperText={touched.oldPassword ? errors.oldPassword : ""}
                    error={touched.oldPassword && Boolean(errors.oldPassword)}
                  />
                </Grid>
                <Grid container justify="center" item xs={6}>
                  <TextField
                    className={classes.borderColor}
                    size="small"
                    id="newPassword"
                    variant='outlined'
                    label="nuova password"
                    onChange={(e: any) => setFieldValue('newPassword', e.target.value)}
                    value={values.newPassword}
                    helperText={touched.newPassword ? errors.newPassword : ""}
                    error={touched.newPassword && Boolean(errors.newPassword)}
                  />
                </Grid>

              </Grid>
              <Grid className={`${classes.modalInProfileFooter} poppinsFont`} item xs={12}>
                <Grid className="d-sm-flex">
                  <Grid item xs={7} />
                  <Grid item xs={2}>
                    <button className={classes.cancelButtonModal} onClick={() => { dispatch(UiActions.switchOpenModal(false)) }}>Annulla</button>
                  </Grid>
                  <Grid item xs={2}>
                    <ActivityButtonModal data-cy="confirm" className={classes.confirmButtonModal} name="willResetPassword">
                      Salva
                    </ActivityButtonModal>
                  </Grid>
                </Grid>
              </Grid>

            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}