import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Grid, TextField, Popover, InputAdornment, Chip, Checkbox, Modal } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStyles, buttonApply, simpleObjectiveButton, imageSimpleObjective, goToAdvancedPlannyng, advancedPlanningButton } from '../campaign/Campain.css';
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../../store/slices/ui';
import { actions as NotificationActions } from '../../store/slices/notification';
import Icon from '../IconsComponent';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, } from '@material-ui/pickers';
import Autosuggest, { BlurEvent, ChangeEvent } from 'react-autosuggest';
import { useSuggestion } from "../Suggestion.hooks";
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import _ from 'lodash';
import { Period } from '../filters/Period';
import { Map } from '../Map';
import { ConfirmDraft, DraftCampainModal } from '../DraftCampainModal';
import { PlantDetailOverview } from '../PlantDetailsOverview';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';


const fortmatTypes: any[] = ["600x300", "400x300", "Altro"]

const billboardsFormat = fortmatTypes.map((o: any) => { return { format: o } })

const icon = <CheckBoxOutlineBlankIcon fontSize="small" className="lightBlueColor" />;
const checkedIcon = <CheckBoxIcon fontSize="small" className="lightBlueColor" />;

export const FirstStep = (props: any) => {
  const { setBudget, budget, handleStepsArray } = props

  const dispatch = useDispatch()
  const classes = useStyles()

  let campaignStep = useSelector(UiSelectors.getCampaignstep)
  let campaignData = useSelector(ContentSelectors.getCampaignData)
  let filters = useSelector(ContentSelectors.didGetFilter)
  const switchModalOpen = useSelector(UiSelectors.switchDraftCampainModal)
  let myBillboardsInMap: any[] = useSelector(ContentSelectors.listBillboards)
  let selectedBillboards: any[] = useSelector(ContentSelectors.getSelectedBillboards)
  // console.log("FirstStep selectedBillboards", selectedBillboards)

  const [endDate, setEndDate] = useState('')
  const [startDate, setStartDate] = useState('')
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [plantProps, setPlantProps] = useState({})
  const [modalType, setModalType] = useState("")
  const [planningType, setPlanningType] = useState("")
  const [suggestions, setSuggestions] = useState([] as any)
  const [selectedCity, setSelectedCity] = useState(filters.city ? filters.city : [] as any)
  const [visualFormat, setVisualFormat] = useState([]) as any
  const [format, setFormat] = useState(filters.format ? filters.format : []) as any[]
  const [error, setError] = useState(false) as any
  const [error2, setError2] = useState(false) as any
  //// questi sotto servono per AutoSuggest, non eliminateli!
  const [data, setData] = useState("")
  const [fieldValue, setFieldValue] = useState("")
  const [city, setCity] = useState("")

  const open = Boolean(anchor);
  const id = open ? 'simple-popover' : undefined;

  const open2 = Boolean(anchorEl);
  const id2 = open ? 'simple-popover' : undefined;

  const deleteCity = (city: any) => {
    let cities = [...selectedCity]
    setSelectedCity(cities.filter((i: any) => {
      return i != city
    }))
    dispatch(ContentActions.willUploadFilter({ key: "city", value: cities.filter((i: any) => { return i != city }) }))
    dispatch(ContentActions.willFilterBillboards({ filters: {}, is_confirm: false }))
  }

  const pushCity = (city: any) => {
    let cities = [...selectedCity]
    cities.push(city.name)
    setSelectedCity(cities)
    dispatch(ContentActions.willUploadFilter({ key: "city", value: cities }))
    dispatch(ContentActions.willFilterBillboards({ filters: {}, is_confirm: false }))
  }

  const {
    getSuggestions,
    getSuggestionValue,
    renderSuggestion,
    renderSectionTitle,
    getSectionSuggestions,
    onChange,
    value,
    setValue,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
  }: any = useSuggestion({
    setFieldValue,
    setData,
    setSuggestions,
    setCity,
    pushCity,
  })

  const inputProps: any = {
    placeholder: "Comune",
    value,
    onChange: onChange,
    className: "autosuggestFilterBorderBottom",
    id: "comune",
  };

  // let selectedFormats: any[] = []
  // console.log("billboardsFormat", billboardsFormat)

  useEffect(() => {
    dispatch(ContentActions.willFilterBillboards({ filters: {}, is_confirm: false }))
    if (filters.format) {
      setFormat(filters.format)
    } else {
      setFormat([])
    }
  }, [filters.period, filters.format])

  // const applyFunction = () => {
  //   dispatch(ContentActions.willUploadFilter({ key: "city", value: selectedCity }))
  //   dispatch(ContentActions.willFilterBillboards({ filters: {}, is_confirm: false }))
  //   setAnchor(null)
  //   setPlanningType("")
  // }

  return (
    <div style={{ overflow: "auto", height: '100%' }} className="d-flex justify-content-center poppinsFont">
      <Grid container item xs={10} alignItems="center" justify="space-around">
        <Grid container item xs={5} spacing={4}>
          <div /* style={{ marginTop: 50 }} */>
            <Grid id="rowInMap" className="d-sm-flex align-items-center mt-4 mb-3" container item xs={12}>
              <Grid item xs={12}><h3>Pianificazione guidata: {campaignData && campaignData.budgetAndTarget.target}</h3></Grid>
              {myBillboardsInMap.length
                ? <Grid item xs={12}><span>Impianti disponibili: {myBillboardsInMap.length}</span></Grid>
                : <Grid item xs={12}><span className="text-muted" style={{ fontSize: 10 }}>Per poter andare avanti, deve essere disponibile almeno un impianto che verà visualizzato nella mappa</span></Grid>}
              {/* <div style={{color:"#ffa64d", marginLeft:30}}><Icon size={20} color="#ffa64d" icon="spam" /> coming soon</div> */}
            </Grid>
            <Grid className={classes.simpleObjective} container item xs={12} spacing={4}>
              <Grid item xs={12}>
                <span className="text-muted detailText">Nome della tua campagna*</span>
                <TextField
                  id="campaignName"
                  data-cy="campaignName"
                  type="text"
                  fullWidth
                  value={campaignData.campaignName}
                  onChange={(e: any) => {
                    setError(false)
                    dispatch(ContentActions.didSetCampaignData({ campaignName: e.target.value, budgetAndTarget: { target: campaignData.budgetAndTarget.target, budget: campaignData.budgetAndTarget.budget } }))
                  }
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <span className="text-muted detailText">Date di inizio e fine della campagna*</span>
                <TextField
                  id="period"
                  data-cy="period"
                  type="text"
                  fullWidth
                  contentEditable={false}
                  value={filters.period ? `Da ${filters.period.date.from} a ${filters.period.date.to}` : ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment style={{ cursor: 'pointer' }} position="start" onClick={(event: any) => {
                        setError(false);
                        setAnchor(event.currentTarget); setPlanningType("Period");
                      }}>
                        <Icon size={20} icon="deals" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid container item xs={12}>
                <Grid className="mb-2" item xs={12}><span className="text-secondary detailText">Comune*</span></Grid>
                <Grid item xs={12}>
                  <Autosuggest
                    multiSection={true}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    renderSectionTitle={renderSectionTitle}
                    getSectionSuggestions={getSectionSuggestions}
                    inputProps={inputProps}
                    theme={{
                      input: classes.autosuggest,
                      inputOpen: classes.autosuggest,
                      inputFocused: classes.inputFocused,
                      suggestionsContainer: classes.absolute,
                      suggestionsContainerOpen: classes.absolute,
                    }}
                  />
                </Grid>
              </Grid>
              {selectedCity
                ? <Grid item xs={12}>
                  {selectedCity.map((city: any, index: any) => {
                    return (
                      <Chip
                        data-cy="cityChip"
                        variant="outlined"
                        label={city}
                        key={index}
                        onDelete={() => deleteCity(city)} />
                    )
                  })}
                </Grid>
                : null}
              <Grid item xs={12}>
                <span className="text-muted detailText">Seleziona il formato</span>
                <Autocomplete
                  className={classes.borderColor}
                  id="format"
                  value={format}
                  options={fortmatTypes}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => <TextField {...params} label="" />}
                  onChange={(e: any, value: any) => {
                    let result = [] as any[]
                    value.map((i: any) => {
                      if (result.includes(i)) {
                        result = result.filter((o: string) => { return o !== i })
                      } else {
                        result.push(i)
                      }
                    })
                    setVisualFormat(value)
                    setFormat(value)
                    dispatch(ContentActions.willUploadFilter({ key: "format", value: result }))
                  }}
                  disableCloseOnSelect
                  multiple
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </React.Fragment>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <span className="text-muted detailText">Budget per la campagna*</span>
                <br />
                {(!campaignData.campaignName || !filters.period || !filters.city /* || !filters.format */) && <span className="text-muted detailText">{'(per sbloccare il budget, inserire prima il resto dei campi obbligatori)'}</span>}
                <TextField
                  id="budget"
                  data-cy="budget"
                  type="number"
                  fullWidth
                  disabled={!campaignData.campaignName || !filters.period || !filters.city /* || !filters.format */}
                  value={budget || ''}
                  onChange={(e: any) => {
                    setError(false)
                    setError2(false)
                    setBudget(e.target.value)
                    dispatch(ContentActions.didSetCampaignData({ campaignName: campaignData.campaignName, budgetAndTarget: { target: campaignData.budgetAndTarget.target, budget: e.target.value } }))
                  }}
                  onBlur={() => dispatch(ContentActions.didSortByObjective({ n_uscite: 1, billboards: myBillboardsInMap, budgetAndTarget: { target: campaignData.budgetAndTarget.target, budget: campaignData.budgetAndTarget.budget } }))}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Icon size={20} icon="deals" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {error && <p style={{ color: 'red', fontSize: 14 }}>Tutti i campi sono richiesti per procedere</p>}
                {error2 && <p style={{ color: 'red', fontSize: 14 }}>Importo troppo basso per acquistare degli impianti</p>}

                {myBillboardsInMap[0] && <button data-cy="apply" className='w-100' style={{ ...buttonApply, fontSize: 15 }} onClick={() => {
                  if (!campaignData.campaignName || !campaignData.budgetAndTarget.budget || !filters.period || !filters.city /* || !filters.format */) {
                    setError(true)
                    // setStep("Pianifica campagna")
                    // setError(false)
                    // setFirstStep(true)
                    // setModalType("confirm")
                    // dispatch(UiActions.switchDraftCampainModal(''))
                  } else {
                    if (_.isEmpty(selectedBillboards)) {
                      setError2(true)
                    } else {
                      dispatch(UiActions.didSetCampaignStep("Pianifica campagna"))
                      handleStepsArray("Pianifica campagna")
                      setError(false)
                      setError2(false)
                    }
                    // setError(true)
                    // dispatch(NotificationActions.willShowNotification({ message: 'Sono richiesti tutti i campi per proseguire', type: "danger" }));
                  }
                }}>Continua</button>}
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid id="mapComponent" style={{ position: 'relative' }} className="h-100" container item xs={6}>
          <Map step={"Pianificazione guidata"} setAnchorEl={setAnchorEl} setPlantProps={setPlantProps} hideFoundedBillboards />
        </Grid>
        <Popover
          id={id}
          open={open}
          anchorEl={anchor}
          onClose={() => { setAnchor(null); setModalType(""); }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }} >
          {planningType === "Period" && <Period setAnchor={setAnchor} setPlanningType={setPlanningType} noFilter />}
        </Popover>

        <Popover
          className={classes.popoverContainer}
          id={id2}
          open={open2}
          anchorEl={anchorEl}
          //  anchorReference="anchorPosition"
          //  anchorPosition={{ top: 100, left: 560 }}
          onClose={() => { setAnchorEl(null); setPlantProps({}) }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
          transformOrigin={{ vertical: 'top', horizontal: 'left', }}
        >
          <PlantDetailOverview items={plantProps} />
        </Popover>
      </Grid>
    </div>
  )
}