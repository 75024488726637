import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { useStyles, legalAcceptanceButton, acceptLegalAcceptance } from './campaign/Campain.css';
import { Grid, Checkbox, CheckboxProps, FormControlLabel } from '@material-ui/core';
import { selectors as ContentSelectors } from '../store/slices/content';
import { Spinner } from 'reactstrap';
import Icon from './IconsComponent';
import { withStyles } from '@material-ui/core/styles';
import { LegalText } from '../assets/text/legalAcceptance';
import _ from 'lodash';

const AcceptanceCheckbox = withStyles({
  root: {
    // color: green[400],
    '&$checked': {
      color: "#66b0ff",
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export const LegalAcceptance = (props: any) => {
  const { setAccettazione, setPlanningType, confirmButtonAction } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const stampaConsegna = useSelector(ContentSelectors.getStampaConsegna)

  const [firstCondition, setFirstCondition] = useState(false)
  const [secondCondition, setSecondCondition] = useState(stampaConsegna)
  const [firstAccepted, setFirstAccepted] = useState(false)
  const [secondAccepted, setSecondAccepted] = useState(stampaConsegna)
  const [error, setError] = useState(false)

  let activityRunning = useSelector(UiSelectors.getActivitiesRunningGeneric)

  const required = () => {
    if (firstAccepted) {
      setError(false)
      confirmButtonAction(secondAccepted)
      // setAccettazione(false)
    } else {
      setError(true)
    }
  }

  useEffect(() => {
    if (firstAccepted) {
      setError(false)
    }
    if (firstAccepted) {
      setError(false)
    }
  }, [firstAccepted, secondAccepted])

  useEffect(() => {
    if (firstAccepted) {
      setError(false)
    }
  }, [secondAccepted])
  return (
    <div
      style={{ position: "absolute", top: 30 }}
      className="d-sm-flex flex-column align-items-center poppinsFont">
      <div style={{ borderRadius: 14, width: 700 }} className="d-sm-flex flex-column justify-content-center align-items-center bg-white p-4">
        <Grid className="w-100">
          <Grid className="mb-3 mt-2" container item xs={12} direction="row" >
            <Grid item xs={11} > Prima di proseguire</Grid>
            <Grid item xs={1}><button className="border-0 bg-transparent" onClick={() => {
              // setAccettazione(false);
              // setPlanningType("Scelta")
              dispatch(UiActions.switchDraftCampainModal(''))
            }}><Icon size={20} icon="close" /></button></Grid>
            <Grid item xs={12}><div className="border-bottom mt-3" /></Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid className="mt-2" container item xs={12} alignItems="center">
              <Grid className="mt-2 hoverPointer" container item xs={2}>
                <Grid onClick={() => setFirstCondition(!firstCondition)} item xs={6}><span><Icon className={firstCondition ? "" : "right"} size={25} icon="chevronRight" /></span></Grid>
                <Grid onClick={() => setFirstCondition(!firstCondition)} item xs={6}><span><Icon size={25} icon="draft" /></span></Grid>
              </Grid>
              <Grid data-cy="firstAccettaption" className="hoverPointer" onClick={() => setFirstCondition(!firstCondition)} item xs={8}> <span style={{ color: firstAccepted ? "#66b0ff" : "" }}>leggi e accetta le condizioni generali di utilizzo</span></Grid>
              <Grid className="ml-5" item xs={1} >
                {firstAccepted ? <FormControlLabel
                  control={<AcceptanceCheckbox checked={firstAccepted} onChange={(e: any) => setFirstAccepted(e.target.checked)} name="checkedB" />}
                  label=""
                /> : null}
              </Grid>
            </Grid>
            {firstCondition ? <Grid className="p-4" >
              <Grid style={{ opacity: 0.5, overflow: "auto", height: 170, fontSize: 14 }}>
                <LegalText />
              </Grid>
              <Grid className="mt-4 align-items-center" container >
                <Grid item xs={1} >
                  <FormControlLabel
                    data-cy="firstAccettaptionCheckbox"
                    className="mt-2"
                    control={<AcceptanceCheckbox checked={firstAccepted} onChange={(e: any) => { setFirstAccepted(e.target.checked); setFirstCondition(false); }} name="checkedB" />}
                    label=""
                  /></Grid>
                <Grid item xs={11}><span style={{ opacity: 0.7 }}>Ho letto e accettato le condizioni generali di utilizzo</span></Grid>
              </Grid>
            </Grid> : null}
            <Grid item xs={12}><div className="border-bottom mt-3" /></Grid>
          </Grid>



          <Grid item xs={12}>
            <Grid className="mt-2" container item xs={12} alignItems="center">
              <Grid className="mt-2 hoverPointer" container item xs={2}>
                <Grid item xs={6}/>
                <Grid onClick={() => setSecondCondition(!secondCondition)} item xs={6}><span><Icon size={25} icon="sales" /></span></Grid>
              </Grid>
              <Grid data-cy="secondAccettaption" className="hoverPointer" onClick={() => setSecondAccepted(!secondAccepted)} item xs={8}>
                <span style={{ color: secondAccepted ? "#66b0ff" : "" }}>
                  {'Stampa&Consegna'}
                </span></Grid>
              <Grid className="ml-5" item xs={1} >
                <FormControlLabel
                  control={<AcceptanceCheckbox checked={secondAccepted} onChange={(e: any) => setSecondAccepted(e.target.checked)} name="checkedB" />}
                  label=""
                />
              </Grid>
            </Grid>

            {secondAccepted ? <Grid className="p-4" >
              <Grid style={{ opacity: 0.5, overflow: "auto", height: "auto", fontSize: 14 }}>
                <span >Grazie per aver scelto il servizio {'Stampa&Consegna'}. Verrai ricontattato dall’assistenza clienti per definire le modalità di trasmissione dei file di stampa</span>
              </Grid>
            </Grid> : null}
            <Grid item xs={12}><div className="border-bottom mt-3" /></Grid>
          </Grid>
          {/* <Grid className="mt-2" item xs={12}>
            <Grid className="mt-2" container item xs={12} alignItems="center">
              <Grid className="mt-2 hoverPointer" onClick={() => setSecondCondition(!secondCondition)} container item xs={2}>
                <Grid item xs={6}><span><Icon className={secondCondition ? "" : "right"} size={25} icon="chevronRight" /></span></Grid>
                <Grid item xs={6}><span><Icon size={25} icon="draft" /></span></Grid>
              </Grid>
              <Grid data-cy="secondAccettaption" className="hoverPointer" onClick={() => setSecondCondition(!secondCondition)} item xs={8}> <span style={{ color: secondAccepted ? "#66b0ff" : "" }}>leggi e accetta le condizioni particolari di contratto di prestazione d'opera per la veicolazione della campagna pubblicitaria</span></Grid>
              <Grid className="ml-5" item xs={1} >
                {secondAccepted ? <FormControlLabel
                  control={<AcceptanceCheckbox checked={secondAccepted} onChange={(e: any) => setSecondAccepted(e.target.checked)} name="checkedB" />}
                  label=""
                /> : null}
              </Grid>
            </Grid>
            {secondCondition ? <Grid className="p-4">
              <Grid style={{ opacity: 0.5, overflow: "auto", height: 120 }}>
                <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>
              </Grid>
              <Grid className="mt-4 align-items-center" container >
                <Grid item xs={1} >
                  <FormControlLabel
                    data-cy="secondAccettaptionCheckbox"
                    className="mt-2"
                    control={<AcceptanceCheckbox checked={secondAccepted} onChange={(e: any) => { setSecondAccepted(e.target.checked); setSecondCondition(false) }} name="checkedB" />}
                    label=""
                  />
                </Grid>
                <Grid item xs={11}><span style={{ opacity: 0.7 }}>Ho letto e accettato le condizioni generali di utilizzo</span></Grid>
              </Grid>
            </Grid> : null}
            <Grid item xs={12}><div className="border-bottom mt-3" /></Grid>
          </Grid> */}
          {error ? <Grid className="text-danger mt-2" item xs={12}>Per continuare, è necessario accettare le condizioni</Grid> : null}
          <Grid className="mt-4" container item xs={12}>
            <Grid item xs={1}><Icon size={25} color="#ffcc00" icon="spam" /></Grid>
            <Grid item xs={11}>
              <b>Attenzione!</b> <br />
              <span style={{ opacity: 0.6 }}>
                Cliccando sul pulsante <b>"Continua"</b> sarà avviata la sessione di pagamento.
                Avrai 30 minuti di tempo per completare l'acquisto.
                Decorso questo tempo senza aver completato l'acquisto, gli spazi pubblicitari
                selezionati per questa campagna saranno resi nuovamente disponibili per altri utenti.
              </span>
            </Grid>
          </Grid>
          <Grid className="d-sm-flex justify-content-center mt-5" item xs={12}>
            <button data-cy="confirmAccettaption" style={acceptLegalAcceptance} onClick={required}>Continua {activityRunning === true ? <Spinner size="sm" color="light" /> : null}</button>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
