import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { Paper, Grid, Modal, Popover, Stepper, Step, StepLabel, TextField, InputAdornment, } from '@material-ui/core';
import { Map } from './Map';
import { Campain } from './advanced-planning/Campain';
import { CampainSecondStep } from './advanced-planning/CampainSecondStep'
import { CampainConfirmStep } from './campaign/campaign-confirm/CampainConfirmStep';
import { CampainPaymentStep } from './campaign/campaign-payment/CampainPaymentStep';
import { CampainPaymentConfirm } from './campaign/campaign-payment/CampaignPaymentConfirm';
import { PlantDetailOverview } from './PlantDetailsOverview';
import { ReactComponent as LockedIcon } from '../assets/icons/ic_secure_outline.svg';
import { ReactComponent as CheckIcon } from '../assets/icons/ic_Check.svg';
import { ReactComponent as CardIcon } from '../assets/icons/ic_Card_View.svg';
import { useStyles, ColorlibConnector } from './campaign/Campain.css';
import Icon from './IconsComponent';

const steps: string[] = ["Seleziona impianti", "Pianifica campagna", "Conferma"]
let initialStepArray: string[] = ["Seleziona impianti"]

export const Planning = ({ setPlanningType }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  let campaignStep = useSelector(UiSelectors.getCampaignstep)
  let campaignData = useSelector(ContentSelectors.getCampaignData)

  const [plants, setPlants] = useState([]) as any
  const [budgetAndName, setBudgetAndName] = useState({}) as any
  const [data, setData] = useState({})
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [plantProps, setPlantProps] = useState({})
  const [stepsDone, setStepsDone] = useState(initialStepArray)

  const stepIconSwitch = (step: string, index: number) => {

    if (!stepsDone.includes(step)) {
      return CardIcon
    } else if (stepsDone.includes(step) && stepsDone.includes(stepsDone[index + 1])) {
      return CheckIcon
    } else if (stepsDone.includes(step) && step === campaignStep) {
      return LockedIcon
    }
  }

  const handleStepsArray = (step: string) => {
    let newArray: string[] = [...stepsDone]

    if (newArray.includes(step) && newArray[steps.indexOf(step)]) {
      // newArray.length = steps.indexOf(step) - 1
      newArray.splice(steps.indexOf(step) + 1)
    } else {
      if (newArray.includes(step)) {
        newArray.filter((oldStep: string) => step !== oldStep)
      } else {
        newArray.push(step)
      }
    }
    setStepsDone(newArray)
  }

  const selectedStep = (select: any) => {
    if (campaignStep === select) {
      return classes.stepSelected
    }
    else {
      return classes.stepDone
    }
  }

  const open = Boolean(anchor);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <div>
        <Grid container xs={12}>
          {campaignStep === "Payment" || campaignStep === "Confirmed"
            ? null
            : <Grid className="d-sm-flex poppinsFont" container item xs={12} alignItems="center" justify="center">
              {campaignStep === "Pianifica campagna" &&
                <Grid style={{ backgroundColor: 'rgb(250, 250, 251)', height: '100%', width: '30%' }} container alignItems="center" justify="center">
                  <Grid item xs={10}>
                    <TextField
                      className={classes.campaignInput}
                      id="campainName"
                      data-cy="campainName"
                      placeholder="Nome Campagna"
                      variant="outlined"
                      size='small'
                      value={campaignData.campaignName}
                      fullWidth
                      onChange={(e: any) => dispatch(ContentActions.didSetCampaignData({ campaignName: e.target.value, budgetAndTarget: { target: campaignData.budgetAndTarget.target, budget: campaignData.budgetAndTarget.budget } }))}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <Icon size={20} icon="edit" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>}
              <Grid item style={{ width: campaignStep === "Pianifica campagna" ? '70%' : '80%' }}>
                <Stepper alternativeLabel activeStep={steps.indexOf(campaignStep)} connector={<ColorlibConnector />}>
                  {steps.map((stepname: string, index: number) => (
                    <Step key={stepname}>
                      <StepLabel className={stepsDone.includes(stepname) ? selectedStep(stepname) : classes.stepDisabled} StepIconComponent={stepIconSwitch(stepname, index)} onClick={() => {
                        // console.log("stepsDone.includes(stepname)", stepsDone.includes(stepname))
                        if (stepsDone.includes(stepname) && campaignStep !== stepname) {
                          handleStepsArray(stepname)
                          dispatch(UiActions.didSetCampaignStep(stepname))
                        }
                      }}>{stepname}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            </Grid>
          }
          {campaignStep === "Seleziona impianti" && <div id='rowInMap' style={{ overflowX: "hidden", overflowY: "auto", width: "30%" }} className="planningHeight position-relative">
            <Campain handleStepsArray={handleStepsArray} setPlanningType={setPlanningType} /></div>}
          {campaignStep === "Pianifica campagna" && <div style={{ overflowX: "hidden", overflowY: "auto", width: "30%" }} className="planningHeight">
            <CampainSecondStep handleStepsArray={handleStepsArray} plants={plants} setBudgetAndName={setBudgetAndName} /></div>}
          {campaignStep === "Conferma" && <div style={{ overflowX: "hidden", overflowY: "auto", width: "100%" }} className="planningHeight">
            <CampainConfirmStep handleStepsArray={handleStepsArray} setData={setData} /> </div>}
          {campaignStep === "Payment" && <div style={{ overflow: "hidden", width: "100%", height: 'calc(100vh - 83px)' }}>
            <CampainPaymentStep data={data} /> </div>}
          {campaignStep === "Confirmed" && <div style={{ overflowX: "hidden", overflowY: "auto", width: "100%" }} className="planningHeight">
            <CampainPaymentConfirm data={data} /> </div>}
          {campaignStep === "Seleziona impianti" || campaignStep === "Pianifica campagna" ? <div id="mapComponent" style={{ width: "70%", position: 'relative' }} className="planningHeight">
            <Map setPlants={setPlants} step={campaignStep} setPlantProps={setPlantProps} setAnchorEl={setAnchor} />
          </div> : null}
        </Grid>
      </div>
      <Popover
        className={classes.popoverContainer}
        id={id}
        open={open}
        anchorEl={anchor}
        //  anchorReference="anchorPosition"
        //  anchorPosition={{ top: 100, left: 560 }}
        onClose={() => { setAnchor(null); setPlantProps({}) }}
        anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
        transformOrigin={{ vertical: 'top', horizontal: 'left', }}
      >
        <PlantDetailOverview items={plantProps} />
      </Popover>
    </>
  )
}