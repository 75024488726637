import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import GoogleMapReact, { fitBounds } from 'google-map-react';
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { CampaignDetailsLeft } from '../components/campaign/campaign-details/CampaignDetailsLeft';
import { MediaOwnersList } from '../components/MediaOwnersList';
import { CampaignDetailsRight } from '../components/campaign/campaign-details/CampaignDetailsRight';
import { PlantDetailOverview } from '../components/PlantDetailsOverview';
import { Grid, Paper, Popover, Modal } from '@material-ui/core';
import { mapCssStyle } from '../components/Map.css';
import { notificationTextChange, setBadge, useStyles, confirmButton, notificationAlertTextChange } from '../components/CampaignDetails.css';
import Icon from '../components/IconsComponent';
import { customMarker } from '../components/CustomMarker';
import useSupercluster from 'use-supercluster';
import _ from 'lodash';
import moment from 'moment';
import { NotificationContainer } from '../components/notification-layer/NotificationContainer'

const geolib = require('geolib');
const Marker = ({ children }: any) => children;

const OrderedPlants = (props: any) => {
  const { orderPlants } = props
  return (
    <div className="d-sm-flex flex-column align-items-center justify-content-between p-3">
      <span style={{ fontSize: 20 }}>Impianti prenotati</span>
      <b style={{ fontSize: 30 }}>{orderPlants}</b>
    </div>
  )
}
const MediaOwners = (props: any) => {
  const { orderPlants } = props
  return (
    <div className="d-sm-flex flex-column align-items-center justify-content-between p-3">
      <span style={{ fontSize: 20 }}>Concessionarie</span>
      <b style={{ fontSize: 30 }}>{orderPlants}</b>
    </div>
  )
}

export const CampaignDetails = (props: any) => {
  // console.log('in orderDetails with:', props)
  const dispatch = useDispatch()
  const classes = useStyles()

  const [allBillboards, setAllBillboards] = useState([])
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorEl2, setAnchorEl2] = useState<HTMLElement | null>(null);
  const [plantProps, setPlantProps] = useState({})
  const [popoverType, setPopoverType] = useState("")
  let punti = [{ lat: 39.907780323055086, lng: 9.237492267012982 }, { lat: 39.437432624875754, lng: 8.53038607583149 }, { lat: 39.34972998992784, lng: 9.499383448932052 }]

  let campaignOrders = useSelector(ContentSelectors.getCampaignOrders)
  let campaign = useSelector(ContentSelectors.getSelectedCampaign)
  const switchModalOpen = useSelector(UiSelectors.switchDraftCampainModal)
  let actionDone = useSelector(UiSelectors.getActionDone)

  const [notification, setNotification] = useState(notificationAlertTextChange(setBadge(campaign.order_date, campaign.status, 50, allBillboards.length, campaign.from, campaign.to).notification))

  const objectPlants = _.keyBy(allBillboards, 'id');

  let paymentEnding = moment().to(moment(campaign.order_date).add(30, 'minutes'))
  let ownersArray = [] as any
  let obj2 = {} as any

  _.forEach(allBillboards, (value: any) => {
    if (obj2[value.media_owner]) {
      obj2[value.media_owner]++
    } else {
      obj2[value.media_owner] = 1
    }
  })
  _.forOwn(obj2, (value: any, key: any) => {
    ownersArray.push({ media_owner: key, plants: value })
  })

  useEffect(() => {
    dispatch(UiActions.setSelectedPage("Campagne"))

    let array = [] as any
    _.map(campaignOrders, (o: any) => {
      // console.log("campaignOrders MAP", o)
      _.map(o.billboards, (i: any) => {
        // console.log("campaignOrders MAP2", i)
        array.push(i)
      })
    })
    setAllBillboards(array)
    console.log("campaign payment", paymentEnding)
  }, [campaignOrders, paymentEnding])

  let bounds1 = geolib.getBounds(punti);
  let size = {
    width: 1200,
    height: 600
  }
  if (document.getElementById("mappa")) {
    size = {
      width: document.getElementById("mappa")!.offsetWidth,
      height: document.getElementById("mappa")!.offsetHeight,
    }
  }
  bounds1 = {
    ne: {
      lat: bounds1.maxLat,
      lng: bounds1.maxLng
    },
    sw: {
      lat: bounds1.minLat,
      lng: bounds1.minLng
    }
  };
  const [bounds, setBound] = useState([] as any)
  const [zoom1, setZoom] = useState(10 as any)

  let points: any = allBillboards.map((bill: any) => ({
    type: "Feature",
    properties: {
      cluster: false,
      "cluster_id": bill.id,
      "point_count": bill.id
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(bill.longitude),
        parseFloat(bill.latitude)
      ]
    }
  }));
  const mapRef = useRef();
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: zoom1,
    options: { radius: 75, maxZoom: 20 }
  });
  const { center, zoom } = fitBounds(bounds1, size);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const open2 = Boolean(anchorEl2);
  const id2 = open ? 'simple-popover' : undefined;

  return (
    <Grid className="poppinsFont" container>
      {/* <Modal open={switchModalOpen} onClose={() => dispatch(UiActions.switchDraftCampainModal(''))}>
        <h1>CIAO</h1>
      </Modal> */}
      <NotificationContainer />
      {notification.length > 0 ? <div style={{ zIndex: 3, right: "33%", top: "40%" }} className="d-sm-flex p-2 position-absolute">
        <Paper className={classes.paperNotification} elevation={3}>
          <Grid container item xs={12} className="d-sm-flex align-items-center justify-content-center p-2">
            <Grid className="d-sm-flex justify-content-center p-2 mb-4" item xs={12} >{notification}</Grid>
            <Grid className="d-sm-flex justify-content-center p-2" item xs={12} >
              <Grid className="d-sm-flex justify-content-center" item xs={6} >
                <button style={confirmButton} onClick={(e: any) => {
                  setPopoverType("mediaOwners");
                  setNotification("");
                  setAnchorEl(document.getElementById("mediaOwners") as any);
                }}>Visualizza lista</button>
              </Grid>
              <Grid className="d-sm-flex justify-content-center" item xs={6} >
                <button style={confirmButton} onClick={() => setNotification("")}>Chiudi notifica</button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div> : null}
      <Grid style={{ height: '6vh' }} className="d-sm-flex align-items-center bg-white border-bottom" item xs={12}>
        <button className="bg-transparent border-0" onClick={() => {
          dispatch(UiActions.goTo("/campaign"));
          // dispatch(ContentActions.didSetSelectedCampaign({}))
          // dispatch(ContentActions.didListCampaignOrders({campaignOrders: []}))
        }}><Icon color="gray" size={35} icon="burger" /> </button>
        <span className="ml-3" >{campaign.name}</span>
      </Grid>
      <Paper style={{ zIndex: 3, right: 35, borderRadius: 14, cursor: "pointer" }}
        id="mediaOwners"
        className="d-sm-flex position-absolute bg-white mt-3"
        onClick={(e: any) => { setPopoverType("mediaOwners"); setAnchorEl(e.currentTarget); setNotification("") }}>
        <MediaOwners orderPlants={_.size(ownersArray)} />
      </Paper>
      <Paper style={{ zIndex: 3, right: 270, borderRadius: 14, cursor: "pointer" }}
        className="d-sm-flex position-absolute bg-white mt-3"
        onClick={(e: any) => { setPopoverType("billboards"); setAnchorEl(e.currentTarget) }}>
        <OrderedPlants orderPlants={_.size(allBillboards)} />
      </Paper>

      {campaign.status === "Draft" && !(actionDone.done && actionDone.action === "campagna pagata")
        ?
        <Paper style={{ zIndex: 3, right: "37%", borderRadius: 14 }} className="d-sm-flex flex-column position-absolute bg-white mt-3 p-2">
          <span style={{ textAlign: "center" }}>La campagna non sarà<br /> più disponibile {paymentEnding}</span>
          <br />
          <span style={{ textAlign: "center", opacity: 0.5, fontSize: 12 }}>{'(in caso la campagna fosse già stata pagata,'}</span>
          <span style={{ textAlign: "center", opacity: 0.5, fontSize: 12 }}>{'il messaggio scomparirà appena lo stato cambierà)'}</span>
          <button className="mt-2" style={confirmButton} onClick={(e: any) => {
            // dispatch(ContentActions.didSetSelectedPlants(allBillboards))
            dispatch(ContentActions.willStartPayment({ selectedPlants: allBillboards, campaignID: "", filters: campaign.filters, campaign: [] }))
            dispatch(UiActions.didSetCampaignStep("Payment"))
            dispatch(UiActions.goTo("/planning"))
            // dispatch(ContentActions.willGetCampaignOrders({ campaign_id: campaign.id, orders: campaign.orders }))
            // dispatch(ContentActions.didSetSelectedCampaign(campaign)
          }}>Vai al Pagamento</button>
        </Paper>
        : null}

      <Grid id="rowInMap" style={{ overflow: "auto", height: 'calc(94vh - 83px)', backgroundColor: "#fafafb" }} container item xs={3}>
        <CampaignDetailsLeft allBillboards={allBillboards} />
      </Grid>
      <Grid style={{ height: 'auto' }} id='mappa' item xs={9}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyAMhpfSL4SkLCu2kSu-8oXXfTRtxddzYcE",
            libraries: ['places', 'geometry', 'drawing', 'visualization']
          }}
          defaultCenter={center}
          center={center}
          defaultZoom={zoom}
          options={{ fullscreenControl: false, styles: mapCssStyle }}
          zoom={zoom}
          layerTypes={['TrafficLayer', 'TransitLayer']}
          onChange={({ zoom, bounds }) => {
            // setTimeout(function () { onChangeMarginBounds(bounds) }, 500)
            setZoom(zoom);
            setBound([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat
            ]);
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {

            map.streetViewControl = true;
            map.mapTypeControl = true;
          }}
        // onChange={({ bounds, marginBounds }) => {
        //     setTimeout(function () { onChangeMarginBounds(bounds) }, 500)
        // }}
        >
          {clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount
            } = cluster.properties;
            // console.log("in render:", pointCount, isCluster, cluster)
            if (isCluster) {
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}
                >
                  <div
                    className="super-cluster"
                    style={{
                      width: 20 + (pointCount / points.length) * 30,
                      height: 20 + (pointCount / points.length) * 30,
                    }}
                    onClick={() => { }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }
            return (
              <Marker
                key={`crime-${cluster.properties.cluster_id}`}
                lat={latitude}
                lng={longitude}
              >
                <div
                  id={`crime-${cluster.properties.cluster_id}`}
                  // className="cluster-marker"
                  style={{ cursor: 'pointer', position: 'absolute', bottom: -20, right: -20 }}
                  onClick={() => {
                    setPlantProps(objectPlants[cluster.properties.cluster_id]);
                    // console.log("document.getElementById", document.getElementById("rowInMap"))
                    setAnchorEl2(document.getElementById("rowInMap"));
                  }}
                >{customMarker(objectPlants[cluster.properties.cluster_id], 40)}</div>
              </Marker>
            );
          })}
        </GoogleMapReact>
      </Grid>
      <Grid>
        <Popover
          className="mt-2"
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => { setAnchorEl(null); setPopoverType(""); }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }} >
          {popoverType === "mediaOwners" && <MediaOwnersList setAnchorEl={setAnchorEl} ownersArray={ownersArray} campaignOrders={campaignOrders} />}
          {popoverType === "billboards" && <CampaignDetailsRight allBillboards={allBillboards} />}
        </Popover>
        <Popover
          className={classes.popoverContainer}
          id={id2}
          open={open2}
          anchorEl={anchorEl2}
          //  anchorReference="anchorPosition"
          //  anchorPosition={{ top: 100, left: 560 }}
          onClose={() => { setAnchorEl2(null); setPlantProps({}) }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
          transformOrigin={{ vertical: 'top', horizontal: 'left', }}
        >
          <PlantDetailOverview items={plantProps} />
        </Popover>
      </Grid>
    </Grid>
  )
}