import { call, put, takeEvery, takeLatest, delay } from 'redux-saga/effects'

import { actions as ProfileActions } from '../slices/profile'
import { actions as UiActions } from '../slices/ui'
import { actions as NotificationActions } from '../slices/notification'
import * as AuthApi from '../../api/auth'
import { push } from 'connected-react-router'

export function* sagas() {
  console.log('inside ui saga')
  yield takeLatest(UiActions.switchPricing.type, switchPricing)
  yield takeLatest(UiActions.goTo.type, goTo)

}

function* switchPricing(action: any) {
  console.log('in switchPricing saga');
  
  yield put(push("/pricing"))

}
function* goTo(action: any) {
  console.log('in goTo saga', action.payload);
  yield put(push(action.payload))
}