import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Grid } from '@material-ui/core';
import GoogleMapReact, { fitBounds } from 'google-map-react';
import moment from 'moment';
import 'moment/locale/it';
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { actions as NotificationActions } from '../store/slices/notification';
import { selectors as UiSelectors } from '../store/slices/ui';
import { useStyles } from './campaign/Campain.css';
import { mapCssStyle } from '../components/Map.css';
import { customMarker } from './CustomMarker';
// import { getBoundingBox } from 'geolocation-utils'
// import { AddNewPlant } from "./AddNewPlant";
// import Supercluster from 'supercluster';
import useSupercluster from 'use-supercluster'
import _ from 'lodash';

const Marker = ({ children }: any) => children;
const geolib = require('geolib');

const currencyFormatter = (value: number) => {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
}

export const Map = (props: any) => {
  // console.log("in Map with:", props)
  const { setAnchorEl, setPlantProps, hideFoundedBillboards } = props
  const dispatch = useDispatch()
  const classes = useStyles()

  const [bounds, setBound] = useState([] as any)
  const [geocoder, setGeocoder] = useState() as any
  const [zoom, setZoom] = useState(10 as any)
  const [center, setCenter] = useState() as any

  let campaignStep = useSelector(UiSelectors.getCampaignstep)
  let myBillboardsInMap = useSelector(ContentSelectors.listBillboards)
  let selectedBillboards = useSelector(ContentSelectors.getSelectedBillboards)
  let plantsSelected = useSelector(ContentSelectors.getSelectedPlants)
  let coordinates = useSelector(ContentSelectors.listBillboardsCoordinates)
  let coordinatesFromStore: any

  const objectPlants = _.keyBy(myBillboardsInMap, 'id');
  let bounds1: any

  let size = {
    width: 1200,
    height: 600
  }

  if (document.getElementById("mappa")) {
    size = {
      width: document.getElementById("mappa")!.offsetWidth,
      height: document.getElementById("mappa")!.offsetHeight,
    }
  }

  useEffect(() => {
    if (_.isEmpty(coordinates)) {
      coordinatesFromStore = [{ lat: 39, lng: 9 }, { lat: 39.4, lng: 9.09 }]
    } else {
      coordinatesFromStore = coordinates
    }

    bounds1 = geolib.getBounds(coordinatesFromStore);

    bounds1 = {
      ne: {
        lat: bounds1.maxLat,
        lng: bounds1.maxLng
      },
      sw: {
        lat: bounds1.minLat,
        lng: bounds1.minLng
      }
    };

    const tmpBounds = fitBounds(bounds1, size);
    setBound(tmpBounds)
    setZoom(tmpBounds.zoom)
    setCenter(tmpBounds.center)

  }, [coordinatesFromStore, coordinates])

  let points = myBillboardsInMap.map((bill: any) => ({
    type: "Feature",
    properties: {
      cluster: false,
      "cluster_id": bill.id,
      "point_count": bill.id,
      "is_selected": bill.id in selectedBillboards //controllo se l impianto e presente tra gli impianti selezionati
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(bill.longitude),
        parseFloat(bill.latitude)
      ]
    }
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: zoom,
    options: { radius: 75, maxZoom: 20 }
  });
  // console.log("clusters", clusters, bounds)

  useEffect(() => {
    console.log("selectedBillboards", selectedBillboards, _.size(selectedBillboards))
  }, [selectedBillboards, plantsSelected])

  const initialize = (maps: any) => {
    // console.log("initialize maps", maps)
    setGeocoder(maps)
  }

  useEffect(() => {
    console.log("geocoder useEffect", geocoder)
  }, [geocoder])

  const codeAddress = (address: string) => {
    const geocoderFunction = new geocoder.Geocoder()

    geocoderFunction.geocode({ 'address': address }, (results: any, status: any) => {
      console.log("codeAddress function results", results)
      if (status === 'OK') {
        if (results[0].geometry.bounds) {
          const newBounds = results[0].geometry.bounds
          // console.log("codeAddress function bounds", results[0].geometry.bounds)
          // const newBounds = results[0].geometry.location
          // console.log("codeAddress function location", results[0].geometry.location)

          // dispatch(ContentActions.willUploadFilter({ key: "interest", value: newBounds }))

          coordinatesFromStore = [
            {
              lat: newBounds.Ab.j,
              lng: newBounds.Va.j
            },
            {
              lat: newBounds.Ab.h,
              lng: newBounds.Va.h
            }
          ]

          let bounds2 = geolib.getBounds([
            {
              lat: newBounds.Ab.j,
              lng: newBounds.Va.j
            },
            {
              lat: newBounds.Ab.h,
              lng: newBounds.Va.h
            }
          ]);

          bounds2 = {
            ne: {
              lat: bounds2.maxLat,
              lng: bounds2.maxLng
            },
            sw: {
              lat: bounds2.minLat,
              lng: bounds2.minLng
            }
          };
          // console.log("codeAddress bounds2", newBounds)

          const tmpBounds = fitBounds(bounds2, size);
          setZoom(tmpBounds.zoom)
          setCenter(tmpBounds.center)
          // console.log("coordinates in function", tmpBounds)
        } else {
          dispatch(NotificationActions.willShowNotification({ message: 'Indirizzo non tovato o troppo specifico', type: "danger" }));
        }
      } else {
        dispatch(NotificationActions.willShowNotification({ message: 'Errore di Google Maps: ' + status, type: "danger" }));
      }
    });
  }

  return (
    <>
      <div style={{ zIndex: 4, left: 0 }} className="row p-2 position-absolute mr-5">
        {campaignStep !== "Pianificazione guidata" && 
          <Grid className={classes.foundedPlantsWidgetSelectedBillboards} container>
            <Grid container item xs={7} alignItems="center" justify="center">{campaignStep === "Pianifica campagna" ? "Impianti selezionati" : "Impianti trovati"}</Grid>
            <Grid container item xs={5} alignItems="center" justify="center"><b>{campaignStep === "Pianifica campagna" ? `+ ${_.size(selectedBillboards)}` : points.length}</b></Grid>
          </Grid>}
        {campaignStep === "Pianifica campagna" || campaignStep === "Conferma"
          ? <Grid className={classes.foundedPlantsWidgetTotalPrize} container>
            <Grid container item xs={6} alignItems="center" justify="center">Spesa prevista</Grid>
            <Grid container item xs={6} alignItems="center" justify="flex-end"><b className="mr-2">{currencyFormatter(_.sum(_.values(selectedBillboards)))}</b></Grid>
          </Grid>
          : null}
      </div>
      <div id='mappa' style={{ height: '100%', width: '100%', position: 'relative' }}>
        {/* <div style={{ zIndex: 10, right: step !== "Pianificazione guidata" ? '50%' : '10%', marginTop: 10 }} className="d-sm-flex position-absolute">
          <input value={address} onChange={(e: any) => { setAddress(e.target.value) }} />
          <button data-cy="addressFilter" style={confirmButton} onClick={() => { { codeAddress(address) } }}>Cerca</button>
          <p></p>
        </div> */}
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyAMhpfSL4SkLCu2kSu-8oXXfTRtxddzYcE",
            libraries: ['places', 'geometry', 'drawing', 'visualization']
          }}
          defaultCenter={center}
          center={center}
          defaultZoom={zoom}
          options={{ fullscreenControl: false, styles: mapCssStyle }}
          layerTypes={['TrafficLayer', 'TransitLayer']}
          zoom={zoom}
          onChange={({ zoom, bounds }) => {
            const tmpBounds = fitBounds(bounds, size);
            setZoom(zoom)
            setCenter(tmpBounds.center)
            setZoom(zoom);
            setBound([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat
            ]);
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            initialize(maps)

            map.streetViewControl = true;
            map.mapTypeControl = true;
          }}
        >
          {clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount
            } = cluster.properties;

            if (isCluster) {
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}
                >
                  <div
                    className="super-cluster"
                    style={{
                      width: 40 + (pointCount / points.length) * 30,
                      height: 40 + (pointCount / points.length) * 30,
                    }}
                    onClick={() => { }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }

            return (
              <Marker
                key={`crime-${cluster.properties.cluster_id}`}
                lat={latitude}
                lng={longitude}
              >
                <div
                  id={`crime-${cluster.properties.cluster_id}`}
                  // className={`cluster-marker-${cluster.properties.is_selected}`}
                  style={{ cursor: 'pointer', position:'absolute', bottom: -20, right: -20 }}
                  onClick={() => {
                    setPlantProps(objectPlants[cluster.properties.cluster_id]);
                    setAnchorEl(document.getElementById("mappa"))
                  }}
                >
                  {customMarker(objectPlants[cluster.properties.cluster_id], 40)}
                </div>
              </Marker>
            );
          })}
        </GoogleMapReact>
      </div>
    </>
  )
}