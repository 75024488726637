import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../../../store/slices/content';
import { Grid } from '@material-ui/core';
import { useStyles } from '../Campain.css';
import Icon from '../../IconsComponent';
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import _ from 'lodash';


export const VisibilityIndex = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  let plantsSelected = useSelector(ContentSelectors.getSelectedPlants)

  let totalMunicipality = [] as any[]
  let totalNormalized = [] as any[]
  let meanVisibilityIndex = [] as any[]

  plantsSelected.map((i: any) => {
    totalMunicipality.push(i.iV_municipality)
  })
  plantsSelected.map((i: any) => {
    totalNormalized.push(i.iV_normalized)
  })
  meanVisibilityIndex.push(_.mean(totalMunicipality), _.mean(totalNormalized))

  return (
    <>
      {/* <Grid className={`${classes.confirmStepBorders} p-2`} item xs={12}>
        <div className="d-sm-flex flex-column align-items-center justify-content-center mt-4">
          <b>Indice visibilità medio </b>
          <b style={{ fontSize: 37, marginTop: 20 }}><b style={{ color: "#1a75ff" }}>{_.ceil(_.mean(meanVisibilityIndex), 2)}</b><b className="text-secondary">/10</b></b>
        </div>
      </Grid> */}
      {/* <Grid className={`${classes.confirmStepBorders}`} item xs={12}> */}
        <Grid data-cy="billaloIndex" className="mt-6 mb-3" container alignItems="center" justify="center">
          <CircularProgressbarWithChildren
            className={classes.visibilityProgress}
            styles={buildStyles({
              pathColor: "#0066ff",
              trailColor: "#f8f9fa"
            })}
            maxValue={10}
            value={_.ceil(_.mean(totalMunicipality), 2)}
            strokeWidth={5} >
            <Icon size={25} icon="goal" />
          </CircularProgressbarWithChildren>
          <div className="d-sm-flex flex-column ml-3 detailText">
            <span><b style={{ fontSize: 30 }}>{_.ceil(_.mean(totalMunicipality), 2)}</b> <br /><span style={{ fontSize: 15 }} className="text-muted">Indice Visibilità <br /> - Comune</span></span>
          </div>
        </Grid>
        <Grid item xs={12}><div className="border-bottom" /></Grid>
        <Grid data-cy="billaloIndex" className="mt-3 mb-3" container alignItems="center" justify="center">
          <CircularProgressbarWithChildren
            className={classes.visibilityProgress}
            styles={buildStyles({
              //textColor: "red",
              pathColor: "#00e68a",
              trailColor: "#f8f9fa"
            })}
            maxValue={10}
            value={_.ceil(_.mean(totalNormalized), 2)}
            strokeWidth={5} >
            <Icon size={25} icon="deals" />
          </CircularProgressbarWithChildren>
          <div className="d-sm-flex flex-column ml-3 detailText">
            <span><b style={{ fontSize: 30 }}>{_.ceil(_.mean(totalNormalized), 2)}</b> <br /><span style={{ fontSize: 15 }} className="text-muted">Indice Visibilità <br /> - Italia</span></span>
          </div>
        </Grid>
      {/* </Grid> */}
    </>
  )
}