import React from 'react';
import { StepConnector } from '@material-ui/core';
import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import styled from "styled-components";
import moment from 'moment';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ///////FILTER PART START///////
    root: {
      width: 300,
      height: "72vh",
      padding: theme.spacing(2),
      //backgroundColor: "#fafafb",
      overflowY: "auto",
      position: 'relative'
    },
    popoverInFilters: {
      margin: 20,
      borderRadius: 10
    },
    buttonBackground: {
      // backgroundColor: "#0062cc",
      justifyContent: "space-evenly",
    },
    filterBody: {
      height: '46vh',
      overflow: 'auto'
    },
    filterBodyPeriod: {
      height: '34vh',
      overflow: 'auto'
    },
    buttonsFilterContainer: {
      position: 'absolute',
      bottom: 30,
      left: 0,
      right: 0
    },
    ///////FILTER PART END///////
    borderColor: {
      '& label.Mui-focused': {
        color: '#00bfff',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#00bfff',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: 10
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00bfff',
        },
      },
    },
    noBorders: {
      border: "none",
    },
    noBordersAll: {
      // width: 180,
      marginLeft: 30,
      borderRadius: 10,
      backgroundColor: "white",
    },
    searchCampaign: {
      marginLeft: 30,
      borderRadius: 10,
      borderColor: "gray",
      borderWidth: 1,
      borderStyle: "solid",
      backgroundColor: "white",
    },
    datePickerText: {
      fontSize: 14
    },
    datePicker: {
      width: 120,
      '& .MuiIconButton-root': {
        padding: "2px !important"
      }
    },
    autosuggest: {
      border: "none"
    },
    inputFocused: {
      outlineStyle: "none"
    },
    absolute: {
      // position: "absolute",
      // zIndex: 4,
      backgroundColor: "white",
      borderTopWidth: 0,
      borderBottomWidth: 0,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      //border: "1px solid #bfbfbf",
      overflow: "auto",
      width: 250,
      maxHeight: 150,
    },
    stepSelected: {
      '& > span > svg': {
        outline: '5px solid rgba(255, 197, 66, 0.1)',
        borderRadius: 20,
        backgroundColor: 'rgba(255, 197, 66, 0.1)',
      },
      '& > span > svg > g': {
        stroke: '#FFC542'
      }
    },
    stepDone: {
      '& > span': {
        cursor: 'pointer'
      },
      '& > span > svg': {
        outline: '5px solid rgba(130, 196, 60, 0.1)',
        borderRadius: 20,
        backgroundColor: 'rgba(130, 196, 60, 0.1)',
      },
      '& > span > svg > g': {
        stroke: '#82C43C'
      }
    },
    stepDisabled: {
      '& > span > svg': {
        outlineOffset: 5,
        outline: '1px solid #979797',
        borderRadius: 20,
        // backgroundColor:'transparent',
      }
    },
    confirmStepBorders: {
      backgroundColor: "white",
      borderRadius: 25,
      marginTop: 15,
      // border: '12px solid #fafafb',
      // border: '1px solid red',
      width: '90%'
    },
    confirmStepBordersBlue: {
      backgroundColor: "#0066ff",
      color: "white",
      fontSize: 15,
      borderRadius: 25,
      marginTop: 15,
      width: '90%',
      // border: '12px solid #fafafb',
      position: 'relative'
    },
    confirmButtonLower: {
      position: 'absolute',
      bottom: 30,
      right: 0,
      left: 0
    },
    visibilityProgress: {
      width: 70,
      height: 70
    },
    percentageProgress: {
      color: 'black',
      fontSize: 22
    },
    tableConfirmStep: {
      minWidth: 650,
      '& MuiPaper-root MuiTableContainer-root MuiPaper-elevation1 MuiPaper-rounded': {
        borderWidth: 0
      },
      '& .MuiTableHead-root': {
        backgroundColor: "#fafafb",
        borderRadius: 14,
      }
    },
    checkboxLegalAcceptance: {
      '&$checked': {
        color: "blue",
      },
    },
    simpleObjective: {
      backgroundColor: "white",
      borderRadius: 14,
      marginBottom: 30,
    },
    tooltip: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "white !important",
      borderWidth: 1,
      borderColor: "#f1f1f5",
      borderStyle: "solid",
      borderRadius: 5,
      padding: 15,
      fontSize: 15
    },
    guidedPlanningSecondStepCard: {
      width: 262,
      height: 300,
      // height: '100%',
      // margin: '1px 57px 0 0',
      padding: '19px 20px 31px',
      borderRadius: '20px',
      backgroundColor: '#44444f',
      color: 'white'
    },
    notFillStep: {
      width: '44px',
      height: 6,
      margin: '0 3px 0 0',
      borderRadius: 5,
      backgroundColor: 'white'
    },
    fillStep: {
      width: '44px',
      height: 6,
      margin: '0 3px 0 0',
      borderRadius: 5,
      backgroundColor: '#50b5ff'
    },
    billaloIndexSlide: {
      color: '#00e68a',
    },
    objectiveContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 20,
      backgroundColor: 'white',
      padding: 20,
      border: '1px solid rgba(0, 0, 0, 0.1)'
    },
    draftModalHeader: {
      padding: '30px 30px 20px 30px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
    },
    campaignInput: {
      '& .MuiOutlinedInput-root': { /// principale
        borderRadius: 10,
        backgroundColor: 'white',
        '& fieldset': {
          // borderColor: 'red',
        },
        '&:hover fieldset': {
          borderColor: '#50B5FF',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#50B5FF',
        },
      }
    },
    chipContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      border: '1px solid #0062FF'
    },
    foundedPlantsWidgetSelectedBillboards: {
      height: 50,
      width: '250px !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'white',

      /// position
      position: 'absolute',
      zIndex: 3,
      left: 50,
      top: 100,
      ///border
      border: '1px solid #3DD598',
      borderRadius: 14,
      /// typography
      fontFamily: "'Poppins', sans-serif",
      fontSize: 14,
    },
    foundedPlantsWidgetTotalPrize: {
      height: 50,
      width: '250px !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'white',

      /// position
      position: 'absolute',
      zIndex: 3,
      left: 50,
      top: 160,
      ///border
      border: '1px solid #50B5FF',
      borderRadius: 14,
      /// typography
      fontFamily: "'Poppins', sans-serif",
      fontSize: 14,
    },
    popoverContainer: {
      width: 340,
      '& .MuiPaper-root.MuiPopover-paper.MuiPaper-rounded': {
        top: 'unset !important',
        bottom: 0
      },
      '& .MuiPaper-root': {
        height: '76.5vh',
        border: '1px solid #0062FF',
        borderRadius: 10
      }
    },
    drowdownMenu: {
      backgroundColor: '#44444F',
      borderRadius: 10,
      '& .dropdown-item': {
        color: 'white !important'
      },
      '& .dropdown-item:hover': {
        backgroundColor: 'transparent',
        color: '#FC5A5A !important',
        '& >svg>path': {
          fill: '#FC5A5A'
        }
      },
      '& .dropdown-item:focus': {
        backgroundColor: 'transparent',
        color: '#FC5A5A !important',
        '& >svg>path': {
          fill: '#FC5A5A'
        }
      },
      '& .dropdown-item>svg>path': {
        fill: 'white'
      }
    }
  }),
);

export const changeOnSelect = (planningType: any, planning: any) => {
  return planningType === planning ? "selectedPlanningType" : ""
}
export const iconColorSelect = (planningType: any, planning: any) => {
  return planningType === planning ? "black" : "#92929D"
}

export const cancelButtonFilters = {
  height: 40,
  fontSize: 15,
  fontFamily: "'Poppins', sans-serif",
  padding: "1px 20px 1px 20px",
  color: "#696974",
  backgroundColor: "transparent",
  border: '1px solid #E2E2EA',
  borderRadius: 10,
}

export const confirmButtonFilters = {
  height: 40,
  fontSize: 15,
  fontFamily: "'Poppins', sans-serif",
  padding: "1px 20px 1px 20px",
  color: "white",
  backgroundColor: "#0062FF",
  border: '1px solid #E2E2EA',
  borderRadius: 10,
}

export const cancelButton = {
  fontSize: 15,
  fontFamily: "'Poppins', sans-serif",
  padding: "1px 20px 1px 20px",
  color: "#595959",
  backgroundColor: "#e6e6e6",
  borderWidth: 0,
  borderRadius: 10,
  borderStyle: "solid",
  borderColor: "white",
}

export const cancelButtonCampaign = {
  height: 38,
  fontSize: 14,
  fontFamily: "'Poppins', sans-serif",
  padding: "1px 20px 1px 20px",
  color: "#696974",
  backgroundColor: "transparent",
  border: '1px solid #E2E2EA',
  borderRadius: 10,
}

export const confirmButton = {
  fontSize: 15,
  fontFamily: "'Poppins', sans-serif",
  padding: "1px 20px 1px 20px",
  backgroundColor: "#0062cc",
  borderRadius: 10,
  border: '1px solid #E2E2EA',
  color: "white",
}

export const confirmButtonCampaign = {
  height: 38,
  fontSize: 14,
  fontFamily: "'Poppins', sans-serif",
  padding: "1px 20px 1px 20px",
  backgroundColor: "#0062FF",
  borderRadius: 10,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: "#d9d9d9",
  color: "white",
}
export const buttonApply = {
  fontSize: 13,
  padding: "11px 35px 11px 35px",
  color: "white",
  backgroundColor: "#66b0ff",
  borderWidth: 0,
  borderRadius: 10,
  borderStyle: "solid",
  borderColor: "white",
  // width:'100%'
}

export const draftButtons = {
  fontSize: 15,
  fontFamily: "'Poppins', sans-serif",
  padding: "6px 15px 6px 15px",
  color: "#595959",
  backgroundColor: "#e6e6e6",
  borderWidth: 0,
  borderRadius: 10,
  borderStyle: "solid",
  borderColor: "white",
}
export const draftButtonsSelected = {
  fontSize: 15,
  fontFamily: "'Poppins', sans-serif",
  padding: "6px 15px 6px 15px",
  color: "white",
  backgroundColor: "#0062cc",
  borderWidth: 0,
  borderRadius: 10,
  borderStyle: "solid",
  borderColor: "white",
}

export const selectedCampain = {
  color: "#0062cc",
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  borderBottomColor: "#0062cc",
}

export const goToPaymentButton = {
  fontSize: 18,
  fontFamily: "'Poppins', sans-serif",
  padding: "12px 60px 12px 60px",
  color: "#0066ff",
  backgroundColor: "#fafafb",
  borderWidth: 0,
  borderRadius: 10,
  borderStyle: "solid",
  borderColor: "white",
}

export const municipalityGraphic = {
  width: "80%",
  height: 320,
}

export const headerTableConcessionarie = {
  backgroundColor: "#fafafb",
  borderRadius: 10,
  height: 40,
  alignItems: "center",
  paddingLeft: 15
}

export const legalAcceptanceButton = {
  backgroundColor: "transparent",
  borderWidth: 0,
}

export const rowTableConcessionarie = {
  minHeight: 150,
  maxHeight: 300,
  paddingLeft: 15,
}

export const acceptLegalAcceptance = {
  fontSize: 18,
  fontFamily: "'Poppins', sans-serif",
  padding: "12px 60px 12px 60px",
  color: "white",
  backgroundColor: "#66b0ff",
  borderWidth: 0,
  borderRadius: 10,
  borderStyle: "solid",
  borderColor: "white",
  width: "90%",
  alignItems: "center"
}

export const imageSimpleObjective = {
  width: "100%",
  height: "90%",
  // borderWidth: 1,
  // borderRadius: 14,
  // borderStyle: "solid",
  // borderColor: "#e6e6e6",
  // padding: 5
}

export const simpleObjectiveButton = {
  fontSize: 15,
  fontFamily: "'Poppins', sans-serif",
  padding: "13px 32px 13px 32px",
  color: "white",
  backgroundColor: "#0066ff",
  // backgroundColor: "#b3b3b3",
  borderWidth: 0,
  borderRadius: 10,
  borderStyle: "solid",
  borderColor: "white",
}

export const advancedPlanningButton = {
  fontSize: 15,
  fontFamily: "'Poppins', sans-serif",
  padding: "13px 32px 13px 32px",
  color: "#0066ff",
  backgroundColor: "#f1f1f5",
  borderWidth: 0,
  borderRadius: 10,
  borderStyle: "solid",
  borderColor: "white",
  marginTop: 20
}

export const goToAdvancedPlannyng = {
  fontSize: 15,
  fontFamily: "'Poppins', sans-serif",
  padding: "12px 30px 12px 30px",
  width: "100%",
  color: "#0066ff",
  backgroundColor: "#fafafb",
  borderWidth: 0,
  borderRadius: 10,
  borderStyle: "solid",
  borderColor: "white",
}

export const goToDetailConfirmPayment = {
  fontSize: 18,
  fontFamily: "'Poppins', sans-serif",
  padding: "12px 40px 12px 40px",
  color: "white",
  backgroundColor: "#007bff",
  borderWidth: 0,
  borderRadius: 10,
  borderStyle: "solid",
  borderColor: "white",
  width: "90%",
}

export const goToDashboardConfirmPayment = {
  fontSize: 18,
  fontFamily: "'Poppins', sans-serif",
  padding: "12px 40px 12px 40px",
  color: "white",
  backgroundColor: "#92929D",
  borderWidth: 0,
  borderRadius: 10,
  borderStyle: "solid",
  borderColor: "white",
  width: "90%",
}

export const tooltip = {
  display: "flex",
  alignItems: "center",
  backgroundColor: "white !important",
  borderWidth: 1,
  borderColor: "#f1f1f5",
  borderStyle: "solid",
  borderRadius: 5,
  padding: 15,
  fontSize: 15
}

export const matchButton = {
  fontSize: 15,
  padding: "10px 25px 10px 25px",
  color: "white",
  backgroundColor: "transparent",
  borderRadius: 5,
  border: 'solid 1px #b5b5be',
  marginTop: 20
}

export const matchSelectedButton = {
  fontSize: 15,
  padding: "10px 25px 10px 25px",
  color: "black",
  backgroundColor: "#f1f1f5",
  borderRadius: 5,
  border: 'solid 1px black',
  marginTop: 20
}

export const Styles = styled.div`
padding: 0.2rem;

.table {
  align-items: center;

  .thead {
    font-family: 'Poppins', sans-serif;
  }

  .tbody {
    height: 65vh;
  }

  .tbodyConfirm {
    height: 49vh;
  }

  .tbodyCampaign {
    height: 60vh;
  }

  .tr-group {
    display: flex;
    flex-direction: column;
    // border: 1px solid #00e68a;
    // border-radius: 10px;
  }

  .tr {
    display: flex;
    :last-child {
      .td {
        font-size : 13px;
        border-bottom: 0;
        color: #6c757d;
      }
    }
  }
  .trCampaign {
    display: flex;
    :last-child {
      .td {
        // font-size : 13px;
        border-bottom: 0;
        color: #6c757d;
      }
    }
  }

  .th,
  .td {
    margin: 0;
    padding: 0.5rem;
    color: #6c757d;

    :last-child {
      border-right: 0;
    }
  }

}
`;

export const PaymentStyles = styled.div`
* {
  box-sizing: border-box;
}

body,
html {
  background-color: #f6f9fc;
  font-size: 18px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

h1 {
  color: #32325d;
  font-weight: 400;
  line-height: 50px;
  font-size: 40px;
  margin: 20px 0;
  padding: 0;
}

.Checkout {
  margin-top: 100px;
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 5px;
}

label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}

button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 60px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  color: white;
  background-color: #007bff;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  outline: 0;
  border-radius: 10px;
  background: white;
  width: 800px;
  border: 1px solid #00bfff;
}

input::placeholder {
  color: #aab7c4;
}

input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}
`;

export const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const createOptions = (fontSize: any) => {
  return {
    hidePostalCode: true,
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

export const orderStatusSwitch = (status: string, campaignStart: any, campaignEnd: any) => {

  switch (status) {
    case "Draft":
      return "Draft"
      break;
    case "rejected":
      return "Rifiutato"
      break;
    case "reject":
      return "Rifiutato"
      break;
    case "accepted":
      return "Confermato"
      break;
    case "scheduled":
      return "Programmato"
      break;
    case "confirmed":
      return "Confermato"
      break;
    case "CONFIRMED":
      return "Confermato"
      break;
    case "paid":
      return "Pagato"
      break;
    case "confirm":
      return "Confermato"
      break;
    // case "payed":
    //   return "Programmato"
    //   break;
    case "delivered_materials": {
      let now = moment()
      if (moment(now).isBetween(moment(campaignStart), moment(campaignStart).subtract(7, 'days'))) {
        return "Prossima Uscita"
      } else {
        return "Materiali Consegnati"
      }
    }
      break;
    case "posted": {
      let now = moment()
      if (moment(now).isBetween(moment(campaignStart), moment(campaignStart).add(3, 'days'))) {
        return "In corso"
      } else {
        return "In Uscita"
      }
    }
      break;
    case "completed":
      return "Completato"
      break;
    case "canceled":
      return "Cancellato"
      break;
    case "pass":
      return "Errore"
      break;
  }
}

export const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: 'rgba(238, 245, 232, 1)',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: 'rgba(238, 245, 232, 1)',
    }
  },
  line: {
    height: 4,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);


export const randomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}