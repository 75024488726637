import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content'
import { useStyles, cancelButtonFilters, confirmButtonFilters } from '../campaign/Campain.css';
import { Grid, TextField, InputAdornment } from '@material-ui/core';
import Icon from '../IconsComponent';


export const PlantPrice = (props: any) => {
  const { setAnchor, setPlanningType } = props
  let filters = useSelector(ContentSelectors.didGetFilter)
  const classes = useStyles();
  const dispatch = useDispatch()
  //const [startDate, setStartDate] = useState(null)
  const [priceFrom, setPriceFrom] = useState(filters.price_from ? filters.price_from : "")
  //const [endDate, setEndDate] = useState(null)
  const [priceTo, setPriceTo] = useState(filters.price_to ? filters.price_to : "")

  const applyFunction = () => {
    dispatch(ContentActions.willUploadFilter({ key: "price", value: { from: priceFrom, to: priceTo } }))
    dispatch(ContentActions.willFilterBillboards({ filters: {}, is_confirm: false }))
    setAnchor(null)
    setPlanningType("")
  }

  // useEffect(() => {
  //   if(period){
  //     setStartDate(period.from)
  //     setEndDate(period.to)
  //   }
  // },[period])

  return (
    <div className={`${classes.root} poppinsFont`}>
      <Grid container spacing={3}>
        <Grid item xs={12}><b>Prezzo Impianti</b></Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid className="mb-2" item xs={12}><span className="text-secondary detailText">INSERISCI IMPORTO</span></Grid>
          <Grid container item xs={12} spacing={2}>
            {/* <Grid className="mb-2" item xs={12}><span className="text-secondary detailText">INSERISCI IMPORTO</span></Grid> */}
            <Grid item xs={12}>
              <TextField
                id="pricerFrom"
                data-cy="pricerFrom"
                type="number"
                fullWidth
                value={priceFrom}
                onChange={(e: any) => setPriceFrom(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon className="mr-2" size={20} icon="deals" /> €
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}><b>FINO A</b></Grid>
            <Grid item xs={12}>
              <TextField
                id="priceTo"
                data-cy="priceTo"
                type="number"
                fullWidth
                value={priceTo}
                onChange={(e: any) => setPriceTo(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon className="mr-2" size={20} icon="deals" /> €
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid className={classes.buttonsFilterContainer} container>
            <Grid container item xs={6} alignItems="center" justify="center">
              <button
                style={cancelButtonFilters}
                data-cy="goBack"
                onClick={() => { setAnchor(null); setPlanningType("") }}
              >Annulla</button>
            </Grid>
            <Grid container item xs={6} alignItems="center" justify="center">
              <button data-cy="apply" style={confirmButtonFilters} onClick={applyFunction}>Applica</button>
            </Grid>
          </Grid>
          </Grid>
        </Grid>
    </div>
  )
}