import { createSlice, PayloadAction, createDraftSafeSelector, createSelector } from "@reduxjs/toolkit";

export const currentSlice = createSlice({
  name: 'ui',
  initialState: {
    activitiesRunning: {} as any,
    pricing: false,
    pricingProps: {} as any,
    draftCampainModal: false as boolean,
    draftChoice: "",
    selectedPage: "", //setta la pagina corrente
    activitiesRunningGeneric: false,
    noGroup: false,
    notificationsPage: false,
    noProfile: false,
    actionDone: { done: null, action: "" } as any,
    campaignStep: "",
    openModal: false as boolean
  },
  reducers: {
    startActivityRunning: (state, action: PayloadAction<string>) => void (state.activitiesRunning[action.payload] = true),
    stopActivityRunning: (state, action: PayloadAction<string>) => void (delete state.activitiesRunning[action.payload]),
    switchPricing: (state, action: PayloadAction<any>) => { state.pricingProps = action.payload.items },
    switchDraftCampainModal: (state, action: PayloadAction<any>) => { state.draftCampainModal = !state.draftCampainModal },
    didSetCityFirst: (state, action: PayloadAction<any>) => { state.draftChoice = "discard" },
    setSelectedPage: (state, action: PayloadAction<any>) => { state.selectedPage = action.payload },
    goTo: (state, action: PayloadAction<any>) => state,
    setActivityRunning: (state, action: PayloadAction<any>) => {
      console.log("in setActivityRunning")
      state.activitiesRunningGeneric = action.payload
    },
    //switchClosePlanningType: (state, action: PayloadAction<string>) => {state.planningType = state.planningType},
    didSetNoGroup: (state, action: PayloadAction<any>) => { state.noGroup = action.payload },
    didSetNotificationsPage: (state, action: PayloadAction<any>) => { state.notificationsPage = action.payload },
    didSetNoProfile: (state, action: PayloadAction<any>) => { state.noProfile = action.payload },
    didSetActionDone: (state, action: PayloadAction<any>) => { state.actionDone = action.payload },
    didSetCampaignStep: (state, action: PayloadAction<any>) => { state.campaignStep = action.payload },
    switchOpenModal: (state, action: PayloadAction<any>) => { state.openModal = action.payload },
  }
})

// Extract the action creators object and the reducer
export const { actions, reducer } = currentSlice
export const {
  startActivityRunning,
  stopActivityRunning,
  switchPricing,
  switchDraftCampainModal,
  didSetCityFirst,
  setSelectedPage,
  goTo,
  didSetActionDone,
  setActivityRunning,
  didSetNoGroup,
  didSetNotificationsPage,
  didSetNoProfile,
  didSetCampaignStep,
  switchOpenModal
} = actions

const activitiesRunningSelector = (state: any) => state.ui.activitiesRunning;
export const selectors = {
  activityRunningSelector: createDraftSafeSelector(
    (state: any) => state.ui.activitiesRunning,
    (_: any, currentActivity: string) => currentActivity,
    (activitiesRunning: any, currentActivity: any) => activitiesRunning[currentActivity] != undefined ? activitiesRunning[currentActivity] : false
  ),
  switchPricing: (state: any) => state.ui.pricingProps,
  switchDraftCampainModal: (state: any) => state.ui.draftCampainModal,
  getDraftChoice: (state: any) => state.ui.draftChoice,
  getSelectedPage: (state: any) => state.ui.selectedPage,
  getActivitiesRunningGeneric: (state: any) => state.ui.activitiesRunningGeneric,
  getNoGroup: (state: any) => state.ui.noGroup,
  getNotificationsPage: (state: any) => state.ui.notificationsPage,
  getNoProfile: (state: any) => state.ui.noProfile,
  getActionDone: (state: any) => state.ui.actionDone,
  getCampaignstep: (state: any) => state.ui.campaignStep,
  getOpenModal: (state: any) => state.ui.openModal
}