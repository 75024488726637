import React, { useState, useMemo, useCallback, useEffect, forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTable, useSortBy, useFlexLayout, useExpanded, useRowSelect, useGroupBy } from "react-table";
import { VariableSizeList, FixedSizeList } from "react-window";
import { Popover, Tooltip, Zoom } from '@material-ui/core';
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from 'react-window-infinite-loader';
import ReactTooltip from "react-tooltip";
import debounce from "lodash/debounce";
import { actions as ContentActions, selectors as ContentSelectors } from '../../../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../../../store/slices/ui';
import { Styles } from "../../CampaignDetails.css";
import { useStyles, cancelButton } from '../Campain.css';
import { customBillboardIcon } from '../../CustomBillboardIcon';
import Icon from '../../IconsComponent';
import { PlantDetailOverview } from "../../PlantDetailsOverview";
import _ from 'lodash';

const iconSize = 18
const CustomTooltip = ({ icon, titleText, bodyText }: any) => {
  return (
    <Tooltip style={{ marginLeft: 10 }} title={
      <div className='d-sm-flex flex-column align-itemns-center'>
        <div className='d-sm-flex align-itemns-center'>
          {icon}
          <span style={{ color: 'black' }}>{titleText}</span>
        </div>
        <span style={{ fontSize: 12, color: '#92929D' }}>
          {bodyText}
        </span>
      </div>
    } TransitionComponent={Zoom}>
      <span>
        {icon}
      </span>
    </Tooltip>
  )
}

const rebuildTooltip = debounce(() => ReactTooltip.rebuild(), 200, {
  leading: false,
  trailing: true
});

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = useRef() as any
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <div>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </div>
    )
  }
)

export const CampaignDetailsRight = (props: any) => {
  const { allBillboards } = props

  const classes = useStyles()
  const listRef = useRef(null);

  const [orderColumn, setOrderColumn] = useState([] as any)
  const [actionOpen, setActionOpen] = useState(false);
  const toggleAction = () => setActionOpen(prevState => !prevState);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [plantProps, setPlantProps] = useState({})
  const [selectedRowOverview, setSelectedRowOverview] = useState("")

  const defaultColumn = useMemo(
    () => ({
      width: 270,
      expanded: false
    }), []
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const columns = useMemo(
    () => [
      {
        Header: "Comune / Indirizzo",
        accessor: (props: any) => {
          const anchorToMap: any = document.getElementById("mappa")

          return (
            <>
              <div className={`${anchorEl !== null ? 'overviewDetailRow' : ''} d-sm-flex align-items-center detailText`} onClick={(event: any) => {
                setAnchorEl(anchorToMap)
                setPlantProps(props)
              }}>
                {props.imageUrl
                  ? <img
                    id={"image-" + props.imageUrl}
                    data-cy="avatar2"
                    src={props.imageUrl}
                    className="mr-3 plantAvatar"
                    loading="lazy"
                    onError={(e: any) => {
                      // console.log("image error. Reloading..");
                      // let imageElement = (document.getElementById("image-" + props.imageUrl) as HTMLImageElement)
                      // imageElement.src = props.imageUrl;
                      setTimeout(() => {
                        let imageElement = document.getElementById("image-" + props.imageUrl) as HTMLImageElement;

                        if (imageElement && imageElement.src) {
                          imageElement.src = props.imageUrl;
                        }
                      }, 500);
                    }}
                  />
                  : <span className='mr-3'>{customBillboardIcon(props.media_type)}</span>
                }
                <div style={{ fontSize: 14 }}>
                  <>{props.address.length < 25 ? props.address : `${props.address.substring(0, 25)}..`}</>
                  <br />
                  {props.municipality}
                  {props.is_draft
                    ? <CustomTooltip
                      icon={<Icon className='mr-1' size={iconSize} color="#FFC542" icon="activity" />}
                      titleText={'Richiesta disponibilità'}
                      bodyText={'Stiamo verificando la disponibilità dello spazio per le date selezionate.Entro 72 ore riceverai l’eventuale conferma'} />
                    : <CustomTooltip
                      icon={<Icon className='mr-1' size={iconSize} color="#00e68a" icon="task_list" />}
                      titleText={'Spazio disponibile'}
                      bodyText={'Lo spazio è disponibile per le date selezionate.'} />
                  }
                  <span style={{ ...cancelButton, fontSize: 11, padding: '2px 4px 2px 4px', borderRadius: 4 }} className="text-muted">{props.base} x {props.height}</span>
                </div>
              </div>
            </>)
        }
      },
      {
        Header: "Tipologia",
        accessor: "media_type"
      },
      {
        Header: "Cimasa",
        accessor: "cod_cimasa"
      },
      {
        Header: "Prezzo",
        accessor: "base"
      },
      {
        Header: "Visibilità",
        accessor: "lighting"
      },
      {
        accessor: "id"
      },
      {
        accessor: "city_code"
      },
      {
        accessor: "billalo_code"
      },
      {
        accessor: "authorization_expiration"
      },
      {
        accessor: "available"
      },
      {
        accessor: "creationDate"
      },
      {
        accessor: "height"
      },
      {
        accessor: "height_ground"
      },
      {
        accessor: "last_maintenance"
      },
      {
        accessor: "latitude"
      },
      {
        accessor: "longitude"
      },
      {
        accessor: "media_owner"
      },
      {
        accessor: "n_authorization"
      },
      {
        accessor: "n_sheet"
      },
      {
        accessor: "orientation"
      },
      {
        accessor: "property_type"
      },
      {
        accessor: "updateDate"
      }
    ], []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // state: { selectedRowIds, expanded },
  } = useTable(
    {
      columns,
      data: allBillboards,
      defaultColumn,
      initialState: {
        sortBy: orderColumn,
        hiddenColumns: ["available", "city_code", "billalo_code", "authorization_expiration", "creationDate", "height", "height_ground", "id", "last_maintenance", "latitude", "longitude", "media_owner", "n_authorization", "n_sheet", "orientation", "property_type", "updateDate", "media_type", "size", "cod_cimasa", "base", "lighting"]
      },
      autoResetSelectedRows: false,
      // autoResetExpanded: false,
      // expandSubRows: false
    } as any,
    useFlexLayout,
    useGroupBy,
    useSortBy,
    useRowSelect,
  )

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);

      return (
        <div className="tr-group" style={style}>
          <div className="tr d-flex align-items-center hoverPointer">
            {row.cells.map((cell: any) => {
              return (
                <div {...cell.getCellProps()} className="td text-dark poppinsFont">
                  {cell.render("Cell")}
                </div>
              );
            })}
          </div>
        </div>
      );
    },
    [prepareRow, rows]
  );

  const fetchMoreData = () => {
    console.log('fetch More data...1')
  };
  const itemData = {
    rows,
    prepareRow,
  }

  return (
    <Styles>
      <div className="d-sm-flex align-items-center allPage">
        <div >
          <div {...getTableProps({ style: { backgroundColor: "white", borderRadius: 15, width: 350, height: 550, overflow: "hidden" } })} className="table">
            <div data-cy="tableBody" className="tbody" {...getTableBodyProps({ style: { margin: 2, overflow: "hidden" } })}>
              <AutoSizer onResize={rebuildTooltip}>
                {({ height, width }) => (
                  <InfiniteLoader
                    isItemLoaded={(index: any) => index < rows.length - 4 ? true : false}
                    itemCount={rows.length}
                    loadMoreItems={fetchMoreData as any}
                  >
                    {({ onItemsRendered, ref }: any) => (
                      <VariableSizeList
                        height={height}
                        itemCount={rows.length}
                        itemData={itemData}
                        // Hardcoded values only :/
                        itemSize={(i) => 80}
                        width={width}
                        onScroll={rebuildTooltip}
                        onItemsRendered={onItemsRendered}
                        ref={listRef}
                      >
                        {RenderRow}
                      </VariableSizeList>
                    )}
                  </InfiniteLoader>
                )}
              </AutoSizer>
            </div>
          </div>
        </div>
        <Popover
          className={classes.popoverContainer}
          id={id}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={() => setAnchorEl(null)}
        >
          <PlantDetailOverview items={plantProps} />
        </Popover>
      </div>
    </Styles>
  )
}