import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Grid } from '@material-ui/core';
import { useStyles } from './campaign/Campain.css';
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import Icon from './IconsComponent';
import { ReactComponent as NotorietàImage } from "../assets/images/business_schedule.svg";
import { ReactComponent as CoperturaImage } from "../assets/images/delivery_services.svg";
import { ReactComponent as InterazioniImage } from "../assets/images/finance.svg";

interface ObjectiveOBJ {
  objective: string;
  image: any;
  description: string;
}

const objectiveList: ObjectiveOBJ[] = [
  {
    objective: 'Notorietà',
    image: <NotorietàImage width={'100%'} height={'90%'} className='mt-1 mb-3' />,
    description: 'Raggiungi quante più persone del tuo target con gli spazi più visibili. Selezioneremo gli impianti a più alta visibilità ed esposti al pubblico scelto.'
  },
  {
    objective: 'Interazioni',
    image: <InterazioniImage width={'100%'} height={'90%'} className='mt-1 mb-3' />,
    description: 'Incentiva il tuo pubblico a compiere azioni online e/o offline. Selezioneremo impianti di grande impatto ed in luoghi ad alto passaggio pedonale.'
  }
]

const ObjectiveContainer = ({ objective, image, description, setPlanningType, setAccettazione }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  
  let campaignData = useSelector(ContentSelectors.getCampaignData)

  return (
    <Grid style={{ cursor: 'pointer' }} className={classes.objectiveContainer} item xs={3} onClick={() => {
      dispatch(UiActions.didSetCampaignStep("Creazione campagna"))
      setPlanningType("Guidata");
      setAccettazione(true);
      dispatch(ContentActions.didSetCampaignData({ campaignName: campaignData.campaignName, budgetAndTarget: { target: objective, budget: campaignData.budgetAndTarget.budget } }));
    }} >
      <Grid item xs={12}>
        {image}
      </Grid>
      <Grid container item xs={12} alignItems="center">
        <Grid container alignItems="center" justify="space-between" item xs={8}><h3>{objective}</h3></Grid>
        <Grid item xs={4}> <Icon className="right" color="#0062FF" size={24} icon="chevronRight" /></Grid>
      </Grid>
      <Grid style={{ color: '#B5B5BE' }} item xs={12}>{description}</Grid>
    </Grid>
  )
}

export const PlanningChoice = (props: any) => {
  const { setAccettazione, setPlanningType } = props
  const dispatch = useDispatch()
  const classes = useStyles()

  return (
    <>
      <Grid className="poppinsFont" style={{ backgroundColor: "#fafafb", height: 'calc(100vh - 85px)' }} container item xs={12} alignItems="center" justify="center">

        <Grid container item xs={9} justify="space-between" >
          <Grid className="mb-7" item xs={12}>
            <h1>Pianifica la tua campagna</h1>
            <br />
            <>Utilizza la pianificazione guidata attraverso gli obiettivi notorietà, copertura, interazioni, oppure utilizza i filtri avanzati.</>
          </Grid>

          {objectiveList.map((objective: any) => {
            return <ObjectiveContainer
              objective={objective.objective}
              image={objective.image}
              description={objective.description}
              setPlanningType={setPlanningType}
              setAccettazione={setAccettazione}
            />
          })}

          <Grid style={{ cursor: 'pointer' }} className={classes.objectiveContainer} item xs={3} onClick={() => {
            dispatch(UiActions.didSetCampaignStep("Seleziona impianti"))
            setPlanningType("Avanzata")
            setAccettazione(true)
          }} >
            <Grid item xs={12}>
              <div className='mt-1 mb-3 newAdvancedPlanningIcon'><Icon color='#FFC542' size={50} icon="add" /></div>
            </Grid>
            <Grid container item xs={12} alignItems="center">
              <Grid container alignItems="center" justify='center' item xs={12}><h3 style={{ textAlign: 'center' }}>Pianificazione avanzata</h3></Grid>
            </Grid>
            <Grid style={{ color: '#B5B5BE', textAlign: 'center' }} item xs={12}>Pianifica in 3 step la tua campagna.</Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}