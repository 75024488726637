import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTable, useFlexLayout } from "react-table";
import { actions as ContentActions, selectors as ContentSelectors } from '../../../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../../../store/slices/ui';
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import debounce from "lodash/debounce";
import ReactTooltip from "react-tooltip";
import { Grid, Modal, Tooltip, Zoom } from '@material-ui/core';
import { useStyles, goToPaymentButton, municipalityGraphic, Styles, headerTableConcessionarie, rowTableConcessionarie, cancelButton } from '../Campain.css';
import Icon from '../../IconsComponent';
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { Chart, PieSeries, Title, Legend, ArgumentAxis, ValueAxis, LineSeries } from '@devexpress/dx-react-chart-material-ui';
import { ConfirmDraft, DraftCampainModal } from '../../DraftCampainModal';
import { ListSelectedPlants } from './ListSelectedPlants';
import { ListMediaOwners } from './ListMediaOwners';
import "react-circular-progressbar/dist/styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment'
import _ from 'lodash';

const currencyFormatter = (value: number) => {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
}

export const Summary = ({ setData, pieChartStats, ownersArray }: any) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  let campainData = useSelector(ContentSelectors.getCampaignData)
  let filters = useSelector(ContentSelectors.didGetFilter)
  let plantsSelected = useSelector(ContentSelectors.getSelectedPlants)
  let discount = useSelector(ContentSelectors.getDiscount)
  let total_price = useSelector(ContentSelectors.getPrezzoTotale)
  const billalo_fee = _.ceil(useSelector(ContentSelectors.getBillaloFee), 2)
  const stampaConsegna = useSelector(ContentSelectors.getStampaConsegna)

  let totalCost = discount < 0 ? _.ceil(total_price + discount + billalo_fee, 2) : _.ceil(total_price + billalo_fee, 2)

  return (
    <>
      <Grid style={{ fontSize: 21 }} className="ml-3 mb-4" item xs={12}>Riepilogo</Grid>
      <Grid style={{ fontSize: 25 }} className="mb-2" item xs={12}>{campainData.campaignName}</Grid>
      <Grid item xs={12}><div className="border-bottom" /></Grid>
      <Grid className="mt-4 mb-4" container item xs={12}>
        <Grid style={{ opacity: 0.6 }} item xs={3}>Data</Grid>
        <Grid className="d-sm-flex flex-row-reverse" item xs={9}>{filters.period && (filters.period.date.to || filters.period.date.from) ? `dal ${filters.period.date.from} al ${filters.period.date.to}` : '-'}</Grid>
      </Grid>
      <Grid item xs={12}><div className="border-bottom" /></Grid>
      {campainData.budgetAndTarget.target && <Grid className="mt-4 mb-4" container item xs={12}>
        <Grid style={{ opacity: 0.6 }} item xs={6}>Obiettivo</Grid>
        <Grid className="d-sm-flex flex-row-reverse" item xs={6}>{campainData.budgetAndTarget.target}</Grid>
      </Grid>}
      <Grid item xs={12}><div className="border-bottom" /></Grid>
      <Grid className="mt-4 mb-4" container item xs={12}>
        <Grid style={{ opacity: 0.6 }} item xs={7}>Spesa Spazi Pubblicitari</Grid>
        <Grid className="d-sm-flex flex-row-reverse" item xs={5}>{currencyFormatter(total_price)}</Grid>
      </Grid>
      <Grid item xs={12}><div className="border-bottom" /></Grid>

      {/* questo è da sistemare appena capisco cosa metterci */}
      {/* <Grid className="mt-4 mb-4" container item xs={12}>
        <Grid style={{ opacity: 0.6 }} item xs={7}>Acconto</Grid>
        <Grid className="d-sm-flex flex-row-reverse" item xs={5}>{currencyFormatter(200)}</Grid>
      </Grid>
      <Grid item xs={12}><div className="border-bottom" /></Grid> */}


      <Grid className="mt-4 mb-4" container item xs={12}>
        <Grid style={{ opacity: 0.6 }} item xs={7}>{'Stampa&Consegna'}</Grid>
        <Grid className="d-sm-flex flex-row-reverse" item xs={5}>
          {stampaConsegna ? <Icon size={25} color="#00e68a" icon="check" /> : <Icon size={25} color="red" icon="close" />}
        </Grid>
      </Grid>
      <Grid item xs={12}><div className="border-bottom" /></Grid>
      <Grid className="mt-4 mb-4" container item xs={12}>
        <Grid style={{ opacity: 0.6 }} item xs={4}>Fee Billalo</Grid>
        <Grid className="d-sm-flex flex-row-reverse" item xs={8}>{currencyFormatter(billalo_fee)}</Grid>
      </Grid>
      <Grid item xs={12}><div className="border-bottom" /></Grid>
      {discount < 0 ? <Grid className="mt-4 mb-4" container item xs={12}>
        <Grid style={{ opacity: 0.6 }} item xs={4}>Sconto</Grid>
        <Grid className="d-sm-flex flex-row-reverse" item xs={8}>{discount < 0 ? currencyFormatter(discount) : 0}</Grid>
      </Grid> : null}
      <Grid item xs={12}><div className="border-bottom" /></Grid>
      <Grid className="mt-4 mb-4" container item xs={12} alignItems="center">
        <Grid style={{ fontSize: 25 }} item xs={4}>Totale</Grid>
        <Grid className="d-sm-flex flex-row-reverse" item xs={8}>{currencyFormatter(totalCost)}</Grid>
      </Grid>
      <Grid className={`${classes.confirmButtonLower} d-sm-flex justify-content-center mt-7`} item xs={12}>
        <button data-cy="goToPayment" style={goToPaymentButton} onClick={() => {
          dispatch(UiActions.didSetCampaignStep("Payment"))
          setData({ totalCost: totalCost, concessionari: _.map(ownersArray, 'media_owner'), campaignData: campainData, plantsSelected: plantsSelected, n_municipality: _.size(pieChartStats) })
          dispatch(ContentActions.willStartPayment({ selectedPlants: plantsSelected, campaignID: "", filters: filters, campaign: [] }))
        }}>Vai al pagamento</button>
      </Grid>
    </>

  )

}