import React, { useState, useMemo, useCallback, useEffect, forwardRef, useRef } from 'react';
import { useTable, useSortBy, useFlexLayout, useFilters, useRowSelect, useGroupBy, useGlobalFilter, useAsyncDebounce } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../../store/slices/ui';
import { useStyles, cancelButton, Styles } from '../campaign/Campain.css';
import { Grid, InputAdornment, TextField, Chip, Popover, Modal, Tooltip, Zoom } from '@material-ui/core';
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import { PlantDetailOverview } from '../PlantDetailsOverview';
import Icon from '../IconsComponent';
import { VariableSizeList, FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from 'react-window-infinite-loader';
import ReactTooltip from "react-tooltip";
import debounce from "lodash/debounce";
import { matchSorter } from 'match-sorter';
import placeholder from '../../assets/images/placeholder.jpg'
import billboard_placeholder from '../../assets/images/billboard_placeholder.svg'
import _ from 'lodash';

const iconSize = 18
const CustomTooltip = ({ icon, titleText, bodyText }: any) => {
  return (
    <Tooltip style={{ marginLeft: 10 }} title={
      <div className='d-sm-flex flex-column align-itemns-center'>
        <div className='d-sm-flex align-itemns-center'>
          {icon}
          <span style={{ color: 'black' }}>{titleText}</span>
        </div>
        <span style={{ fontSize: 12, color: '#92929D' }}>
          {bodyText}
        </span>
      </div>
    } TransitionComponent={Zoom}>
      <span>
        {icon}
      </span>
    </Tooltip>
  )
}

const rebuildTooltip = debounce(() => ReactTooltip.rebuild(), 200, {
  leading: false,
  trailing: true
});

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = useRef() as any
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <div>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </div>
    )
  }
)

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const classes = useStyles()
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <TextField
      className={classes.campaignInput}
      variant="outlined"
      id="searchInput"
      size="small"
      value={value || ""}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon color="gray" icon="search" />
          </InputAdornment>
        )
      }}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Cerca per indirizzo..`}
    />
  )
}

export const CampaignSecondStepBillboardsList = ({ setModalType, setSelectedBillboard }: any) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const listRef = useRef(null);

  const [orderColumn, setOrderColumn] = useState([] as any)
  const [actionOpen, setActionOpen] = useState(false);
  const toggleAction = () => setActionOpen(prevState => !prevState);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [plantProps, setPlantProps] = useState({})
  const [selectedRowOverview, setSelectedRowOverview] = useState("")

  let myBillboardsInMap = useSelector(ContentSelectors.listBillboards)
  let selectedBillboards = useSelector(ContentSelectors.getSelectedBillboards)
  let plantsSelected = useSelector(ContentSelectors.getSelectedPlants)
  let orderedPlants = useSelector(ContentSelectors.getOrderedPlants)
  let campaignData = useSelector(ContentSelectors.getCampaignData)

  const defaultColumn = useMemo(
    () => ({
      //width: 400,
    }),
    []
  );

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row: any) => row.values[id]] })
  }
  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = val => !val

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // useEffect(() => {
  //   if (campaignData.budgetAndTarget.target) {
  //     const totalCost = parseInt(campaignData.budgetAndTarget.budget)
  //     const tenPercent = totalCost * 10 / 100
  //     dispatch(ContentActions.didSortByObjective({ n_uscite: 1, billboards: myBillboardsInMap, budgetAndTarget: { target: campaignData.budgetAndTarget.target, budget: (totalCost + tenPercent) } }))
  //   }
  // }, [campaignData.budgetAndTarget.target])

  const columns = useMemo(
    () => [
      {
        Header: "Indirizzo",
        width: 160,
        accessor: (props: any) => {
          const imageUrl = /* props.imageUrl ? props.imageUrl :  */billboard_placeholder
          const anchorToMap: any = document.getElementById("mappa")
          return (
            <>
              <div className={`${anchorEl !== null ? 'overviewDetailRow' : ''} d-sm-flex align-items-center detailText`} onClick={(event: any) => {
                setAnchorEl(anchorToMap)
                setPlantProps(props)
              }}>
                <img data-cy="avatar2" className="plantAvatar" src={imageUrl} />
                <div style={{ fontSize: 14 }}>
                  <>{props.address.length < 25 ? props.address : `${props.address.substring(0, 25)}..`}</>
                  <br />
                  {props.municipality}
                  {props.is_draft
                    ? <CustomTooltip
                      icon={<Icon className='mr-1' size={iconSize} color="#FFC542" icon="activity" />}
                      titleText={'Richiesta disponibilità'}
                      bodyText={'Stiamo verificando la disponibilità dello spazio per le date selezionate.Entro 72 ore riceverai l’eventuale conferma'} />
                    : <CustomTooltip
                      icon={<Icon className='mr-1' size={iconSize} color="#00e68a" icon="task_list" />}
                      titleText={'Spazio disponibile'}
                      bodyText={'Lo spazio è disponibile per le date selezionate.'} />
                  }
                  <span style={{ ...cancelButton, fontSize: 11, padding: '2px 4px 2px 4px', borderRadius: 4 }} className="text-muted">{props.base} x {props.height}</span>
                </div>
              </div>
            </>)
        }
      },
      {
        id: "icons",
        width: 40,
        accessor: (props: any) => {
          let color = "gray"
          let iconColor = "white"
          let disabledIcon = true
          if (props.id in selectedBillboards) {
            //console.log("ROW PROPS:", props,selectedBillboards, props.id in selectedBillboards)
            color = "#00cc00"
            disabledIcon = false
            iconColor = "gray"
          }
          return (
            <div className="d-sm-flex align-items-center">
              <button data-cy="deletePlant" className={`${props.id in selectedBillboards ? "" : "hoverPointerDisabled"} d-sm-flex flex-row-reverse bg-transparent border-0 p-0`}
                disabled={disabledIcon} onClick={() => {
                  dispatch(ContentActions.didRemoveSelectedBillboard({ id: props.id }))
                  dispatch(ContentActions.didDeletePlant(props))
                }} ><Icon color={iconColor} size={20} icon="trash" /></button>
              <button data-cy="selectPlant" className="bg-transparent border-0 p-0 ml-1"
                onClick={() => {
                  setSelectedBillboard(props)
                  setModalType('reservation')
                  dispatch(ContentActions.didSelectBillboard({ id: props.id, price: props.price }))
                  dispatch(ContentActions.didSetPlant(props))
                  dispatch(UiActions.switchDraftCampainModal(''))
                }} ><Icon color={color} size={25} icon="add_channel_2" /></button>
            </div>
          )
        }
      },
      {
        accessor: "municipality"
      },
      {
        accessor: "address"
      },
      {
        accessor: "media_type"
      },
      {
        accessor: "cod_cimasa"
      },
      {
        accessor: "base"
      },
      {
        accessor: "lighting"
      },
      {
        accessor: "id"
      },
      {
        accessor: "city_code"
      },
      {
        accessor: "billalo_code"
      },
      {
        accessor: "authorization_expiration"
      },
      {
        accessor: "available"
      },
      {
        accessor: "creationDate"
      },
      {
        accessor: "height"
      },
      {
        accessor: "height_ground"
      },
      {
        accessor: "last_maintenance"
      },
      {
        accessor: "latitude"
      },
      {
        accessor: "longitude"
      },
      {
        accessor: "media_owner"
      },
      {
        accessor: "n_authorization"
      },
      {
        accessor: "n_sheet"
      },
      {
        accessor: "orientation"
      },
      {
        accessor: "property_type"
      },
      {
        accessor: "updateDate"
      },
      {
        accessor: "taxes.last_payment"
      },
      {
        accessor: "taxes.municipal_tax"
      },
      {
        accessor: "billboard_class"
      },
      {
        accessor: "media_owner_type"
      },
      {
        accessor: "visible"
      },
      {
        accessor: "min_period"
      },
      {
        accessor: "location"
      },
      {
        accessor: "location_name"
      },
      {
        accessor: "press_type"
      },
      {
        accessor: "facade"
      },
      {
        accessor: "notes"
      },
      {
        accessor: "other_location"
      }
    ],
    [selectedBillboards]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleRowSelected,
    preGlobalFilteredRows,
    setGlobalFilter,
    state
  }: any = useTable(
    {
      columns,
      data: _.isEmpty(orderedPlants) ? myBillboardsInMap : orderedPlants, /// <--- qui ci vanno i dati della lista
      defaultColumn,
      filterTypes,
      initialState: {
        sortBy: orderColumn,
        hiddenColumns: ["other_location", "notes", "facade", "municipality", "address", "available", "city_code", "billalo_code", "authorization_expiration", "creationDate", "height", "height_ground", "id", "last_maintenance", "latitude", "longitude", "media_owner", "n_authorization", "n_sheet", "orientation", "property_type", "updateDate", "media_type", "size", "cod_cimasa", "base", "lighting", "taxes.municipal_tax", "taxes.last_payment", "billboard_class", "media_owner_type", "visible", "min_period", "location", "location_name", "press_type"]
      },
      autoResetSelectedRows: false,
    } as any,
    useFlexLayout,
    useFilters,
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          width: 10,
          Header: ({ getToggleAllRowsSelectedProps }: any) => (
            <div style={{ marginLeft: 2 }} >
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row, index }: any) => {
            // console.log("row index",row)
            return (
              <div className="d-sm-flex align-items-center">
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} data-cy={"billboardSelectionCheckbox" + row.index} />
              </div>
            )
          },
        },
        ...columns,
      ])
    }
  )

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);

      return (
        <div className={`${row.getToggleRowSelectedProps().checked ? "borderSelected" : row.isSelected ? "overviewDetailRow" : 'border-bottom'} tr-group`} style={style} onClick={() => {
          setSelectedRowOverview(row.id)
          toggleRowSelected(row.id, true)
        }}>
          <div className="tr d-flex align-items-center hoverPointer">
            {row.cells.map((cell: any) => {
              return (
                <div {...cell.getCellProps()} className="td text-dark poppinsFont">
                  {cell.render("Cell")}
                </div>
              );
            })}
          </div>
        </div>
      );
    },
    [prepareRow, rows]
  );

  const fetchMoreData = () => {
    console.log('fetch More data..')
  };

  const itemData = {
    rows,
    prepareRow,
  }

  return (
    <Styles>
      <div className="d-sm-flex justify-content-center mt-3">
        <Grid container item xs={11}>
          <Grid container item xs={12} alignItems="center" justify="space-between">
            <Grid item xs={3}>
              <Dropdown disabled={selectedFlatRows[0] || !selectedBillboards ? false : true} isOpen={actionOpen} toggle={toggleAction}>
                <DropdownToggle data-cy="actionButton" className={`${selectedFlatRows[0] ? "backgroundSelected text-white" : "bg-transparent text-dark"} border`} caret>Azioni </DropdownToggle>
                <DropdownMenu className={classes.drowdownMenu}>
                  <DropdownItem data-cy="selectAllPlants" onClick={() => {
                    dispatch(ContentActions.didSelectBillboards({ ids: _.mapValues(_.keyBy(selectedFlatRows, function (o) { return o.original.id }), function (o) { return o.original.price }) }));
                    dispatch(ContentActions.didSetPlants(_.map(selectedFlatRows, 'original')))
                  }}><Icon color="gray" icon="add_channel_2" /> Includi in Campagna</DropdownItem>
                  <DropdownItem onClick={() => {
                    dispatch(ContentActions.didRemoveSelectedBillboards({ ids: selectedFlatRows.map((i: any) => i.original.id) }))
                    dispatch(ContentActions.didDeletePlants(_.map(selectedFlatRows, 'original')))
                  }}><Icon color="gray" icon="trash" /> Escludi da Campagna</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Grid>
            <Grid item xs={8}>
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </Grid>
          </Grid>
          <Grid container item alignItems="center">
            <div {...getTableProps()} className="table mt-3">
              <div className="thead">
                {headerGroups.map((headerGroup) => {
                  // console.log("headerGroup", headerGroup, headerGroups)
                  return (
                    <>
                      <Grid item xs={8}>
                        <div {...headerGroup.getHeaderGroupProps({ style: { backgroundColor: '#F1F1F5', borderTopLeftRadius: 10, borderTopRightRadius: 10, marginBottom: 5 } })} className="tr">
                          {headerGroup.headers.map((column) => (
                            <div /* {...column.getHeaderProps()} */ className="th text-muted" >
                              {column.render("Header")}
                            </div>
                          ))}
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                      </Grid>
                    </>
                  )
                })}
              </div>
              <div data-cy="tableBody" className="tbody" {...getTableBodyProps({ style: { margin: 2, borderRadius: 15, backgroundColor: "white", height: '47vh' } })}>
                <AutoSizer onResize={rebuildTooltip}>
                  {({ height, width }) => (
                    <InfiniteLoader
                      isItemLoaded={(index: any) => index < rows.length - 4 ? true : false}
                      itemCount={rows.length}
                      loadMoreItems={fetchMoreData as any}
                    >
                      {({ onItemsRendered, ref }: any) => (
                        <VariableSizeList
                          height={height}
                          itemCount={rows.length}
                          itemData={itemData}
                          // Hardcoded values only :/
                          itemSize={(i) => (rows[i] && rows[i].isExpanded ? 250 : 80)}
                          width={width}
                          onScroll={rebuildTooltip}
                          onItemsRendered={onItemsRendered}
                          ref={listRef}
                        >
                          {RenderRow}
                        </VariableSizeList>
                      )}
                    </InfiniteLoader>
                  )}
                </AutoSizer>
              </div>
            </div>
          </Grid>
        </Grid>
        <Popover
          className={classes.popoverContainer}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => { setAnchorEl(null); toggleRowSelected(selectedRowOverview, false) }}
          anchorOrigin={{ vertical: 'center', horizontal: 'left', }}
          transformOrigin={{ vertical: 'center', horizontal: 'left', }}
        >
          <PlantDetailOverview items={plantProps} />
        </Popover>
      </div>
    </Styles>
  );
}