import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import styled from "styled-components";
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { coroutine } from 'cypress/types/bluebird';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    plantDetailsOverviewRoot: {
      flexGrow: 1,
      width: 300,
      fontSize:14
    },
    gridList: {
      width: "100%",
      height: "100%",
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
      position: 'relative'
    },
    top: {
      color: '#0062CC',
      //animationDuration: '550ms',
      position: 'absolute',
      //left: 0,
    },
    circle: {
      strokeLinecap: 'round',
    },
    billaloIcon: {
      color: '#0062CC',
      alignItems: "center",
      justifyContent: "center",
      // position: 'absolute',
    },
    visibilityProgress: {
      width: 80,
      height: 80,
    },
    municipalityGraphic: {
      width: 200,
      height: 200,
    },
    confirmStepBorders: {
      backgroundColor: "white",
      borderRadius: 14,
      marginTop: 15,
    },
    percentageProgress: {
      color: 'black',
      alignItems: "center",
      justifyContent: "center",
      fontSize: 25
      // position: 'absolute',
    },
    paperNotification: {
      height: 150,
      width: 400,
      fontSize: 17
    },
    popoverContainer: {
      width: 340,
      '& .MuiPaper-root.MuiPopover-paper.MuiPaper-rounded': {
        top: 'unset !important',
        bottom: 0
      },
      '& .MuiPaper-root': {
        height: '76.5vh',
        border: '1px solid #0062FF',
        borderRadius: 10
      }
    }
  }),
);

export const backButton = {
  display: "flex",
  backgroundColor: "white",
  width: 186,
  alignItems: "center",
  borderRadius: 10,
  borderWidth: 0,
  fontSize: 18,
  padding: "8px 11px 8px 11px",
  color: "gray"
}

export const photoShape = {
  // borderWidth: 1,
  // borderColor: "#bfbfbf",
  // borderStyle: "solid",
  width: "100%",
  height: "100%",
  borderRadius: 12
}

export const confirmButton = {
  borderRadius: 7,
  backgroundColor: "#007bff",
  color: "white",
  border: "none",
  fontSize: 15,
  padding: "8px 11px 8px 11px",
  // height: 50,
  // width: "100%"
}

export const Divider = () => <Grid className="border-bottom" container item xs={12} alignItems="center"></Grid>

export const useStylesList = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    cancelButton: {
      borderColor: "transparent",
      backgroundColor: "#66b0ff",
      color: "white",
      borderRadius: 10,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      "&:hover": {
        backgroundColor: "#66b0ff",
      }
    },
    confirmButton: {
      borderColor: "transparent",
      backgroundColor: "#007bff",
      color: "white",
      borderRadius: 10,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      "&:hover": {
        backgroundColor: "#007bff",
      }
    },
    borderColor: {
      '& label.Mui-focused': {
        color: '#00bfff',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#00bfff',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: 10
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00bfff',
        },
      },
    },
    noBorders: {
      border: "none",
    },
    noBordersAll: {
      // width: 180,
      // marginLeft: 30,
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: "#E6E6E6",
      borderRadius: 10,
      backgroundColor: "white",
    },
    rootMaintenance: {
      // width: 300,
      // height: "70vh",
      padding: theme.spacing(2),
      //backgroundColor: "#fafafb",
      overflow: "hidden"
    },
    csvButtonUpload: {
      backgroundColor: "#E6E6E6",
      color: "gray",
      border: "none",
      borderRadius: 14,
      height: 60,
      width: 150,
      "&:hover": {
        backgroundColor: "#E6E6E6",
      }
    },
    csvButtonUploaded: {
      backgroundColor: "hsl(80, 100%, 98%)",
      color: "gray",
      border: "none",
      borderRadius: 14,
      height: 60,
      width: 150,
      "&:hover": {
        backgroundColor: "hsl(80, 100%, 98%)",
      }
    },
    csvButtonDownload: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "hsl(200, 100%, 98%)",
      borderWidth: 0,
      borderRadius: 14,
      "&:hover": {
        backgroundColor: "hsl(200, 100%, 98%)",
      }
    },
    csvButtonIcon: {
      height: 60,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#e6f2ff",
      borderWidth: 0,
      borderRadius: 14,
    },
    csvButtonIconUploaded: {
      height: 60,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "hsl(80, 100%, 94%)",
      borderWidth: 0,
      borderRadius: 14,
    },
  }))

export const Styles = styled.div`
 padding: 0.8rem;
// margin-top: 5px;
// padding: 13px;

.table {
  
  width: 100%;

  .thead {
    padding-right: 15px;
    font-family: 'Poppins', sans-serif;
  }

  .tbody {
    height: 80vh;
  //  padding: 17px;
  }

  .tbodyMap {
    height: 72vh;
  }

  .tr-group {
    display: flex;
    flex-direction: column;
     overflow-x: hidden;
    // border: 1px solid #00e68a;
    // border-radius: 10px;
  }

  .tr {
     overflow: hidden;
    display: flex;
    :last-child {
      .td {
        border-bottom: 0;
        color: #6c757d;
      }
    }
  }

  .th,
  .td {
    margin: 0;
    // padding: 0.5rem;
    margin-left: 10px;
    color: #6c757d;

    :last-child {
      border-right: 0;
    }
  }
}
`;

export const notificationAlertTextChange = (notification: string) => {
  switch (notification) {
    case "Alert Materiali Leggero":
      return "Alcune campagne sono in attesa dei materiali"
      break;
    case "Alert Materiali Pesante":
      return "Alcune campagne sono in attesa dei materiali"
      break;
    case "Alert Foto Leggero":
      return "La campagna non ha le foto minime richieste"
      break;
    default:
      return ""
  }
}

export const notificationTextChange = (notification: string) => {
  switch (notification) {
    case "Alert Materiali Leggero":
      return "Attesa materiali"
      break;
    case "Alert Materiali Pesante":
      return "Attesa materiali"
      break;
    case "Alert Foto Leggero":
      return "Foto richieste"
      break;
    default:
      return ""
  }
}

export const setBadge = (order_date: any, status: string, imgLength: number, plantListLength: number, campaignStart: any, campaignEnd: any) => {
  let now = moment().format("YYYY-MM-DD")
  let before15 = moment(campaignStart).subtract(15, 'days').format("YYYY-MM-DD")
  let before30 = moment(campaignStart).subtract(30, 'days').format("YYYY-MM-DD")
  let before7 = moment(campaignEnd).subtract(7, 'days').format("YYYY-MM-DD")

  // console.log("setBadge test", moment(now).isBefore(before30),now, moment(campaignStart).format("YYYY-MM-DD"), before30)
  // console.log("setBadge test", moment(now).isBetween(before30, moment(campaignStart).format("YYYY-MM-DD")), now, moment(campaignStart).format("YYYY-MM-DD"), before30)

  if (moment(now).isBetween(before15, moment(campaignStart).format("YYYY-MM-DD")) && status !== "delivered_materials") {
    return { invisible: false, notification: "Alert Materiali Pesante", style: { marginLeft: 10, width: 6, height: 6, borderRadius: "100%", backgroundColor: "#ffc542" } }  /// 15gg prima l'inizio della campagna && status !== delivered_materials Alert Materiali Pesante
  }
  else if (moment(now).isBetween(before30, moment(campaignStart).format("YYYY-MM-DD")) && status !== "delivered_materials") {
    return { invisible: false, notification: "Alert Materiali Leggero", style: { marginLeft: 10, width: 6, height: 6, borderRadius: "100%", backgroundColor: "#ffc542" } }  /// 30gg prima l'inizio della campagna && status !== delivered_materials Alert Materiali Leggero
  }
  else if (imgLength < (plantListLength / 2) && moment(now).isBetween(before7, moment(campaignStart).format("YYYY-MM-DD"))) {
    return { invisible: false, notification: "Alert Foto Leggero", style: { marginLeft: 10, width: 6, height: 6, borderRadius: "100%", backgroundColor: "#fc5a5a" } }  /// le foto caricate sono uguali o maggiorni alla metà degli impianti && mancano 7 gg alla fine della campagna Alert Foto Leggero
  } else {
    return { invisible: true, notification: "", style: {} }  /// nasconde il bedge
  }
}