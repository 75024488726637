import { call, put, takeEvery, takeLatest, delay, select, fork } from 'redux-saga/effects'
import { Button } from 'reactstrap';
import * as ContentApi from '../../api/content'
import * as PaymentApi from '../../api/payment'
import { actions as AuthActions } from '../slices/auth'
import { actions as NotificationActions } from '../slices/notification'
import { actions as UiActions } from '../slices/ui'
import { actions as ContentActions, selectors as ContentSelectors } from '../slices/content'
import { actions as UIActions, selectors as UISelectors } from '../slices/ui'
import { push } from 'connected-react-router'
import moment from 'moment';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { readFile } from 'fs';
import { any } from 'cypress/types/bluebird';


export function* sagas() {
  console.log('in content saga dsp');
  yield takeLatest(ContentActions.willFilterBillboards.type, willFilterBillboards)
  yield takeLatest(ContentActions.willUploadFilter.type, willUploadFilter)
  yield takeLatest(ContentActions.willCreateCampaign.type, willCreateCampaign)
  yield takeLatest(ContentActions.willFilterPricingIds.type, willFilterPricingIds)
  yield takeLatest(ContentActions.didSortByObjective.type, didSortByObjective)
  yield takeLatest(ContentActions.willPayOrder.type, willPayOrder)
  yield takeLatest(ContentActions.batchDeleteOrders.type, batchDeleteOrders)
  yield takeLatest(ContentActions.willListMyCampaigns.type, willListMyCampaigns)
  yield takeLatest(ContentActions.willGetCampaignOrders.type, willGetCampaignOrders)
  yield takeLatest(ContentActions.willStartPayment.type, willStartPayment)
  yield fork(checkFilters);
  yield takeLatest(ContentActions.willUpdateOrderState.type, willUpdateOrderState)
  yield takeLatest(ContentActions.willUpdateCampaignState.type, willUpdateCampaignState)
  yield takeLatest(ContentActions.willAddBillboardsAvailability.type, willAddBillboardsAvailability)
  yield takeLatest(ContentActions.willGetContracts.type, willGetContracts)
  yield takeLatest(ContentActions.willPayCampaign.type, willPayCampaign)

}

function* checkFilters() {
  const result = yield call(ContentApi.getFilter);
  console.log('checkFilters', result)
  if (result) {
    // console.log("checkFilters-2", moment(result.period.date.from, 'DD-MM-YYYY'), moment().add(60, 'days'))
    if (result.period) {
      if (moment(result.period.date.from, 'DD-MM-YYYY').isAfter(moment().add(60, 'days'))) {
        yield put(ContentActions.didUploadTempFilter({ filters: result }))
        console.log("localstorage recovered!")
      }
    } else {
      yield put(UiActions.didSetCityFirst(''))
      console.log("localstorage empty!")
    }
  } else {
    yield put(UiActions.didSetCityFirst(''))
  }
  yield put(UiActions.switchDraftCampainModal(''))

}

function* willFilterBillboards(action: any) {
  // console.log('in saga willFilterBillboards', action)
  // let _filters = action.payload.filters
  // let _filters1, price_from
  // console.log('in saga willFilterBillboards 2', action.payload.filters)

  const campaign_id = action.payload.campaign_id

  let { price_from, price_to, period, ..._filters } = action.payload.filters
  // console.log('filtro 1', _filters)

  let start_busy_dates = action.payload.start_busy_dates
  console.log("listFilteredBillboards in if true saga")
  if (!_.isEmpty(action.payload.filters)) {
    if (price_from !== "" && price_from) {
      // delete action.payload.filters.price_from
      _filters = { price_from, ..._filters }
      // console.log("in if ", _filters)
    }
    if (action.payload.filters.price_to !== "" && price_to) {
      _filters = { price_to, ..._filters }
    }
    if (!_.isEmpty(action.payload.filters.period)) {
      // console.log("periodo1", _filters)

      _filters.from = moment(action.payload.filters.period.date.from, "DD-MM-YYYY")
      _filters.to = moment(action.payload.filters.period.date.to, "DD-MM-YYYY")
      // console.log("periodo2", _filters)
      //   period.from = moment(_filters.period.from).subtract(13, 'days')
      //   // _filters.period.from= moment(_filters.period.from).subtract(13, 'days')
      //   _filters = { period, ..._filters }
    }
    if (!_filters.media_type) {
      delete _filters.media_type
      delete _filters.billboard_class
    }
    delete _filters.period

    if (_filters.interest) {
      delete _filters.interest
    }
    // console.log('filtro', _filters)
    try {
      if (_.isEmpty(_filters)) {
        yield put(ContentActions.didFilterBillboards({ billboards: [], is_confirm: action.payload.is_confirm, selectedPlants: {} }))
      } else {
        const result = yield call(ContentApi.listFilteredBillboards, _filters, start_busy_dates, campaign_id);
        // console.log("result willFilterBillboards", result)
        let is_confirm = action.payload.is_confirm
        let array = action.payload.selectedPlants
        let selectedFilteredBillboards = [] as any

        if (!_.isEmpty(array) && action.payload.is_confirm) {
          //in caso di conferma creazione campagna devo controllare che gli impianti siano tutti disponibili
          // controllo impianti  e setto is confirm a true se devo chiamare will create campaign
          // let count = 0
          // console.log('in if list billboards check', array)
          let x = _.keyBy(result.data.listFilteredBillboards, 'id')
          array.map((o: any) => {
            result.data.listFilteredBillboards.map((i: any) => {
              if (o.id === i.id) {
                selectedFilteredBillboards.push(o)
              }
            })
          })

          let count = array.length - selectedFilteredBillboards.length
          // console.log('array??', array, count)

          if (count > 0) {
            is_confirm = false
            yield put(UiActions.switchDraftCampainModal(''))
            yield put(ContentActions.didSelectBillboards({ ids: _.mapValues(_.keyBy(selectedFilteredBillboards, function (o) { return o.id }), function (o) { return o.price }) }));
            yield put(NotificationActions.willShowNotification({ message: count > 1 ? `${count} impianti non sono più disponibili` : "Un impianto non è più disponibile", type: "danger" }));
          }
        }


        yield put(ContentActions.didFilterBillboards({ billboards: result.data.listFilteredBillboards, is_confirm: is_confirm, selectedPlants: selectedFilteredBillboards }))
      }
    } catch (error: any) {
      console.log('Error ', error);
      yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
    }
  } else {
    yield put(ContentActions.didSetFilter({}))
    yield fork(checkFilters)
  }

}
function* willUploadFilter(action: any) {
  console.log('in saga willUploadFilter', action)
  let filters = action.payload.filters

  if (!filters.media_type) {
    delete filters.media_type
    delete filters.billboard_class
  }

  try {
    yield call(ContentApi.uploadFilter, filters);
    // console.log("result uploadFilter saga", result)
    yield put(ContentActions.didUploadFilter({ filters: filters }))
  } catch (error: any) {
    console.log('Error ', error);
    // yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
}

function* willCreateCampaign(action: any) {
  // console.log('in saga willCreateCampaign', action)
  let campaign_id = action.payload.campaignID
  // console.log("in saga willCreateCampaign with order_date", action.payload.order_date)
  //calcolare n_municipality
  let id_ordini = []
  // if(!_.isEmpty(action.payload.orders)){
  //   _.forEach(action.payload.sconto, )
  // }

  let start_busy_dates = action.payload.start_busy_dates
  // let billboards = _.mapValues(_.keyBy(action.payload.selectedPlants, function (o) { return o.id }))
  let billboards = _.map(action.payload.selectedPlants, (o: any) => {
    return { billboard_id: o.id, price: o.price }
  })
  // console.log("in saga willCreateCampaign start_busy_dates", start_busy_dates)

  // yield put(UIActions.setActivityRunning())
  let input = {
    id: campaign_id,
    status: "Draft",
    name: action.payload.campaignData.campaignName,
    target: action.payload.campaignData.budgetAndTarget.target,
    total_amount: _.ceil((action.payload.total_amount), 2) + action.payload.fee,
    to: moment(action.payload.period.date.to, "DD-MM-YYYY"),
    from: moment(action.payload.period.date.from, "DD-MM-YYYY"),
    order_date: moment(),
    orders: _.isEmpty(action.payload.orders) ? [] : action.payload.orders,
    n_municipality: action.payload.n_municipality,
    print_amount: 0,
    fee: action.payload.fee,
    discount: action.payload.discount,
    billboards: billboards,
    filters: JSON.stringify(action.payload.filters)
  }
  // let x = _.mapValues(_.keyBy(action.payload.selectedPlants, function (o) { return o.id }), function (o) { return o.price })
  // console.log("in saga willCreateCampaign X", x)
  let y = [] as any
  y = _.groupBy(action.payload.selectedPlants, 'media_owner')
  y = _.values(y)
  let orders = _.map(y, function (o: any) {
    // let sconto= _.forEach(action.payload.sconto,  i.media_owner===o[0]['media_owner'])
    let sconto = _.keyBy(action.payload.sconto, function (i) { return i.media_owner })
    // let sconto = _.map(action.payload.sconto, function(i:any){return i.media_owner=})    action.payload.sconto[o[0]['media_owner']]
    let importo_sconto = 0
    if (sconto[o[0]['media_owner']]) {
      importo_sconto = sconto[o[0]['media_owner']].discount
    }
    let id = uuidv4()
    if (!_.isEmpty(action.payload.ordini)) {
      _.forEach(action.payload.ordini, function (i: any) {
        if (o[0]['media_owner'] === i.media_owner) {
          id = i.id
        } else {
          console.log('ordine da eliminare???', i.id, i.media_owner)
        }
      })
      // console.log("in for", action.payload.ordini, id)
    }

    // console.log("sconto creazione ordine", sconto, importo_sconto, o, id)
    return {
      id: id,
      media_owner: o[0]['media_owner'],
      printer: action.payload.printer,
      amount: _.ceil((_.multiply(_.sumBy(o, 'price'), action.payload.n_uscite)) + importo_sconto, 2),
      billboards: o,
      discount: importo_sconto
      // _.map(o, function (i: any) {
      //   return {
      //     billboard_id: i.id,
      //     price: i.price
      //   }
      // })
    }
  })
  // input.total_amount = input.total_amount + _.sumBy(orders, 'amount')
  // input.fee = input.total_amount * 10 / 100

  var tempArray = [] as any
  // console.log('in saga willCreateCampaign willCreateorder 2', input)
  let ordersId = [] as any[]
  let ordersDraftId = [] as any[]
  orders = _.remove(orders, (el: any) => { return el != undefined })
  // console.log('in saga willCreateCampaign orders', orders)
  for (var i = 0; i < orders.length; i += 25) {
    tempArray = orders.slice(i, i + 25)
    // tempArray = _.remove(tempArray, (el: any) => { return el != undefined })
    try {
      const result = yield call(PaymentApi.batchAddOrders, input, tempArray);
      // console.log("result uploadFilter saga", result)
      if (result.data.batchAddOrders) {
        _.map(result.data.batchAddOrders, function (o: any) {
          if (o.is_draft) {
            ordersDraftId.push(o.id)
          } else {
            ordersId.push(o.id)
          }

        })
      }
      // yield put(NotificationActions.willShowNotification({ message: "Creazione dell'ordine eseguita con successo", type: "success" }));
    } catch (error: any) {
      console.log('Error ', error);
      yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
    }
  }
  yield put(ContentActions.addOrdersId({ orders: ordersId, ordini: orders }));
  // console.log('ordini id da eliminare????', ordersId, action.payload.orders, _.difference(action.payload.orders, ordersId))

  yield put(ContentActions.ordersToDelete(_.difference(action.payload.orders, ordersId)))

  try {
    input['orders'] = ordersId
    input['draft_orders'] = ordersDraftId
    console.log('elenco completo di ordini', ordersId, input, orders, tempArray)
    const result = yield call(PaymentApi.addCampaign, input, orders, start_busy_dates);
    console.log("result uploadFilter saga", result)
    yield put(NotificationActions.willShowNotification({ message: "Campagna pubblicitaria creata con successo", type: "success" }));
    yield put(ContentActions.willListMyCampaigns())
  } catch (error: any) {
    console.log('Error ', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
  yield put(UIActions.setActivityRunning(false))
}

function* willStartPayment(action: any) {
  console.log("in willStartPayment saga", action)
  let pricindIds = [] as any
  let impianti_sconto = [] as any
  let orders = action.payload.campaign.orders
  _.map(action.payload.selectedPlants, (o: any) => {
    if (o.pricing_id === null || o.pricing_id === undefined || o.pricing_id === "undefined") {
      return
    } else {
      pricindIds.push({ media_owner: o.media_owner, pricing_id: o.pricing_id })
      impianti_sconto.push(o)
    }
  })

  let filtro = {
    "city": action.payload.filters.city,
    "period": action.payload.filters.period
  }

  try {
    let resultStartPayment: any = yield call(PaymentApi.startPayment, action.payload.campaignID, pricindIds, filtro, action.payload.selectedPlants, impianti_sconto);

    resultStartPayment = JSON.parse(resultStartPayment)
    // console.log("result startPayment 2", resultStartPayment, resultStartPayment.client_secret)
    yield put(ContentActions.didStartPayment({ client_secret: resultStartPayment.client_secret }))

  } catch (error: any) {
    console.log('Error ', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
}

function* willFilterPricingIds(action: any) {
  console.log("in willFilterPricingIds", action)
  let pricindIds = [] as any
  let impianti_sconto = [] as any
  _.map(action.payload.selectedPlants, (o: any) => {
    if (o.pricing_id === null || o.pricing_id === undefined || o.pricing_id === "undefined") {
      return
    } else {
      pricindIds.push({ media_owner: o.media_owner, pricing_id: o.pricing_id })
      impianti_sconto.push(o)
    }
  })
  let filtro = {
    "city": action.payload.filters.city,
    "period": action.payload.filters.period
  }

  // console.log("pricing ids filtered:", pricindIds, filtro)
  // impianti_sconto = _.keyBy(impianti_sconto, 'pricing_id');

  try {

    const result = yield call(PaymentApi.getPricingRules, pricindIds, filtro, action.payload.selectedPlants, impianti_sconto);

    yield put(ContentActions.didUploadPriceAndDiscount({ sconto: result.sconto, totale: result.totale, sconti: result.sconti, fee: result.billalo_fee }))

    let totale = result.totale + result.sconto
    yield put(ContentActions.willCreateCampaign({ campaignID: "", filters: {}, uscite: 0, n_uscite: action.payload.n_uscite, sconto: result.sconti, orders: [], discount: result.sconto, campaignData: {}, selectedPlants: [], total_amount: totale, period: {}, media_owners: action.payload.media_owners, n_municipality: action.payload.n_municipality, fee: result.billalo_fee }))

    if (result.sconto < 0) {
      yield put(NotificationActions.willShowNotification({ message: "Hai diritto ad uno sconto di " + result.sconto * -1 + "€", type: "success" }));
    }

  } catch (error: any) {
    console.log('Error ', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
}

function* didSortByObjective(action: any) {
  console.log("in didSortByObjective with:", action)
  let uscite = action.payload.n_uscite
  let array = action.payload.billboards
  const totalCost = action.payload.budgetAndTarget.budget
  const objective = action.payload.budgetAndTarget.target
  let newSum = 0
  let pagati = [] as any
  let ordered = [] as any
  if (objective === "Notorietà") {
    ordered = _.orderBy(array, "iV_normalized", "desc")

    ordered.map((o: any) => {
      if (newSum + (o.price * uscite) <= totalCost) {
        newSum = newSum + (o.price * uscite)
        pagati.push(o)
      } else {
        return
      }
    })
    console.log("ordered by Notorietà", ordered)
  }
  if (objective === "Interazioni") {
    ordered = _.orderBy(array, "panel_index", "desc")

    ordered.map((o: any) => {
      if (newSum + (o.price * uscite) <= totalCost) {
        newSum = newSum + (o.price * uscite)
        pagati.push(o)
      } else {
        return
      }
    })
    console.log("ordered by Interazioni", ordered)
  }

  yield put(ContentActions.didSelectBillboards({ ids: _.mapValues(_.keyBy(pagati, function (o) { return o.id }), function (o) { return o.price }) }));
  yield put(ContentActions.didSetPlants(pagati));
  yield put(ContentActions.didSetOrderedPlants({ ordered: ordered }))
  console.log("billboards selected: ", pagati)

}

function* batchDeleteOrders(action: any) {
  console.log('batchDeleteOrders, orders to delete??', action)
  var tempArray = [] as any
  for (var i = 0; i < action.payload.orders.length; i += 25) {
    tempArray = action.payload.orders.slice(i, i + 25)

    try {
      const result = yield call(PaymentApi.batchDeleteOrders, tempArray, action.payload.campaign_id);
      console.log("result batchDeleteOrders saga", result)

    } catch (error: any) {
      console.log('Error ', error);
    }
  }
}

function* willPayOrder(action: any) {
  console.log('willPayOrder, orders to delete??', action)

  try {
    const result = yield call(PaymentApi.payOrder, action.payload);
    console.log("result payOrder saga", result)
    yield put(NotificationActions.willShowNotification({ message: "Pagamento effettuato", type: "success" }));

  } catch (error: any) {
    console.log('Error ', error);
    if (!error.message.includes("Please use the same instance of")) {
      yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
    }
  }
}

function* willListMyCampaigns(action: any) {
  try {
    const result = yield call(ContentApi.listMyCampaigns);
    console.log("result willListMyCampaigns saga", result)
    if (result[0]) {
      /// serve per la dashboard
      const lastCampaign = _.orderBy(result, 'order_date', 'desc')[0]
      yield put(ContentActions.didSetSelectedCampaign(lastCampaign))
      yield put(ContentActions.willGetCampaignOrders({ campaign_id: lastCampaign.id, orders: lastCampaign.orders }))
    }
    yield put(ContentActions.didListMyCampaigns({ campaigns: result }));

  } catch (error: any) {
    console.log('Error ', error);
  }
}


function* willGetCampaignOrders(action: any) {
  // console.log("willGetCampaignOrders", action.payload)
  try {
    const result = yield call(ContentApi.batchGetOrders, action.payload.campaign_id, action.payload.orders);
    console.log("result willGetCampaignOrders saga", result)
    yield put(ContentActions.didListCampaignOrders({ campaignOrders: result }));

  } catch (error: any) {
    console.log('Error ', error);
  }
}

function* willUpdateOrderState(action: any) {
  console.log('in saga willUpdateOrderState', action)

  let id = action.payload.id
  let campaign_id = action.payload.campaign_id
  let status = action.payload.action

  try {
    const result = yield call(ContentApi.updateOrderState, campaign_id, id, status);
    console.log("result willUpdateOrderState", result)
    // yield put(NotificationActions.willShowNotification({ message: "Stato dell'ordine aggiornato correttamente", type: "success" }));

  } catch (error: any) {
    console.log('Error ', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
}
function* willUpdateCampaignState(action: any) {
  console.log('in saga willUpdateCampaignState', action)

  let campaign_id = action.payload.campaign_id
  let status = action.payload.action

  try {
    const resultCampaign = yield call(ContentApi.updateCampaignState, campaign_id, status);

    console.log("result willUpdateCampaignState", resultCampaign)
  } catch (error: any) {
    console.log('Error ', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
}

function* willAddBillboardsAvailability(action: any) {
  console.log('in saga willAddBillboardsAvailability', action)
  let period = action.payload.period
  let orders = action.payload.orders

  let datesArray = [] as any
  let input = [] as any
  let currentYear = moment(period.date.from, "DD-MM-YYYY").year()
  // console.log("anno?", currentYear, moment(period.date.from).year())
  // let startYearFilter = moment().year(period.year).startOf('year').day("Monday").add(8, 'days')
  let startYear = "" as any
  if (currentYear === 2023) {
    startYear = moment("2023-01-02")
  }
  else if (currentYear === 2034) {
    startYear = moment("2034-01-02")
  }
  else if (currentYear === 2040) {
    startYear = moment("2040-01-02")
  }
  else if (currentYear === 2045) {
    startYear = moment("2045-01-02")
  } else {
    startYear = moment().year(currentYear).startOf('year').day("Monday").add(8, 'days')
  }

  // console.log("inizio anno", startYear)
  _.forEach(period.uscite, (o: any) => {
    let date = startYear.clone()
    date.add(_.multiply(14, (o % 26) - 1), 'days')
    // console.log("dataa", date)
    // datesArray.push(date.format('YYYY-MM-DD'))
    datesArray.push(date)
  })
  // console.log("array", datesArray)
  _.forEach(orders, (o: any) => {
    let bibbloardsIds = [] as any
    _.map(o.billboards, (i: any) => {
      bibbloardsIds.push(i.id)
    })
    input.push({ order_id: o.id, billboards: bibbloardsIds })
  })

  try {
    const result = yield call(ContentApi.batchAddBillboardAvailability, input, datesArray, action.payload.campaign_id);
    console.log("result willAddBillboardsAvailability", result)

  } catch (error: any) {
    console.log('Error ', error);
    /// NON SO SE LASCIARE LA NOTIFICA
    // yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
}


function* willGetContracts(action: any) {
  console.log('in saga willGetOrderContract', action)

  yield put(UiActions.didSetActionDone({ done: true, action: "orderContract" }))
  let urls = [] as any
  try {
    // const result = yield call(ContentApi.buildPdfOrderContract, action.payload.campaign, action.payload.id);
    // console.log("result willGetOrderContract", result.data.buildPdfOrderContract)
    // if (result.data.buildPdfOrderContract) {
    const campaign = yield call(ContentApi.getResourceUrl, action.payload.campaign, "", "campaign");
    console.log("result willGetcampaignContract getting resource url", campaign.data.getResourceUrl)
    // setTimeout(function(){ window.open(`${campaign.data.getResourceUrl}`, '_self') }, action.payload.orders.length+1);
    // window.open(`${campaign.data.getResourceUrl}`)
    urls.push(campaign.data.getResourceUrl)
    // }
    // yield put(ContentActions.didListOrderImages(result.data.listOrderImages))
    for (let i = 0; i < action.payload.orders.length; i++) {
      console.log("ordine", i)
      let order = yield call(ContentApi.getResourceUrl, action.payload.campaign, action.payload.orders[i], "order");
      console.log("orders pdf", order.data.getResourceUrl)
      // window.open(`${order.data.getResourceUrl}`, `${i * 1000}`)
      // setTimeout(function(){ window.open(`${order.data.getResourceUrl}`, '_self') }, action.payload.orders.length);
      urls.push(order.data.getResourceUrl)
    }
  } catch (error: any) {
    console.log('Error ', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
  yield put(ContentActions.didPutDownloadUrls({ urls: urls }))
  yield put(UiActions.didSetActionDone({ done: null, action: "" }))

}

function* willPayCampaign(action: any) {

  const event = action.payload.event
  const props = action.payload.props
  const selectedCampaign = action.pyload.selectedCampaign
  const client_secret = action.payload.client_secret
  const campaignOrders = action.payload.campaignOrders
  const name = action.payload.name
  const confirmCardPayment = action.payload.confirmCardPayment

  console.log("willPayCampaign saga", client_secret)

  yield put(UiActions.startActivityRunning("payment"))
  // console.log('with event: ', event)
  // event.preventDefault();
  // const cardElement = props.elements.getElement('cardNumber');
  // console.log('with cardElement: ', cardElement, client_secret)

  const cardElement = props.elements.getElement('cardNumber');

  try {
    yield put(ContentActions.willPayOrder({
      client_secret: client_secret, payment_method: {
        card: cardElement,
        billing_details: {
          name: name,
        },
      }
    }))
    /// componenti divisi
    const cardNumber = props.elements.getElement('cardNumber');
    const cardExpiry = props.elements.getElement('cardExpiry');
    const cardCvc = props.elements.getElement('cardCvc');
    // dispatch(ContentActions.willPayOrder({
    //   client_secret: client_secret, payment_method: {
    //     card: cardNumber,
    //     billing_details: {
    //       name: name,
    //     },
    //   }
    // }))

    // const creationResult: any = await client.mutate({ mutation: mutation, variables: { amount: amount * 100 } })
    // console.log('with result: ', creationResult)
    // const data = JSON.parse(creationResult.data.startPayment.data)
    // console.log('with data: ', data.metadata.orderId)
    yield put(ContentActions.batchDeleteOrders({ orders: [], campaign: "" }));

    /// prova componenti divisi
    // const result = await props.stripe.confirmCardPayment(client_secret, {  
    //   payment_method: {
    //     card: cardNumber,
    //     billing_details: {
    //       name: name,
    //     },
    //   } as any
    // });
    const result = yield call(confirmCardPayment, client_secret, {
      payment_method: {
        card: cardNumber,
        billing_details: {
          name: name,
        },
      } as any
    })
    // console.log("result payment frontend", result)

    // const result = await props.stripe.confirmCardPayment(client_secret, {
    //   payment_method: {
    //     card: cardElement,
    //     billing_details: {
    //       name: name,
    //     },
    //   } as any
    // });

    // console.log('payment result: ', result)
    if (result.paymentIntent) {
      // console.log('in true payment ok', campaignOrders)
      for (var i = 0; i < campaignOrders.length; i++) {
        yield put(ContentActions.willUpdateOrderState({ campaign_id: selectedCampaign.id, id: campaignOrders[i].id, action: "pay" }))
      }
      yield put(ContentActions.willUpdateCampaignState({ campaign_id: campaignOrders[0].campaign, action: "pay" }))
      yield put(NotificationActions.willShowNotification({ message: "Pagamento effettuato", type: "success" }));
      yield put(ContentActions.willAddBillboardsAvailability({ period: [], campaign_id: selectedCampaign.id }));
      yield put(UiActions.didSetCampaignStep("Confirmed"))
      // setStep("Confirmed")
      // yield put(UiActions.goTo('/'))
    }

  } catch (error: any) {
    yield put(NotificationActions.willShowNotification({ message: "Errore durante il pagamento", type: "danger" }));

  }



  yield put(UiActions.stopActivityRunning("payment"))
}

// const onSubmit = async (event: any) => {
//   // console.log('with event: ', event)
//   event.preventDefault();
//   // const cardElement = props.elements.getElement('cardNumber');
//   // console.log('with cardElement: ', cardElement, client_secret)

//   // dispatch(ContentActions.willPayOrder({
//   //   client_secret: client_secret, payment_method: {
//   //     card: cardElement,
//   //     billing_details: {
//   //       name: name,
//   //     },
//   //   }
//   // }))
//   dispatch(UiActions.startActivityRunning("payment"))
//   /// componenti divisi
//   const cardNumber = props.elements.getElement('cardNumber');
//   const cardExpiry = props.elements.getElement('cardExpiry');
//   const cardCvc = props.elements.getElement('cardCvc');
//   // dispatch(ContentActions.willPayOrder({
//   //   client_secret: client_secret, payment_method: {
//   //     card: cardNumber,
//   //     billing_details: {
//   //       name: name,
//   //     },
//   //   }
//   // }))

//   // const creationResult: any = await client.mutate({ mutation: mutation, variables: { amount: amount * 100 } })
//   // console.log('with result: ', creationResult)
//   // const data = JSON.parse(creationResult.data.startPayment.data)
//   // console.log('with data: ', data.metadata.orderId)
//   dispatch(ContentActions.batchDeleteOrders({ orders: [], campaign: "" }));

//   /// prova componenti divisi
//   const result = await props.stripe.confirmCardPayment(client_secret, {
//     payment_method: {
//       card: cardNumber,
//       billing_details: {
//         name: name,
//       },
//     } as any
//   });
//   // console.log("result payment frontend", result)

//   // const result = await props.stripe.confirmCardPayment(client_secret, {
//   //   payment_method: {
//   //     card: cardElement,
//   //     billing_details: {
//   //       name: name,
//   //     },
//   //   } as any
//   // });

//   // console.log('payment result: ', result)
//   if (result.paymentIntent) {
//     // console.log('in true payment ok', campaignOrders)
//     for (var i = 0; i < campaignOrders.length; i++) {
//       dispatch(ContentActions.willUpdateOrderState({ campaign_id: selectedCampaign.id, id: campaignOrders[i].id, action: "pay" }))
//     }
//     dispatch(ContentActions.willUpdateCampaignState({ campaign_id: campaignOrders[0].campaign, action: "pay" }))
//     dispatch(NotificationActions.willShowNotification({ message: "Pagamento effettuato", type: "success" }));
//     dispatch(ContentActions.willAddBillboardsAvailability({ period: [], campaign_id: selectedCampaign.id }));
//     dispatch(UiActions.didSetCampaignStep("Confirmed"))
//     // setStep("Confirmed")
//     // dispatch(UiActions.goTo('/'))
//   } else {
//     dispatch(NotificationActions.willShowNotification({ message: "Errore durante il pagamento", type: "danger" }));
//   }

//   dispatch(UiActions.stopActivityRunning("payment"))
// }