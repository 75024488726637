import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../../store/slices/ui';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { ExitsComponent } from './ExitsComponent';
import { useStyles, CustomSwitch, CustomCheckbox } from './ReservationComponent.css';
import { PlantAvailability } from '../PlantAvailabilityComponent/PlantAvailability'
import Icon from '../IconsComponent';
import moment from 'moment';
import 'moment/locale/it';
import _ from 'lodash';


export const ReservationComponent = ({ selectedBillboard }: any) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [calendar, setCalendar] = useState(false)
  const [setToAll, setSetToAll] = useState(false)
  const [date, setDate] = useState({ from: '', to: '' })
  const [filter, setFilter] = useState('')
  const [startBusyDates, setStartBusyDates] = useState('')

  let filters = useSelector(ContentSelectors.didGetFilter)

  const dates = {
    from: moment(filters.period.date.from, 'DD-MM-YYYY').format('YYYY-MM-DD'),
    to: moment(filters.period.date.to).format('YYYY-MM-DD')
  }

  // useEffect(() => {
  //   if (filters.period) {

  //     setDate(dates)
  //     console.log("ReservationComponent date", date,dates)
  //   }
  // }, [])

  return (
    <Grid container style={{ borderRadius: 20, width: 620, height: 601 }} className="d-sm-flex bg-white overflow-auto">
      <Grid className="p-4" container alignItems="center" justify="center" >
        <Grid container alignItems="center" item xs={12}>
          <Grid item xs={2}>Calendario</Grid>
          <Grid className="robotoFont" item xs={10}>
            <FormControlLabel
              className="mt-2"
              control={<CustomSwitch checked={calendar} defaultChecked={true} onChange={() => setCalendar(!calendar)} name="checkedA" />}
              label={!calendar ? "Calendario" : "Uscite (7gg)"}
            />
          </Grid>
          {/* <Grid item xs={1}>
          <button
            data-cy="goBack"
            className="border-0 bg-transparent"
            onClick={() => {dispatch(UiActions.switchDraftCampainModal(''))}}
          >
            <Icon size={25} color="#2196F3" icon="close" />
            </button>
        </Grid> */}
        </Grid>

        <Grid item xs={12}>
          {!calendar
            ? <PlantAvailability plant={selectedBillboard} setDate={setDate} dates={dates} />
            : <ExitsComponent setDate={setDate} setFilter={setFilter} filter={filter} setStartBusyDates={setStartBusyDates} />}
        </Grid>

      </Grid>
      <Grid className={classes.buttonContainer} container item xs={12} alignItems="center" justify="space-between">
        <Grid className="ml-4 mt-2" item xs={6}>
          {/* <FormControlLabel
            control={
              <CustomCheckbox
                checked={setToAll}
                defaultChecked={false}
                onChange={() => setSetToAll(!setToAll)}
                name="applyToAll"
                color="primary"
              />
            }
            label="Applica le date  a tutti gli impianti"
          /> */}
        </Grid>
        <Grid className='mr-2 poppinsFont' container item xs={5} alignItems="center">
          <Grid item xs={6}>
            <button className={classes.cancelButton} onClick={() => {
              dispatch(UiActions.switchDraftCampainModal(''))
              dispatch(ContentActions.didRemoveSelectedBillboard({ id: selectedBillboard.id }))
              dispatch(ContentActions.didDeletePlant(selectedBillboard))
            }}>Annulla</button>
          </Grid>
          <Grid item xs={6}>
            <button data-cy="confirm" className={classes.confirmButton} onClick={() => dispatch(UiActions.switchDraftCampainModal(''))}>Conferma</button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}