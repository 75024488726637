import * as React from 'react';

import {
  Card, CardText, CardBody,
  CardTitle, CardSubtitle, Button,
  Container, FormGroup, Input, Label, FormFeedback,
  Col, Row
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { actions as AuthActions, selectors as AuthSelectors } from '../store/slices/auth'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ActivityButton } from '../components/ActivityButton'
import { NotificationContainer } from '../components/notification-layer/NotificationContainer'

const ResetPasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  newPassword: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
});

export const ResetPassword = () => {

  const dispatch = useDispatch();
  // const loginError = useSelector(AuthSelectors.getLoggedError);

  // React.useEffect(() => {

  //   console.log('effect loginError: ', loginError);

  //   return () => { }

  // }, [loginError])

  return (
    <>
      <NotificationContainer />
      <Container>
        <Card className="mt-3 mt-lg-10">
          <CardBody>
            <CardTitle tag="h5" className="text-center">Reset password</CardTitle>
            <CardSubtitle tag="h6" className="mb-2 text-muted text-center">Hint: Insert New Password</CardSubtitle>
            <Formik
              initialValues={{
                oldPassword: '',
                newPassword: ''
              }}
              validationSchema={ResetPasswordSchema}
              validateOnBlur={true}
              onSubmit={values => {
                // console.log('in onsubmit with: ', values)
                dispatch(AuthActions.willResetPassword({ oldPassword: values.oldPassword, newPassword: values.newPassword }));
              }}
            >
              {({ errors, touched, setFieldValue, values }) => (
                <Form>

                  <FormGroup>
                    <Label for="oldPassword">Vecchia password</Label>
                    <Input invalid={errors.oldPassword && touched.oldPassword ? true : false} type="password" name="oldPassword" id="oldPassword" placeholder="Inserire la vecchia password" tag={Field} />
                    {errors.oldPassword && touched.oldPassword ? (
                      <FormFeedback>{errors.oldPassword}</FormFeedback>
                    ) : null}
                  </FormGroup>

                  <FormGroup>
                    <Label for="newPassword">Nuova Password</Label>
                    <Input invalid={errors.newPassword && touched.newPassword ? true : false} type="password" name="newPassword" id="newPassword" placeholder="Inserire la nuova password" tag={Field} />
                    {errors.newPassword && touched.newPassword ? (
                      <FormFeedback>{errors.newPassword}</FormFeedback>
                    ) : null}
                  </FormGroup>
                  <Row>
                    <Col>
                      <ActivityButton type="submit" name="resetPassword" color="primary" block>Conferma</ActivityButton>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Button color="primary" block to="/login" outline tag={Link}>Indietro</Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}