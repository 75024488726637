import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ProfileActions, selectors as ProfileSelectors } from '../store/slices/profile';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { selectors as ContentSelectors } from '../store/slices/content';
import { Grid, Modal } from '@material-ui/core';
import Autosuggest, { BlurEvent, ChangeEvent } from 'react-autosuggest';
import { useSuggestion } from "../components/Suggestion.hooks";
import { useStyles, modalStyle, buttonSelected, buttonNotSelected, mutableTextFieldProfilo, mutableTextFieldAnagrafica, mutableTextFieldPagamenti, mutableTextFieldUfficio } from '../components/profile/Profile.css';
import Icon from '../components/IconsComponent';
import placeholder from '../assets/images/placeholder_profile.png';
import moment from 'moment';
import _ from 'lodash';
import { ActivityButton } from '../components/ActivityButton';
import { ResetPassword } from '../components/profile/modals/ResetPassword';


export const Profile = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const userProfile = useSelector(ProfileSelectors.getAdvertiser)
  const profileInCognito = useSelector(ProfileSelectors.getProfile)
  const openModal = useSelector(UiSelectors.getOpenModal)

  const [selected, setSelected] = useState("aziendale")
  const [editProfilo, setEditProfilo] = useState(false)
  const [editAnagrafica, setEditAnagrafica] = useState(false)
  const [editPagamenti, setEditPagamenti] = useState(false)
  const [modalType, setModalType] = useState('')

  const [profilo, setProfilo] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }), {
    name: "",
    type: "",
    p_iva: "",
    company_form: "",
    company_type: "",
    fiscal_code: "",
    pec: "",
    email: "",
    phone: "",
    legal_municipality: "",
    legal_address: "",
    legal_cap: "",
    firstJob: null,
    lastYearCampaign: null,
    founded_by: "",
    image: "",
    subscription_date: moment().format("YYYY-MM-DD"),
    legal_email: "",
    bank_account: "",
    society_form: "",
    recipient_code: ""
  })
  // const advertiser = profilo.name.replace(/ /g, '_'); /// questo è l'advertiser da mandare per cognito
  // console.log("Profile profilo", profilo)

  useEffect(() => {
    if (userProfile) {
      setProfilo(userProfile)
    }

    dispatch(UiActions.setSelectedPage(""))
  }, [userProfile])

  return (
    <>
      <Modal className={classes.modalInProfile} open={openModal} onClose={() => {/* dispatch(UiActions.switchOpenModal(false)) */ }}>
        <div style={{ ...modalStyle, flexDirection: 'column', position: 'relative' }} >
          <Grid className={classes.modalInProfileHeader} container>
            <Grid item xs={11}>Modifica profilo</Grid>
            <Grid className="d-sm-flex flex-row-reverse hoverPointer" item xs={1} onClick={() => dispatch(UiActions.switchOpenModal(false))} >
              <Icon size={24} color="#92929D" icon="close" />
            </Grid>
          </Grid>
          {modalType === 'reset-password' && <ResetPassword />}
        </div>
      </Modal>
      <Grid style={{ backgroundColor: "#fafafb", height: 'calc(94vh - 35px)', overflow:'auto' }} className="poppinsFont" container justify="center">
        <Grid className="mt-4" container item xs={10} justify="flex-end">
          {(!editProfilo || !editAnagrafica || !editPagamenti) ? <ActivityButton data-cy="confirm" className={classes.confirmButton} name="willAddAdvertiser" color="primary" onClick={() => {
            dispatch(ProfileActions.willAddAdvertiser(profilo))
          }}>Salva modifiche</ActivityButton> : null}
        </Grid>

        <Grid className="h-100" container item xs={10} justify="center">
          <Grid className="d-sm-flex mt-4" container item xs={12}>

            <Grid style={{ borderRadius: 13, fontSize: 14 }} className="d-sm-flex bg-white p-2" container item xs={12} >
              <Grid item xs={2} container justify="center" alignItems="center">
                <img style={{ borderRadius: 14 }} src={placeholder} width="150" height="150" />
              </Grid>
              <Grid className="d-sm-flex ml-3" container item xs={10} spacing={4}>
                <Grid className="d-sm-flex align-items-center" item xs={12}>
                  {/* <Grid className="d-sm-flex justify-content-center p-2" item xs={2}><img style={{ borderRadius: 14 }} src={placeholder} width="150" height="150" /></Grid> */}
                  <Grid /* style={{ fontSize: 50 }} */ item xs={11}>
                    {mutableTextFieldAnagrafica("name", "Ragione sociale", editProfilo ? profilo.name : <span style={{ fontSize: 50 }}>{profilo.name}</span>, editProfilo, profilo, "", 8, setProfilo)}
                  </Grid>
                  <Grid item xs={1}>
                    <button className="border-0 bg-transparent" onClick={() => setEditProfilo(true)}><Icon size={25} icon="edit" /></button>
                  </Grid>
                </Grid>
                {/* <Grid className="d-sm-flex align-items-center" item xs={6}>
                <Grid style={{ opacity: 0.4 }} item xs={5}>Spazi venduti</Grid>
                <Grid className="d-sm-flex flex-row-reverse" item xs={7}>ijp</Grid>
              </Grid> */}
                <Grid className="d-sm-flex align-items-center" item xs={6}>
                  <Grid style={{ opacity: 0.4 }} item xs={8}>Piano di abbonamento</Grid>
                  <Grid className="d-sm-flex flex-row-reverse" item xs={3}>BASE</Grid>
                </Grid>
                <Grid className="d-sm-flex align-items-center" item xs={6}>
                  <Grid style={{ opacity: 0.4 }} item xs={5}>Iscritto dal</Grid>
                  <Grid className="d-sm-flex flex-row-reverse" item xs={7}>{profilo.subscription_date ? moment(profilo.subscription_date).format("DD-MM-YYYY") : profilo.subscription_date}</Grid>
                </Grid>
                <Grid className="d-sm-flex align-items-center" item xs={6}>
                  {/* <Grid style={{ opacity: 0.4 }} item xs={4}>Livello fee</Grid>
                <Grid className="d-sm-flex flex-row-reverse" item xs={7}>{profilo.fees}%</Grid> */}
                </Grid>
                {editProfilo ? <Grid className="d-sm-flex flex-row-reverse" item xs={12}><button className="border rounded bg-transparent" onClick={() => setEditProfilo(false)}><Icon size={25} icon="check" />Applica</button></Grid> : null}
              </Grid>
            </Grid>
          </Grid>

          <Grid style={{ borderRadius: 13, fontSize: 14 }} className="d-sm-flex bg-white p-2 mt-5" container item xs={12} >
            <Grid className="d-sm-flex align-items-center ml-3" container item xs={11} spacing={4}>
              <Grid className="d-sm-flex align-items-center mt-4" item xs={12}>
                <Grid style={{ fontSize: 20 }} item xs={11}>Dati fatturazione</Grid>
                <Grid item xs={1}><button className="border-0 bg-transparent" onClick={() => setEditPagamenti(true)}><Icon size={25} icon="edit" /></button></Grid>
              </Grid>
              <Grid className="d-sm-flex align-items-center" item xs={12}>
                {mutableTextFieldPagamenti("p_iva", "P.IVA", profilo.p_iva, editPagamenti, profilo, "P.IVA", 3, setProfilo)}
                {mutableTextFieldPagamenti("legal_address", "Sede legale", profilo.legal_address, editPagamenti, profilo, "Sede legale", 3, setProfilo)}
                {mutableTextFieldPagamenti("recipient_code", "Codice destinatario", profilo.recipient_code, editPagamenti, profilo, "Codice destinatario", 3, setProfilo)}
                {/* {mutableTextFieldPagamenti("legal_email", "Email", profilo.legal_email, editPagamenti, profilo, "Email", 3, setProfilo)} */}
                <Grid item xs={3}><span style={{ opacity: 0.4 }}>Email</span><br />{profileInCognito.email}</Grid>
              </Grid>
              <Grid className="d-sm-flex align-items-center" item xs={12}>

                {mutableTextFieldPagamenti("fiscal_code", "Codice Fiscale", profilo.fiscal_code, editPagamenti, profilo, "Codice Fiscale", 3, setProfilo)}
                {mutableTextFieldPagamenti("pec", "PEC", profilo.pec, editPagamenti, profilo, "PEC", 3, setProfilo)}
                {mutableTextFieldPagamenti("society_form", "Forma societaria", profilo.society_form, editPagamenti, profilo, "Forma societaria", 3, setProfilo)}
                {mutableTextFieldAnagrafica("phone", "Telefono", profilo.phone, editPagamenti, profilo, "Telefono", 3, setProfilo)}
              </Grid>
              {/* <Grid className="d-sm-flex align-items-center mb-4" item xs={11}>
              </Grid> */}
            </Grid>
            {editPagamenti ? <Grid className="d-sm-flex flex-row-reverse" item xs={12}><button className="border rounded bg-transparent" onClick={() => setEditPagamenti(false)}><Icon size={25} icon="check" />Applica</button></Grid> : null}

          </Grid>

          <Grid style={{ borderRadius: 13, fontSize: 14 }} className="d-sm-flex bg-white p-2 mt-5 mb-5" container item xs={12} >
            <Grid className="d-sm-flex align-items-center ml-3" container item xs={11} spacing={4}>
              <Grid className="d-sm-flex align-items-center mt-4" item xs={12}>
                <Grid style={{ fontSize: 20 }} item xs={11}>Dati account</Grid>
                <Grid item xs={1}><button className="border-0 bg-transparent" onClick={() => setEditAnagrafica(true)}><Icon size={25} icon="edit" /></button></Grid>
              </Grid>
              <Grid className="d-sm-flex align-items-center" container item xs={11} spacing={4}>
                {mutableTextFieldAnagrafica("name", "Nome referente", profilo.name, editAnagrafica, profilo, "Nome", 4, setProfilo)}
                {mutableTextFieldAnagrafica("email", "Email", profilo.email, editAnagrafica, profilo, "Email", 4, setProfilo)}
                <Grid item xs={4}>
                  <button className="border rounded bg-transparent" onClick={() => {
                    setModalType('reset-password')
                    dispatch(UiActions.switchOpenModal(true))
                  }}>Modifica password</button>
                </Grid>
              </Grid>
            </Grid>
            {editAnagrafica ? <Grid className="d-sm-flex flex-row-reverse" item xs={12}><button className="border rounded bg-transparent" onClick={() => setEditAnagrafica(false)}><Icon size={25} icon="check" />Applica</button></Grid> : null}

          </Grid>

        </Grid>
      </Grid>
    </>
  )
}