import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { Paper, Grid, Modal, Stepper, Step, StepLabel } from '@material-ui/core';
import { Map } from './Map';
import { Campain } from './advanced-planning/Campain';
import { CampainSecondStep } from './advanced-planning/CampainSecondStep'
import { CampainConfirmStep } from './campaign/campaign-confirm/CampainConfirmStep';
import { CampainPaymentStep } from './campaign/campaign-payment/CampainPaymentStep';
import { CampainPaymentConfirm } from './campaign/campaign-payment/CampaignPaymentConfirm';
import { DraftCampainModal } from './DraftCampainModal';
import { FirstStep } from './guided-planning/FirstStep';
import { SecondStep } from './guided-planning/SecondStep';
import { ReactComponent as LockedIcon } from '../assets/icons/ic_secure_outline.svg';
import { ReactComponent as CheckIcon } from '../assets/icons/ic_Check.svg';
import { ReactComponent as CardIcon } from '../assets/icons/ic_Card_View.svg';
import { useStyles, ColorlibConnector } from './campaign/Campain.css';

const steps: string[] = ["Creazione campagna", "Pianifica campagna", "Conferma"]
let stepsDone: string[] = ["Creazione campagna"]

const handleStepsArray = (step: string) => {
  let newArray: string[] = [...stepsDone]

  if (newArray.includes(step) && newArray[steps.indexOf(step)]) {
    // newArray.length = steps.indexOf(step) - 1
    newArray.splice(steps.indexOf(step) + 1)
  } else {
    if (newArray.includes(step)) {
      newArray.filter((oldStep: string) => step !== oldStep)
    } else {
      newArray.push(step)
    }
  }
  stepsDone = newArray
}

export const PlanningGuided = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  // const [step, setStep] = useState("Creazione campagna")
  const [data, setData] = useState({})
  const [budget, setBudget] = useState() as any

  let campaignStep = useSelector(UiSelectors.getCampaignstep)
  // let campaignStep = 'Conferma'
  

  const selectedStep = (select: any) => {
    if (campaignStep === select) {
      return classes.stepSelected
    }
    else /* (stepsDone.includes(select) && campaignStep !== select) */ {
      return classes.stepDone
    }
  }


  const stepIconSwitch = (step: string, index: number) => {

    if (!stepsDone.includes(step)) {
      return CardIcon
    } else if (stepsDone.includes(step) && stepsDone.includes(stepsDone[index + 1])) {
      return CheckIcon
    } else if (stepsDone.includes(step) && step === campaignStep) {
      return LockedIcon
    }
  }

  return (
    <>
      {/* <Modal open={switchModalOpen} onClose={() => dispatch(UiActions.switchDraftCampainModal(''))}>
        <DraftCampainModal setAnchor={setAnchor} modalType={modalType} />
      </Modal> */}
      <div>
        <Grid container>
          {campaignStep === "Payment" || campaignStep === "Confirmed"
            ? null
            : <Grid className="d-sm-flex border-bottom poppinsFont" container item xs={12} alignItems="center" justify="center">
              <Stepper style={{ width: '80%' }} alternativeLabel activeStep={steps.indexOf(campaignStep)} connector={<ColorlibConnector />}>
                {steps.map((stepname: string, index: number) => (
                  <Step key={stepname}>
                    <StepLabel className={stepsDone.includes(stepname) ? selectedStep(stepname) : classes.stepDisabled} StepIconComponent={stepIconSwitch(stepname, index)} onClick={() => {
                      // console.log("stepsDone.includes(stepname)", stepsDone.includes(stepname))
                      if (stepsDone.includes(stepname) && campaignStep !== stepname) {
                        handleStepsArray(stepname)
                        dispatch(UiActions.didSetCampaignStep(stepname))
                      }
                    }}>{stepname}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          }
          {campaignStep === "Confirmed" && <div style={{ overflowX: "hidden", overflowY: "auto", width: "100%" }} className="planningHeight">
            <CampainPaymentConfirm data={data} /> </div>}
          {campaignStep === "Payment" && <div style={{ overflowX: "hidden", overflowY: "auto", width: "100%" }} >
            <CampainPaymentStep data={data} /> </div>}
          {campaignStep === "Conferma" && <div style={{ overflowX: "hidden", overflowY: "auto", width: "100%" }} className="h-100">
            <CampainConfirmStep setData={setData} handleStepsArray={handleStepsArray} /> </div>}
          {campaignStep === "Creazione campagna" ? <div className="planningHeight">
            <FirstStep setBudget={setBudget} budget={budget} handleStepsArray={handleStepsArray} />
          </div> : null}
          {campaignStep === "Pianifica campagna" && <div style={{ overflowX: "auto", overflowY: "auto", width: "100%" }} className="planningHeight">
            <SecondStep budget={budget} handleStepsArray={handleStepsArray} /></div>}
        </Grid>
      </div>
    </>
  )
}