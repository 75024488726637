import * as React from 'react';

import {
  Card, CardText, CardBody,
  CardTitle, CardSubtitle, Button,
  Container, FormGroup, Input, Label, FormFeedback,
  Col, Row
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { actions as AuthActions, selectors as AuthSelectors } from '../store/slices/auth'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ActivityButton } from '../components/ActivityButton'
import { SignUp } from '../pages/signup';

const SignupSchema = Yup.object().shape({
  givenName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  familyName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  passwordConfirm: Yup.string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value
    })
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  emailConfirm: Yup.string()
    .test('email-match', 'Emails must match', function (value) {
      return this.parent.email === value
    })
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
});

export const SignupPage = () => {

  const dispatch = useDispatch();
  const loginError = useSelector(AuthSelectors.getLoggedError);
  let history = useHistory();
  const [test, setTest] = React.useState(false)

  React.useEffect(() => {

    console.log('effect loginError: ', loginError);

    return () => { }

  }, [loginError])

  return (
    <Container>
      <Card className="mt-3 mt-lg-10">
        <CardBody>
          <CardTitle tag="h5" className="text-center">Pagina di Registrazione</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted text-center">Suggerimento: inserire nome, cognome, email e password</CardSubtitle>
          <Formik
            initialValues={{
              email: '',
              emailConfirm: '',
              familyName: '',
              givenName: '',
              password: '',
              passwordConfirm: ''
            }}
            validationSchema={SignupSchema}
            validateOnBlur={true}
            onSubmit={values => {
              console.log('in onsubmit with: ', values)
              dispatch(AuthActions.willSignupUser({ email: values.email, password: values.password, family_name: values.familyName, given_name: values.givenName, history: history }));
            }}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <FormGroup>
                  <Label for="givenName">Nome</Label>
                  <Input invalid={errors.givenName && touched.givenName ? true : false} type="text" name="givenName" id="givenName" placeholder="nome" tag={Field} />
                  {errors.givenName && touched.givenName ? (
                    <FormFeedback>{errors.givenName}</FormFeedback>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label for="familyName">Cognome</Label>
                  <Input invalid={errors.familyName && touched.familyName ? true : false} type="text" name="familyName" id="familyName" placeholder="cognome" tag={Field} />
                  {errors.familyName && touched.familyName ? (
                    <FormFeedback>{errors.familyName}</FormFeedback>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label for="email">Indirizzo Email</Label>
                  <Input invalid={errors.email && touched.email ? true : false} type="text" name="email" id="email" placeholder="email" tag={Field} />
                  {errors.email && touched.email ? (
                    <FormFeedback>{errors.email}</FormFeedback>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label for="emailConfirm">Conferma Indirizzo email</Label>
                  <Input invalid={errors.emailConfirm && touched.emailConfirm ? true : false} type="text" name="emailConfirm" id="emailConfirm" placeholder="conferma email" tag={Field} />
                  {errors.emailConfirm && touched.emailConfirm ? (
                    <FormFeedback>{errors.emailConfirm}</FormFeedback>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input invalid={errors.password && touched.password ? true : false} type="password" name="password" id="password" placeholder="password" tag={Field} />
                  {errors.password && touched.password ? (
                    <FormFeedback>{errors.password}</FormFeedback>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label for="passwordConfirm">Conferma Password</Label>
                  <Input invalid={errors.passwordConfirm && touched.passwordConfirm ? true : false} type="password" name="passwordConfirm" id="passwordConfirm" placeholder="conferma password" tag={Field} />
                  {errors.passwordConfirm && touched.passwordConfirm ? (
                    <FormFeedback>{errors.passwordConfirm}</FormFeedback>
                  ) : null}
                </FormGroup>
                <Row>
                  <Col>
                    <ActivityButton type="submit" name="signup" color="primary" block>Registrati</ActivityButton>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col><Button color="primary" block to="/login" outline tag={Link}>Torna a Login</Button></Col>
                </Row>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </Container>
  )
}