import React, { useState, useMemo, useCallback, useEffect, forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { selectors as ProfileSelectors } from '../store/slices/profile';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { TextField, InputAdornment, Tooltip, Zoom, Backdrop, CircularProgress, Grid } from '@material-ui/core';
import Icon from "../components/IconsComponent";
import { ReactComponent as BillaloIcon } from "../assets/icons/Billalo_logo.svg";
import { useStylesDashboards, Styles } from "../components/dashboard/Dashboard.css";
import { orderStatusSwitch } from '../components/campaign/Campain.css'
import _ from 'lodash';
import moment from 'moment';

import debounce from "lodash/debounce";
import { DashboardList } from '../components/dashboard/DashboardList'
import { NotificationContainer } from '../components/notification-layer/NotificationContainer'

const fontsizeInRightComponents = 20

const currencyFormatter = (value: number) => {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
}

const numFormatter = (num: any) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
}

export const Dashboard = () => {
  const dispatch = useDispatch()
  const classes = useStylesDashboards()

  let lastCampaign = useSelector(ContentSelectors.getSelectedCampaign)
  const campaignList: any[] = useSelector(ContentSelectors.getMyCampaigns)
  const campaignOrders: any[] = useSelector(ContentSelectors.getCampaignOrders)
  const profile = useSelector(ProfileSelectors.getProfile)
  // const userProfile = useSelector(ProfileSelectors.getAdvertiser)
  // console.log("Dashboard lastCampaign", lastCampaign)

  // let lastCampaign = campaignList.length && campaignList[0]
  // let totalBillboardsSold: number = 0
  // let totalBillboardsSoldLastYear: number = 0
  let billboards: any[] = []

  _.map(campaignOrders, (order: any) => {
    if (order.status !== 'canceled') {
      // if (moment(order.order_date).year() < moment().year()) {
      //   totalBillboardsSoldLastYear = totalBillboardsSoldLastYear + order.billboards.length
      // }
      // totalBillboardsSold = totalBillboardsSold + order.billboards.length
      billboards.push(...order.billboards)
    }
  })

  // const ordersToShow: any[] = _.compact(_.map(campaignOrders, (order: any, index: any) => {
  //   if (index <= 4) {
  //     return order
  //   }
  // }))

  // const totalOrdersAmount: any = _.map(campaignOrders, 'amount')
  // const totalOrdersAmountLastYear: any = _.map(campaignOrders, (order: any) => {
  //   if (moment(order.order_date).year() < moment().year()) {
  //     return order.amount
  //   }
  // })

  // let size6x3 = 0 as number
  // let size4x3 = 0 as number
  // let sizeOthers = 0 as number
  let totalMunicipality = [] as any
  // let totalNormalized = [] as any
  // let meanVisibilityIndex = [] as any

  // if (billboards) {
  //   billboards.map((i: any) => {
  //     const mappedSize = `${i.base}x${i.height}`
  //     // sizeArray.push({ size: mappedSize })
  //     if (mappedSize === "600x300") {
  //       size6x3 = size6x3 + 1
  //     }
  //     else if (mappedSize === "400x300") {
  //       size4x3 = size4x3 + 1
  //     } else {
  //       sizeOthers = sizeOthers + 1
  //     }
  //   })
  //   console.log("number of Sizes:", size6x3, size4x3, sizeOthers)

  billboards.map((i: any) => {
    totalMunicipality.push(i.iV_municipality)
  })
  //   billboards.map((i: any) => {
  //     totalNormalized.push(i.iV_normalized)
  //   })
  //   meanVisibilityIndex.push(_.mean(totalMunicipality), _.mean(totalNormalized))
  // }

  useEffect(() => {
    if (_.isEmpty(campaignList)) {
      dispatch(ContentActions.willListMyCampaigns())
    }

    dispatch(UiActions.setSelectedPage("Dashboard"))
  }, [])

  return (
    <>
    <NotificationContainer />
      {campaignList[0] === 'empty'
        ? <h1 className='d-flex align-items-center justify-content-center w-100 bg-white'>Non sono ancora state create delle campagne</h1>
        : <>
          {campaignList[0]
            ? <>
              <Grid container className="poppinsFont" style={{ height: '91.5vh' }} justify="center">
                <Grid container item xs={11} alignItems="center" justify="space-around">
                  <Grid className='p-2 ml-5' item xs={12} alignItems="center">
                    <h1>Ciao {profile.given_name ? profile.given_name : ''}</h1>
                    {/* <p className="text-muted">, qui trovi la tua dashboard</p> */}
                  </Grid>

                  <Grid className={classes.confirmStepBorders} item xs={2}>
                    <div className="d-sm-flex flex-column ml-3">
                      <b style={{ marginBottom: 30 }} >Fee</b>
                      {/* <h4><b>{currencyFormatter(lastCampaign.fee)}</b></h4> */}
                      <h4><b>{_.ceil((lastCampaign.fee / lastCampaign.total_amount) * 100, 2)} %</b></h4>
                    </div>
                  </Grid>
                  <Grid className={classes.confirmStepBorders} item xs={2}>
                    <div className="d-sm-flex flex-column ml-3">
                      <b style={{ marginBottom: 30 }} >Campagne realizzate</b>
                      <h4>{/* <Icon size={25} icon="impianto" /> */} <b>n.{campaignList.length}</b></h4>
                    </div>
                  </Grid>
                  <Grid className={classes.confirmStepBorders} item xs={2} /* justify="center" */>
                    <div className="d-sm-flex flex-column ml-3">
                      <b style={{ marginBottom: 30 }} >Spazi acquistati</b>
                      <h4><b>{billboards.length}</b></h4>
                      {/* <span className="text-muted" style={{ fontSize: fontsizeInRightComponents / 1.5 }}>Premium</span> */}
                    </div>
                  </Grid>
                  <Grid item xs={2}/>
                </Grid>

                <Grid container  style={{ paddingBottom: 100 }} item xs={11} alignItems="center" justify="space-around">
                  <Grid container className="d-sm-flex border p-3" style={{ borderRadius: 14 }} item xs={7} /* alignItems="center" */ justify="space-between">
                    <Grid className='mt-1 mb-2' style={{ color: '#696974' }} item xs={12}>Ultima campagna</Grid>
                    <Grid container className={`${classes.confirmStepBorders}`} item xs={5}>
                      <Grid className='mt-1 mb-2' xs={12}><b>{lastCampaign.name}</b></Grid>
                      <Grid className='mt-1 mb-2' xs={12}><p>{moment(lastCampaign.from).format("DD-MM-YYYY")} / {moment(lastCampaign.to).format("DD-MM-YYYY")}</p></Grid>
                      <Grid className="text-muted d-sm-flex align-items-center mt-1 mb-2" xs={12}>
                        <p style={{ fontSize: 14 }}><Icon size={18} icon="goal" /> Obiettivo {lastCampaign.target}
                          <span className="ml-4 maintenanceOverview" >{orderStatusSwitch(lastCampaign.status, lastCampaign.from, lastCampaign.to)}</span></p></Grid>
                    </Grid>
                    <Grid item xs={5} className={`${classes.confirmStepBorders}`}>
                      <Grid data-cy="billaloIndex" className="mb-3 mt-1" item xs={12} container alignItems="center" justify="space-around">
                        <Grid container item xs={4}>
                          <CircularProgressbarWithChildren
                            className={classes.visibilityProgress}
                            styles={buildStyles({
                              pathColor: "#0066ff",
                              trailColor: "#f8f9fa"
                            })}
                            maxValue={10}
                            value={_.ceil(_.mean(totalMunicipality), 2)}
                            strokeWidth={5} >
                            {/* <Icon color="#0062CC" size={25} icon="headquarters" /> */}
                            <BillaloIcon />
                          </CircularProgressbarWithChildren>
                        </Grid>
                        <Grid item xs={8}>
                          <div className="d-sm-flex flex-column ml-3 detailText">
                            <span><b style={{ fontSize: 25 }}>{_.ceil(_.mean(totalMunicipality), 2)} / 10</b> <br /><span style={{ fontSize: 14 }} className="text-muted">Indice visibilità medio</span></span>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item xs={11}><div className="border-bottom" /></Grid>
                      <Grid data-cy="billaloIndex" className="mt-3 mb-1" item xs={12} container alignItems="center" justify="space-around">
                        <Grid container item xs={4}>
                          <CircularProgressbarWithChildren
                            className={classes.visibilityProgress}
                            styles={buildStyles({
                              pathColor: "#00e68a",
                              trailColor: "#f8f9fa"
                            })}
                            maxValue={10}
                            value={/* _.ceil(_.mean(totalMunicipality), 2) */0}
                            strokeWidth={5} >
                            <Icon color="#00e68a" size={25} icon="deals" />
                          </CircularProgressbarWithChildren>
                        </Grid>
                        <Grid item xs={8}>
                          <div className="d-sm-flex flex-column ml-3 detailText">
                            <span><b style={{ fontSize: 25 }}>{currencyFormatter(lastCampaign.total_amount)}</b> <br /><span style={{ fontSize: 14 }} className="text-muted">Importo speso</span></span>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid /* className={classes.confirmStepBorders}  */ item xs={3}></Grid>
                </Grid>
              </Grid>
            </>
            : <h1 className='d-flex align-items-center justify-content-center w-100 bg-white' >La tua dashboard sta caricando</h1>
          }
        </>}

    </>
  );
}
