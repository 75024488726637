import React, { useState, useEffect } from 'react';
import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import { loader } from 'graphql.macro';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { StripeProvider, Elements, CardElement, injectStripe, CardNumberElement, CardExpiryElement, CardCvcElement } from 'react-stripe-elements';
import { PaymentStyles, createOptions } from '../Campain.css';
import { Grid, Checkbox } from '@material-ui/core';
import Icon from '../../IconsComponent';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ContentActions, selectors as ContentSelectors } from '../../../store/slices/content';
import { actions as NotificationActions } from '../../../store/slices/notification';
import { actions as UiActions, selectors as UiSelectors } from '../../../store/slices/ui';
import stripe_logo from '../../../assets/images/stripe_logo.svg.png';
import { ActivityButton } from '../../ActivityButton';
import _ from 'lodash';

const currencyFormatter = (value: number) => {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
}

export const CampainPaymentStep = (props: any) => {
  const { setStep, data } = props
  const [stripe, setStripe] = useState()
  // console.log("stripe", stripe)

  return (
    <PaymentStyles>
      <StripeProvider stripe={stripe} apiKey="pk_test_51IEydlBobj2XjFUxEtd2thxFd9KNyXhidCMLvoqgEpaC9O4STRLnqMTQKAp4CXGa2s3811q6UACaHFDaLIu6KRwa00C8CaKvVN">
        <Elements>
          <InjectedCheckoutForm setStep={setStep} data={data} />
        </Elements>
      </StripeProvider>
    </PaymentStyles>
  )
}

const CheckoutForm = (props: any) => {
  const { setStep, data } = props
  // console.log("CheckoutForm data", data)
  const dispatch = useDispatch()

  const [amount, setAmount] = useState(0)
  const [name, setName] = useState("")
  const [card, setCard] = useState({} as any)
  const [checked, setChecked] = React.useState(true);

  const client_secret = useSelector(ContentSelectors.getClientSecret)
  let selectedCampaign = useSelector(ContentSelectors.getSelectedCampaign)
  let campaignOrders = useSelector(ContentSelectors.getCampaignOrders)
  let campaignStep = useSelector(UiSelectors.getCampaignstep)
  // console.log('CheckoutForm client_secret', client_secret)

  useEffect(() => {
    dispatch(ContentActions.willGetCampaignOrders({ campaign_id: selectedCampaign.id, orders: selectedCampaign.orders }))
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const cardElement = props.elements.getElement('cardNumber');
  // console.log('with cardElement: ', client_secret)
  // console.log("confirmCardPayment", props.stripe.createPaymentMethod)

  const onSubmit = async (event: any) => {
    //   // console.log('with event: ', event)
    event.preventDefault();

    // dispatch(ContentActions.willPayCampaign({
    //   confirmCardPayment: props.stripe.confirmCardPayment(),
    //   props: props,
    //   selectedCampaign: selectedCampaign,
    //   client_secret: client_secret,
    //   campaignOrders: campaignOrders,
    //   name: name,
    // }))
    // const cardElement = props.elements.getElement('cardNumber');
    // console.log('with cardElement: ', cardElement, client_secret)

    dispatch(ContentActions.willPayOrder({
      client_secret: client_secret, payment_method: {
        card: cardElement,
        billing_details: {
          name: name,
        },
      }
    }))
    dispatch(UiActions.startActivityRunning("payment"))
    /// componenti divisi
    const cardNumber = props.elements.getElement('cardNumber');
    const cardExpiry = props.elements.getElement('cardExpiry');
    const cardCvc = props.elements.getElement('cardCvc');
    // dispatch(ContentActions.willPayOrder({
    //   client_secret: client_secret, payment_method: {
    //     card: cardNumber,
    //     billing_details: {
    //       name: name,
    //     },
    //   }
    // }))

    // const creationResult: any = await client.mutate({ mutation: mutation, variables: { amount: amount * 100 } })
    // console.log('with result: ', creationResult)
    // const data = JSON.parse(creationResult.data.startPayment.data)
    // console.log('with data: ', data.metadata.orderId)
    dispatch(ContentActions.batchDeleteOrders({ orders: [], campaign: "" }));

    /// prova componenti divisi
    // const result = await props.stripe.confirmCardPayment(client_secret, {
    //   payment_method: {
    //     card: cardNumber,
    //     billing_details: {
    //       name: name,
    //     },
    //   } as any
    // });
    // console.log("result payment frontend", result)

    const result = await props.stripe.confirmCardPayment(client_secret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: name,
        },
      } as any
    });

    // console.log('payment result: ', result)
    if (result.paymentIntent) {
      // console.log('in true payment ok', campaignOrders)
      for (var i = 0; i < campaignOrders.length; i++) {
        dispatch(ContentActions.willUpdateOrderState({ campaign_id: selectedCampaign.id, id: campaignOrders[i].id, action: "pay" }))
      }
      dispatch(ContentActions.willUpdateCampaignState({ campaign_id: campaignOrders[0].campaign, action: "pay" }))
      dispatch(NotificationActions.willShowNotification({ message: "Pagamento effettuato", type: "success" }));
      dispatch(ContentActions.willAddBillboardsAvailability({ period: [], campaign_id: selectedCampaign.id }));
      dispatch(UiActions.didSetCampaignStep("Confirmed"))
      // setStep("Confirmed")
      // dispatch(UiActions.goTo('/'))
    } else {
      dispatch(NotificationActions.willShowNotification({ message: "Errore durante il pagamento", type: "danger" }));
    }

    dispatch(UiActions.stopActivityRunning("payment"))
  }

  return (
    <>
      <div className="d-sm-flex align-items-center p-2 poppinsFont">
        {campaignStep
          ? null
          : <>
            <div className="hoverPointer" onClick={() => dispatch(UiActions.didSetCampaignStep("Conferma"))}><Icon className="border rounded p-1" size={30} icon="chevronLeft" /></div>
            <span className="ml-2" >Torna indietro</span>
          </>}
      </div>
      <Grid className="mb-3" container xs={12} alignItems="center" justify="center">
        <span className="poppinsFont" style={{ color: "#007bff", fontSize: 50, fontWeight: 700 }} >Concludi acquisto</span>
      </Grid>
      <Grid className="mb-3" container xs={12} alignItems="center" justify="center">
        <b style={{ fontSize: 25, opacity: 0.4 }}>Totale da pagare:</b><b style={{ fontSize: 25, marginLeft: 10 }}>{selectedCampaign ? currencyFormatter(_.ceil(selectedCampaign.total_amount, 2)) : currencyFormatter(data.totalCost)}</b>
      </Grid>
      <Grid className="mb-3" container xs={12} alignItems="center" justify="center">
        <b style={{ fontSize: 18, opacity: 0.4 }}>Inserisci i dati della carta di credito</b>
      </Grid>
      <div className="Checkout">
        <form onSubmit={onSubmit}>
          <label>
            Intestatario carta
            <input name="name" type="text" placeholder="inserire nome e cognome" value={name} onChange={(e: any) => setName(e.target.value)} required />
          </label>
          {/* <label>
            Card details
            <CardElement {...createOptions(18)} />
          </label> */}
          <label>
            Numero Carta
            <CardNumberElement
              id="cardNumber"
              showIcon={true}
              // onReady={(event) => { console.log("CardNumberElement [ready]"); }}
              onChange={event => { console.log("CardNumberElement [change]"/* , event */); setCard(event) }}
              // onBlur={(event) => { console.log("CardNumberElement [blur]"/* , event */); }}
              // onFocus={() => { console.log("CardNumberElement [focus]"); }}
              placeholder="inserire numero carta"
              {...createOptions(20)}
            />
          </label>
          <Grid container xs={12} alignItems="center">
            <Grid item xs={6}>
              <label>
                Data Scadenza
                <CardExpiryElement
                  id="cardExpiry"
                  className="middleElement"
                  // onReady={() => { console.log("CardExpiryElement [ready]"); }}
                  // onChange={event => { console.log("CardExpiryElement [change]"/* , event */); }}
                  // onBlur={() => { console.log("CardExpiryElement [blur]"); }}
                  // onFocus={() => { console.log("CardExpiryElement [focus]"); }}
                  {...createOptions(20)}
                />
              </label>
            </Grid>
            <Grid item xs={6}>
              <label className="ml-4">
                CVC
                <CardCvcElement
                  id="cardCvc"
                  className="middleElement"
                  // onReady={() => { console.log("CardCvcElement [ready]"); }}
                  // onChange={event => { console.log("CardCvcElement [change]"/* , event */); }}
                  // onBlur={() => { console.log("CardCvcElement [blur]"); }}
                  // onFocus={() => { console.log("CardCvcElement [focus]"); }}
                  {...createOptions(20)}
                />
              </label>
            </Grid>
          </Grid>
          <div className="d-sm-flex flex-column">
            <Grid container xs={12} alignItems="center">
              <Grid item xs={1}>
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  color="primary"
                />
              </Grid>
              <Grid item xs={11}>Ricorda dati della carta</Grid>
            </Grid>
            <ActivityButton type="submit" name="payment" color="primary" block>Acquista</ActivityButton>
            {/* <button onClick={(e: any) => {
              console.log('onclick', e)
            }}>Acquista</button> */}
          </div>
        </form >
        <Grid className="mt-3" container xs={12} alignItems="center" justify="center">
          <span style={{ fontSize: 20, opacity: 0.6 }}>Payment powered by</span> <img src={stripe_logo} width="210" height="90" />
        </Grid>
      </div>
    </>
  )
}

const InjectedCheckoutForm = injectStripe(CheckoutForm)